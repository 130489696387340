@import '../../../style/globals.scss';

.SidebarEventContainer {
    position: absolute;
    top: 0;
    width: 100%;
    height: 290px;
    pointer-events: none;
    transform: translateY(0%);
    transition: transform 200ms ease-in-out;

    &.isHidden {
        transform: translateY(-100%);
    }

    .dismissButton {
        position: absolute;
        z-index: 1;
        top: 4px;
        right: 4px;
        pointer-events: auto;
        svg {
            path {
                fill: var(--token-accent-theme-1-text-text-default);
            }
        }
    }

    .SidebarEvent {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: url('/assets/global/images/events/sidebar-overlay.webp');
        background-size: 100% 100%;
        font-family: var(--font-quaternary);
        font-weight: 500;

        .timer {
            position: relative;
            width: calc(100% - 30px);
            height: 130px;
            margin-right: auto;
            margin-left: auto;
        }

        .incorrectShake {
            animation-name: incorrect-shake;
            animation-timing-function: linear;
            animation-iteration-count: 1;
            animation-duration: 500ms;
        }

        @keyframes incorrect-shake {
            8%,
            41% {
                transform: translateX(-10px);
            }
            25%,
            58% {
                transform: translateX(10px);
            }
            75% {
                transform: translateX(-5px);
            }
            92% {
                transform: translateX(5px);
            }
            0%,
            100% {
                transform: translateX(0);
            }
        }

        .text {
            width: calc(100% - 140px);
            margin: 33px 72px 0px 72px;
            color: var(--token-accent-theme-1-text-text-default);
            text-align: center;
            text-transform: uppercase;
            pointer-events: auto;

            .winString {
                text-transform: none;
            }

            .intro {
                display: block;
                font-size: $font-size-h6;
            }

            .instruction {
                display: block;
                margin-top: -5px;
                margin-bottom: 5px;
                font-size: $font-size-h4;
                font-weight: 600;
            }

            .lowlight {
                opacity: 0.7;
            }
        }
    }

    @media (max-width: $bp-s), (max-height: 800px) {
        .SidebarEvent {
            height: 190px;

            .text {
                margin-top: 20px;

                .intro {
                    font-size: $font-size-p;
                }

                .instruction {
                    font-size: $font-size-h6;
                }
            }

            .timer {
                height: 100px;
            }
        }
    }
}
