.mainLoader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: black;
    pointer-events: none;
    touch-action: none;
    font-family: var(--font-secondary, system-ui), system-ui;

    &__text {
        position: absolute;
        top: calc(50% + 70px);
        max-width: 400px;
        padding: 20px;
        color: white;
        opacity: 0; /* Start as invisible */
        transition: opacity 5s 5s ease-in; /* Smoothly transition opacity */
        animation: fade-in 5s 5s forwards; /* Explicitly persist final state */
    }

    &__title {
        display: block;
        font-weight: bold;
        padding-bottom: 10px;
    }

    &__subtitle {
        display: block;
        font-style: italic;
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        display: block;
        background-size: contain;
        background-repeat: no-repeat;
        opacity: 0; /* Start as invisible */
        transition: opacity 5s 1s ease-in; /* Smoothly transition opacity */
    }

    &::before {
        width: 90px;
        height: 90px;
        background-image: url('/assets/global/preloader/logo.png');
        animation: fade-in 5s 1s forwards; /* Explicitly persist final state */
    }

    &::after {
        width: 150px;
        height: 150px;
        background-image: url('/assets/global/preloader/spinner.svg');
        animation: fade-in 5s 1s forwards, rotate-and-back 2.2s infinite;
    }

    @keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes rotate-and-back {
        0%,
        100% {
            transform: rotate(0deg);
        }
        10% {
            transform: rotate(180deg);
            animation-timing-function: ease-in-out;
        }
        50% {
            transform: rotate(180deg);
        }
        60% {
            transform: rotate(0deg);
            animation-timing-function: ease-in-out;
        }
    }
}
