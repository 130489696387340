._1Yw81G_fontSizeH1 {
  font-size: 55px;
}

._1Yw81G_fontSizeH2 {
  font-size: 44px;
}

._1Yw81G_fontSizeH3 {
  font-size: 35px;
}

._1Yw81G_fontSizeH4 {
  font-size: 28px;
}

._1Yw81G_fontSizeH5 {
  font-size: 22px;
}

._1Yw81G_fontSizeH6 {
  font-size: 18px;
}

._1Yw81G_fontSizep {
  font-size: 14px;
}

._1Yw81G_fontSizeSmall {
  font-size: 12px;
}

._1Yw81G_fontSizeTiny {
  font-size: 8px;
}

._1Yw81G_Button {
  text-align: center;
  background-color: var(--token-accent-theme-1-button-button-default);
  color: var(--token-accent-theme-1-text-text-default);
  border-radius: 10px;
  width: 100%;
  padding: 15px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition-property: all;
  transition-duration: .3s;
  display: block;
}

._1Yw81G_Button:focus, ._1Yw81G_Button:hover {
  background-color: var(--token-accent-theme-1-button-button-highlight);
}

._1Yw81G_Button:disabled {
  color: var(--token-accent-theme-1-text-text-inactive);
  background-color: var(--token-accent-theme-1-button-button-inactive);
}

._1Yw81G_Button._1Yw81G_secondary {
  background-color: var(--token-accent-theme-1-button-button-secondary-default);
  color: var(--token-accent-theme-1-text-text-contrast-default);
}

._1Yw81G_Button._1Yw81G_secondary:focus, ._1Yw81G_Button._1Yw81G_secondary:hover {
  background-color: var(--token-accent-theme-1-button-button-secondary-highlight);
}

._2j5PMq_fontSizeH1 {
  font-size: 55px;
}

._2j5PMq_fontSizeH2 {
  font-size: 44px;
}

._2j5PMq_fontSizeH3 {
  font-size: 35px;
}

._2j5PMq_fontSizeH4 {
  font-size: 28px;
}

._2j5PMq_fontSizeH5 {
  font-size: 22px;
}

._2j5PMq_fontSizeH6 {
  font-size: 18px;
}

._2j5PMq_fontSizep {
  font-size: 14px;
}

._2j5PMq_fontSizeSmall {
  font-size: 12px;
}

._2j5PMq_fontSizeTiny {
  font-size: 8px;
}

._2j5PMq_FactionPicker {
  color: var(--token-LEGACY-accent-theme-1-text-text-active);
  max-width: 100%;
  margin: -280px -115px -71px;
  padding: 200px 115px 71px;
  overflow: visible hidden;
}

._2j5PMq_FactionPicker::-webkit-scrollbar {
  width: 15px;
}

._2j5PMq_FactionPicker::-webkit-scrollbar-thumb {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-foreground);
  background-clip: content-box;
  border: 3px solid #0000;
  border-radius: 8px;
}

._2j5PMq_FactionPicker::-webkit-scrollbar-track {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-background);
  background-clip: content-box;
  border-right: 1px solid #0000;
  border-radius: 10px;
}

._2j5PMq_FactionPicker ul {
  white-space: nowrap;
}

._2j5PMq_FactionPickerFaction {
  backface-visibility: hidden;
  vertical-align: top;
  margin-right: 22px;
  animation-duration: 1s;
  animation-fill-mode: backwards;
  display: inline-block;
  position: relative;
}

._2j5PMq_FactionPickerFaction:first-child {
  animation-delay: .1s;
}

._2j5PMq_FactionPickerFaction:nth-child(2) {
  animation-delay: .2s;
}

._2j5PMq_FactionPickerFaction:nth-child(3) {
  animation-delay: .3s;
}

._2j5PMq_FactionPickerFaction:nth-child(4) {
  animation-delay: .4s;
}

._2j5PMq_FactionPickerFaction:nth-child(5) {
  animation-delay: .5s;
}

._2j5PMq_FactionPickerFaction:nth-child(6) {
  animation-delay: .6s;
}

._2j5PMq_FactionPickerFaction:nth-child(7) {
  animation-delay: .7s;
}

._2j5PMq_FactionPickerFaction:nth-child(8) {
  animation-delay: .8s;
}

._2j5PMq_FactionPickerFaction:nth-child(9) {
  animation-delay: .9s;
}

._2j5PMq_FactionPickerFaction:nth-child(10) {
  animation-delay: 1s;
}

._2j5PMq_FactionPickerFaction:nth-child(11) {
  animation-delay: 1.1s;
}

._2j5PMq_FactionPickerFaction:nth-child(12) {
  animation-delay: 1.2s;
}

._2j5PMq_FactionPickerFaction._2j5PMq_page-transition-preVisible {
  animation-name: none;
}

._2j5PMq_FactionPickerFaction._2j5PMq_page-transition-visible {
  animation-name: _2j5PMq_slide-in;
}

._2j5PMq_FactionPickerFaction._2j5PMq_page-transition-postVisible {
  animation-name: _2j5PMq_slide-out;
  animation-fill-mode: forwards;
}

._2j5PMq_FactionPickerFaction:last-child {
  margin-right: 0;
}

._2j5PMq_FactionPickerFaction__BackgroundContainer {
  filter: brightness(.5);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  transition: filter .4s cubic-bezier(.5, .33, .33, 1);
  position: absolute;
  inset: 0;
}

._2j5PMq_FactionPickerFaction__Button {
  background-size: 100% 100%;
  width: 203px;
  height: 237px;
  position: relative;
}

._2j5PMq_FactionPickerFaction__Button:focus ._2j5PMq_FactionPickerFaction__Image, ._2j5PMq_FactionPickerFaction__Button:hover ._2j5PMq_FactionPickerFaction__Image, ._2j5PMq_FactionPickerFaction__Button--selected ._2j5PMq_FactionPickerFaction__Image {
  filter: brightness();
  transform: scale(1.2);
}

._2j5PMq_FactionPickerFaction__Button:focus ._2j5PMq_FactionPickerFaction__BackgroundContainer, ._2j5PMq_FactionPickerFaction__Button:hover ._2j5PMq_FactionPickerFaction__BackgroundContainer, ._2j5PMq_FactionPickerFaction__Button--selected ._2j5PMq_FactionPickerFaction__BackgroundContainer {
  filter: brightness();
}

._2j5PMq_FactionPickerFaction__Button--selected ._2j5PMq_FactionPickerFaction__Border {
  border-color: var(--token-accent-theme-1-button-button-highlight);
}

._2j5PMq_FactionPickerFaction__Button--selected ._2j5PMq_FactionPickerFaction__Image {
  filter: brightness();
}

._2j5PMq_FactionPickerFaction__Button--selected ~ ._2j5PMq_FactionPickerFaction__HighlightImage {
  opacity: 1;
  background-size: 100% 100%;
  transition-property: background-size, opacity;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.5, .33, .33, 1);
}

._2j5PMq_FactionPickerFaction__Border {
  border: 1.5px solid var(--token-accent-theme-1-border-border-default);
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  margin: 10px;
  transition: border-color .5s cubic-bezier(.5, .33, .33, 1);
  position: absolute;
  inset: 0;
}

._2j5PMq_FactionPickerFaction__Image {
  filter: brightness(.2);
  -webkit-user-drag: none;
  max-width: 100%;
  max-height: 108px;
  margin-left: auto;
  margin-right: auto;
  transition: transform .4s cubic-bezier(.5, .33, .33, 1), filter .4s cubic-bezier(.5, .33, .33, 1);
  position: relative;
}

._2j5PMq_FactionPickerFaction__HighlightImage {
  z-index: -1;
  opacity: 0;
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-size: 100% 0%;
  width: 224px;
  height: 491px;
  transition: none;
  animation-name: _2j5PMq_fade-in;
  animation-duration: 2s;
  animation-delay: 1.5s;
  animation-fill-mode: backwards;
  position: absolute;
  top: -135px;
  left: -15px;
  rotate: 30deg;
}

._2j5PMq_FactionPickerFaction__Text {
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 162px;
  height: 47px;
  margin-left: auto;
  margin-right: auto;
  padding: 9px;
  display: flex;
  position: absolute;
  bottom: -8px;
  left: 0;
  right: 0;
}

._2j5PMq_FactionPickerFaction__Title {
  font-size: 14px;
  font-family: var(--font-primary);
  text-transform: uppercase;
}

._2j5PMq_FactionPickerFaction__Subtitle {
  color: var(--token-LEGACY-accent-theme-1-text-text-secondary);
  margin-top: -4px;
  font-size: 12px;
}

@keyframes _2j5PMq_slide-in {
  from {
    opacity: 0;
    bottom: -200px;
  }

  to {
    opacity: 1;
    bottom: 0;
  }
}

@keyframes _2j5PMq_slide-out {
  from {
    opacity: 1;
    bottom: 0;
  }

  to {
    opacity: 0;
    bottom: -200px;
  }
}

@keyframes _2j5PMq_fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.PpN9jq_fontSizeH1 {
  font-size: 55px;
}

.PpN9jq_fontSizeH2 {
  font-size: 44px;
}

.PpN9jq_fontSizeH3 {
  font-size: 35px;
}

.PpN9jq_fontSizeH4 {
  font-size: 28px;
}

.PpN9jq_fontSizeH5 {
  font-size: 22px;
}

.PpN9jq_fontSizeH6 {
  font-size: 18px;
}

.PpN9jq_fontSizep {
  font-size: 14px;
}

.PpN9jq_fontSizeSmall {
  font-size: 12px;
}

.PpN9jq_fontSizeTiny {
  font-size: 8px;
}

.PpN9jq_AuthInput {
  height: 45px;
  font-size: 14px;
  color: var(--token-accent-theme-1-text-text-default) !important;
}

.PpN9jq_AuthInput::placeholder {
  opacity: .7;
  color: var(--token-accent-theme-1-text-text-default) !important;
}

.PpN9jq_AuthInput--invalid {
  transition: none;
  animation-name: PpN9jq_invalid-input-shake;
  animation-timing-function: linear;
}

@keyframes PpN9jq_invalid-input-shake {
  8%, 41% {
    -webkit-transform: translateX(-10px);
  }

  25%, 58% {
    -webkit-transform: translateX(10px);
  }

  75% {
    -webkit-transform: translateX(-5px);
  }

  92% {
    -webkit-transform: translateX(5px);
  }

  0%, 100% {
    -webkit-transform: translateX(0);
  }
}

.MsuNTa_fontSizeH1 {
  font-size: 55px;
}

.MsuNTa_fontSizeH2 {
  font-size: 44px;
}

.MsuNTa_fontSizeH3 {
  font-size: 35px;
}

.MsuNTa_fontSizeH4 {
  font-size: 28px;
}

.MsuNTa_fontSizeH5 {
  font-size: 22px;
}

.MsuNTa_fontSizeH6 {
  font-size: 18px;
}

.MsuNTa_fontSizep {
  font-size: 14px;
}

.MsuNTa_fontSizeSmall {
  font-size: 12px;
}

.MsuNTa_fontSizeTiny {
  font-size: 8px;
}

.MsuNTa_Input {
  border: 1px solid #fff;
  border-color: var(--token-accent-theme-1-border-border-default);
  color: var(--token-accent-theme-1-text-text-default);
  background-color: #00000080;
  border-radius: 10px;
  outline: none;
  padding: 15px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: border .35s;
}

.MsuNTa_Input::placeholder {
  color: var(--token-LEGACY-accent-theme-1-text-text-secondary);
}

.MsuNTa_Input:focus {
  border-color: var(--token-accent-theme-1-border-border-highlight);
  outline: none;
}

.oUJM5a_AvatarLayered {
  flex: none;
  display: block;
  position: relative;
}

.oUJM5a_AvatarLayered img, .oUJM5a_AvatarLayered .oUJM5a_masked {
  z-index: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
}

.oUJM5a_AvatarLayered .oUJM5a_masked {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-foreground);
  border-radius: 50%;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.oUJM5a_AvatarLayered .oUJM5a_frame {
  width: 170%;
  max-width: none;
  top: -35%;
  left: -35%;
}

.oUJM5a_AvatarLayered .oUJM5a_background {
  width: auto;
  height: 200%;
}

.oUJM5a_AvatarLayered .oUJM5a_character {
  transition: transform .5s ease-out;
  top: 5px;
}

.oUJM5a_AvatarLayered.oUJM5a_hasHover {
  cursor: pointer;
}

.oUJM5a_AvatarLayered.oUJM5a_hasHover:hover .oUJM5a_character, .oUJM5a_AvatarLayered.oUJM5a_hasHover:focus .oUJM5a_character {
  transform: scale(1.2)translateY(10%);
}

.v1I5QG_fontSizeH1 {
  font-size: 55px;
}

.v1I5QG_fontSizeH2 {
  font-size: 44px;
}

.v1I5QG_fontSizeH3 {
  font-size: 35px;
}

.v1I5QG_fontSizeH4 {
  font-size: 28px;
}

.v1I5QG_fontSizeH5 {
  font-size: 22px;
}

.v1I5QG_fontSizeH6 {
  font-size: 18px;
}

.v1I5QG_fontSizep {
  font-size: 14px;
}

.v1I5QG_fontSizeSmall {
  font-size: 12px;
}

.v1I5QG_fontSizeTiny {
  font-size: 8px;
}

.v1I5QG_AvatarPicker {
  grid-template-columns: repeat(4, 1fr);
  gap: 34px 24px;
  width: 100%;
  margin: 0 auto;
  display: grid;
}

@media (width <= 992px) {
  .v1I5QG_AvatarPicker {
    gap: 29px 21px;
  }
}

@media (width <= 768px) {
  .v1I5QG_AvatarPicker {
    gap: 29px 15px;
  }
}

.v1I5QG_AvatarPickerAvatar {
  border: 2px solid #0000;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  animation-duration: 1s;
  animation-fill-mode: backwards;
  position: relative;
  overflow: hidden;
}

.v1I5QG_AvatarPickerAvatar:nth-child(4n+1) {
  animation-delay: .1s;
}

.v1I5QG_AvatarPickerAvatar:nth-child(4n+2) {
  animation-delay: .2s;
}

.v1I5QG_AvatarPickerAvatar:nth-child(4n+3) {
  animation-delay: .3s;
}

.v1I5QG_AvatarPickerAvatar:nth-child(4n+4) {
  animation-delay: .4s;
}

.v1I5QG_AvatarPickerAvatar.v1I5QG_page-transition-preVisible {
  opacity: 0;
  animation-name: none;
}

.v1I5QG_AvatarPickerAvatar.v1I5QG_page-transition-visible {
  animation-name: v1I5QG_slide-in;
}

.v1I5QG_AvatarPickerAvatar.v1I5QG_page-transition-postVisible {
  animation-name: v1I5QG_slide-out;
  animation-fill-mode: forwards;
}

@media (width <= 992px) {
  .v1I5QG_AvatarPickerAvatar {
    width: 76px;
    height: 76px;
  }
}

.v1I5QG_AvatarPickerAvatar--selected {
  border-color: var(--token-accent-theme-1-border-border-active);
}

.v1I5QG_AvatarPickerAvatar__Button {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.v1I5QG_AvatarPickerAvatar__Button:focus .v1I5QG_AvatarPickerAvatar__Image, .v1I5QG_AvatarPickerAvatar__Button:hover .v1I5QG_AvatarPickerAvatar__Image {
  background-size: 120% 120%;
}

.v1I5QG_AvatarPickerAvatar__Image {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-foreground);
  background-position: center;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  transition: background-size .2s cubic-bezier(.5, .33, .33, 1);
}

@keyframes v1I5QG_slide-in {
  from {
    opacity: 0;
    bottom: -200px;
  }

  to {
    opacity: 1;
    bottom: 0;
  }
}

@keyframes v1I5QG_slide-out {
  from {
    opacity: 1;
    bottom: 0;
  }

  to {
    opacity: 0;
    bottom: -200px;
  }
}

.nhLJVW_fontSizeH1 {
  font-size: 55px;
}

.nhLJVW_fontSizeH2 {
  font-size: 44px;
}

.nhLJVW_fontSizeH3 {
  font-size: 35px;
}

.nhLJVW_fontSizeH4 {
  font-size: 28px;
}

.nhLJVW_fontSizeH5 {
  font-size: 22px;
}

.nhLJVW_fontSizeH6 {
  font-size: 18px;
}

.nhLJVW_fontSizep {
  font-size: 14px;
}

.nhLJVW_fontSizeSmall {
  font-size: 12px;
}

.nhLJVW_fontSizeTiny {
  font-size: 8px;
}

.nhLJVW_authNextStepButton {
  width: 481px;
  max-width: calc(100% - 70px);
  height: 45px;
  margin-left: 35px;
  margin-right: 35px;
  display: inline-block;
  position: relative;
}

.nhLJVW_editProfilePage {
  opacity: 1;
  background-color: #000;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
}

.nhLJVW_editProfilePage.nhLJVW_loaded {
  background-position: 50% 0;
}

.nhLJVW_editProfilePage.nhLJVW_step1 {
  background-position: 50% -100px;
  transition: background-position .8s ease-in-out .3s, opacity 1s ease-in-out;
}

.nhLJVW_editProfilePage.nhLJVW_step2 {
  background-position: 50% -200px;
  transition: background-position .8s ease-in-out, opacity 1s ease-in-out;
}

.nhLJVW_editProfilePage.nhLJVW_page-transition-preVisible {
  opacity: 0;
}

.nhLJVW_editProfilePage.nhLJVW_page-transition-visible {
  opacity: 1;
}

.nhLJVW_editProfilePage.nhLJVW_page-transition-postVisible {
  opacity: 0;
}

.nhLJVW_editProfilePage__content {
  opacity: 1;
  margin-left: 70px;
  transition: opacity 1s;
}

.nhLJVW_editProfilePage__formContent {
  width: 418px;
  margin-bottom: 135px;
  position: absolute;
  bottom: 0;
}

.nhLJVW_editProfilePage__formContent label {
  font-size: 14px;
  font-weight: 600;
  display: block;
}

.nhLJVW_editProfilePage__pickerContainer {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: 110px;
  padding-bottom: 108px;
  display: flex;
  position: fixed;
  inset: 0;
}

@media (height <= 1000px) {
  .nhLJVW_editProfilePage__pickerContainer {
    padding-bottom: 20px;
  }
}

@media (height <= 700px) {
  .nhLJVW_editProfilePage__pickerContainer {
    padding-top: 20px;
  }
}

.nhLJVW_editProfilePage__pickerContainerTitle {
  z-index: 10;
  text-align: center;
  font-family: var(--font-primary);
  margin: 0 8px;
  font-size: 35px;
}

@media (width <= 992px) {
  .nhLJVW_editProfilePage__pickerContainerTitle {
    font-size: 28px;
  }
}

.nhLJVW_editProfilePage__bottom {
  text-align: center;
  width: 100%;
}

.nhLJVW_editProfilePage__errorMessage {
  color: var(--token-arise-theme-1-state-state-error-light);
  text-align: center;
}

.nhLJVW_editProfilePage__errorMessage:empty:before {
  content: "";
  display: inline-block;
}

.nhLJVW_editProfilePage .nhLJVW_catastrophicError {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
}

.nhLJVW_editProfilePage .nhLJVW_catastrophicError h2 {
  font-family: var(--font-primary);
  text-align: center;
  font-size: 28px;
}

.eE_5DW_fontSizeH1 {
  font-size: 55px;
}

.eE_5DW_fontSizeH2 {
  font-size: 44px;
}

.eE_5DW_fontSizeH3 {
  font-size: 35px;
}

.eE_5DW_fontSizeH4 {
  font-size: 28px;
}

.eE_5DW_fontSizeH5 {
  font-size: 22px;
}

.eE_5DW_fontSizeH6 {
  font-size: 18px;
}

.eE_5DW_fontSizep {
  font-size: 14px;
}

.eE_5DW_fontSizeSmall {
  font-size: 12px;
}

.eE_5DW_fontSizeTiny {
  font-size: 8px;
}

.eE_5DW_loadingScreen {
  opacity: 1;
  -webkit-user-select: none;
  user-select: none;
  height: 100%;
  transition: opacity .5s ease-out, height 0s linear .5s;
  top: 0;
}

.eE_5DW_loadingScreen img {
  pointer-events: none;
}

.eE_5DW_hidden {
  opacity: 0;
  pointer-events: none;
}

@keyframes eE_5DW_rotate-and-back {
  0%, 100% {
    transform: rotate(0);
  }

  10% {
    animation-timing-function: ease-in-out;
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  60% {
    animation-timing-function: ease-in-out;
    transform: rotate(0);
  }
}

.eE_5DW_loadingSpinner {
  animation: 2.2s infinite eE_5DW_rotate-and-back;
}

.q4ybRG_fontSizeH1 {
  font-size: 55px;
}

.q4ybRG_fontSizeH2 {
  font-size: 44px;
}

.q4ybRG_fontSizeH3 {
  font-size: 35px;
}

.q4ybRG_fontSizeH4 {
  font-size: 28px;
}

.q4ybRG_fontSizeH5 {
  font-size: 22px;
}

.q4ybRG_fontSizeH6 {
  font-size: 18px;
}

.q4ybRG_fontSizep {
  font-size: 14px;
}

.q4ybRG_fontSizeSmall {
  font-size: 12px;
}

.q4ybRG_fontSizeTiny {
  font-size: 8px;
}

.q4ybRG_Icon path {
  fill: var(--token-LEGACY-accent-theme-1-interface-interface-white);
}

.PrFOVW_fontSizeH1 {
  font-size: 55px;
}

.PrFOVW_fontSizeH2 {
  font-size: 44px;
}

.PrFOVW_fontSizeH3 {
  font-size: 35px;
}

.PrFOVW_fontSizeH4 {
  font-size: 28px;
}

.PrFOVW_fontSizeH5 {
  font-size: 22px;
}

.PrFOVW_fontSizeH6 {
  font-size: 18px;
}

.PrFOVW_fontSizep {
  font-size: 14px;
}

.PrFOVW_fontSizeSmall {
  font-size: 12px;
}

.PrFOVW_fontSizeTiny {
  font-size: 8px;
}

.PrFOVW_Tooltip {
  background-color: var(--token-accent-theme-1-interface-mono-interface-white);
  color: var(--token-accent-theme-1-text-text-contrast-default);
  white-space: nowrap;
  opacity: 0;
  border-radius: 8px;
  padding: 5px 10px;
  font-size: 14px;
  transition: opacity .3s ease-in-out;
  box-shadow: 0 2px 5px #0003;
}

.PrFOVW_Tooltip.PrFOVW_autoPosition {
  position: absolute;
  top: 50%;
  left: calc(100% + 10px);
  transform: translateY(-50%);
}

.PrFOVW_Tooltip.PrFOVW_isVisible {
  opacity: 1;
}

.PrFOVW_Tooltip:before {
  content: "";
  border-style: solid;
  border-width: 8px 10px;
  border-color: transparent var(--token-accent-theme-1-interface-mono-interface-white) transparent transparent;
  position: absolute;
  top: 50%;
  left: -16px;
  transform: translateY(-50%);
}

.sBhBqW_fontSizeH1 {
  font-size: 55px;
}

.sBhBqW_fontSizeH2 {
  font-size: 44px;
}

.sBhBqW_fontSizeH3 {
  font-size: 35px;
}

.sBhBqW_fontSizeH4 {
  font-size: 28px;
}

.sBhBqW_fontSizeH5 {
  font-size: 22px;
}

.sBhBqW_fontSizeH6 {
  font-size: 18px;
}

.sBhBqW_fontSizep {
  font-size: 14px;
}

.sBhBqW_fontSizeSmall {
  font-size: 12px;
}

.sBhBqW_fontSizeTiny {
  font-size: 8px;
}

.sBhBqW_IconButton {
  position: relative;
}

.sBhBqW_IconButton:disabled {
  pointer-events: none;
}

.sBhBqW_IconButton:after {
  content: "";
  background-color: var(--token-arise-theme-1-state-state-error-light);
  opacity: 0;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  transition: opacity .3s ease-in-out;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

.sBhBqW_IconButton.sBhBqW_hasDot:after {
  opacity: 1;
}

.sBhBqW_isRoundStyle {
  background-color: var(--token-accent-theme-1-interface-brand-interface-midground);
  border-radius: 100%;
  width: 50px;
  height: 50px;
}

.sBhBqW_isRoundStyle svg path {
  fill: var(--token-accent-theme-1-text-text-default);
}

.sBhBqW_isRoundStyle:focus, .sBhBqW_isRoundStyle:hover {
  filter: brightness(1.4);
}

.sBhBqW_isIconStyle {
  width: 24px;
  height: 24px;
}

.sBhBqW_isIconStyle svg {
  vertical-align: baseline;
}

.sBhBqW_isIconStyle svg path {
  fill: var(--token-LEGACY-accent-theme-1-interface-interface-grey);
}

.sBhBqW_isIconStyle:focus svg path, .sBhBqW_isIconStyle:hover svg path {
  fill: var(--token-LEGACY-accent-theme-1-interface-interface-white);
}

.sBhBqW_isDiamondStyle {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-foreground);
  width: 75px;
  height: 75px;
  -webkit-mask-image: url("/assets/global/images/diamond-button-mask.svg");
  mask-image: url("/assets/global/images/diamond-button-mask.svg");
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}

.sBhBqW_isDiamondStyle svg path {
  fill: var(--token-LEGACY-accent-theme-1-button-button-secondary);
}

.sBhBqW_isDiamondStyle:focus, .sBhBqW_isDiamondStyle:hover {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-active);
}

.sBhBqW_isDiamondStyle:focus svg path, .sBhBqW_isDiamondStyle:hover svg path {
  fill: var(--token-accent-theme-1-button-button-highlight);
}

.Dpptja_fontSizeH1 {
  font-size: 55px;
}

.Dpptja_fontSizeH2 {
  font-size: 44px;
}

.Dpptja_fontSizeH3 {
  font-size: 35px;
}

.Dpptja_fontSizeH4 {
  font-size: 28px;
}

.Dpptja_fontSizeH5 {
  font-size: 22px;
}

.Dpptja_fontSizeH6 {
  font-size: 18px;
}

.Dpptja_fontSizep {
  font-size: 14px;
}

.Dpptja_fontSizeSmall {
  font-size: 12px;
}

.Dpptja_fontSizeTiny {
  font-size: 8px;
}

.Dpptja_ProfileBackground {
  width: 100%;
  height: 100%;
}

.Dpptja_ProfileBackground div {
  background-position: top;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.wSqWdq_fontSizeH1 {
  font-size: 55px;
}

.wSqWdq_fontSizeH2 {
  font-size: 44px;
}

.wSqWdq_fontSizeH3 {
  font-size: 35px;
}

.wSqWdq_fontSizeH4 {
  font-size: 28px;
}

.wSqWdq_fontSizeH5 {
  font-size: 22px;
}

.wSqWdq_fontSizeH6 {
  font-size: 18px;
}

.wSqWdq_fontSizep {
  font-size: 14px;
}

.wSqWdq_fontSizeSmall {
  font-size: 12px;
}

.wSqWdq_fontSizeTiny {
  font-size: 8px;
}

.wSqWdq_ProfileCharacter {
  position: relative;
}

.wSqWdq_ProfileCharacter div {
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
}

.q8btAG_fontSizeH1 {
  font-size: 55px;
}

.q8btAG_fontSizeH2 {
  font-size: 44px;
}

.q8btAG_fontSizeH3 {
  font-size: 35px;
}

.q8btAG_fontSizeH4 {
  font-size: 28px;
}

.q8btAG_fontSizeH5 {
  font-size: 22px;
}

.q8btAG_fontSizeH6 {
  font-size: 18px;
}

.q8btAG_fontSizep {
  font-size: 14px;
}

.q8btAG_fontSizeSmall {
  font-size: 12px;
}

.q8btAG_fontSizeTiny {
  font-size: 8px;
}

.q8btAG_ProfileAssetsContainer {
  background-color: #000;
  width: 100%;
  height: 100%;
  position: relative;
}

.q8btAG_ProfileAssetsContainer .q8btAG_loadingScreen {
  background-color: #000;
  transition: none;
}

.q8btAG_ProfileAssetsContainer .q8btAG_background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.q8btAG_ProfileAssetsContainer .q8btAG_character {
  pointer-events: none;
  width: 100%;
  height: calc(100% - 50px);
  position: absolute;
  bottom: 0;
  left: 0;
}

.q8btAG_ProfileAssetsContainer .q8btAG_avatar {
  pointer-events: none;
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.-HmIza_fontSizeH1 {
  font-size: 55px;
}

.-HmIza_fontSizeH2 {
  font-size: 44px;
}

.-HmIza_fontSizeH3 {
  font-size: 35px;
}

.-HmIza_fontSizeH4 {
  font-size: 28px;
}

.-HmIza_fontSizeH5 {
  font-size: 22px;
}

.-HmIza_fontSizeH6 {
  font-size: 18px;
}

.-HmIza_fontSizep {
  font-size: 14px;
}

.-HmIza_fontSizeSmall {
  font-size: 12px;
}

.-HmIza_fontSizeTiny {
  font-size: 8px;
}

.-HmIza_PostPurchaseScreen {
  z-index: 1000;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.-HmIza_PostPurchaseScreen a {
  text-decoration: underline;
}

.-HmIza_PostPurchaseScreen .-HmIza_modal {
  text-align: center;
  background-size: 100% 100%;
  width: 300px;
  max-width: calc(100% - 40px);
  min-height: 300px;
  padding: 40px;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.-HmIza_PostPurchaseScreen .-HmIza_modal.-HmIza_noBackgroundImage {
  background-color: var(--token-accent-theme-1-interface-mono-interface-midground);
  border-radius: 10px;
}

.-HmIza_PostPurchaseScreen .-HmIza_modal.-HmIza_verifying {
  justify-content: center;
  align-items: end;
}

.-HmIza_PostPurchaseScreen .-HmIza_modal.-HmIza_failed, .-HmIza_PostPurchaseScreen .-HmIza_modal.-HmIza_successNoPack, .-HmIza_PostPurchaseScreen .-HmIza_modal.-HmIza_checkoutCancelled {
  flex-direction: column;
  justify-content: space-between;
}

.-HmIza_PostPurchaseScreen .-HmIza_modal.-HmIza_failed {
  min-height: 320px;
}

.-HmIza_PostPurchaseScreen .-HmIza_modal.-HmIza_checkoutCancelled {
  min-height: 200px;
}

.-HmIza_PostPurchaseScreen .-HmIza_modal.-HmIza_success {
  width: 450px;
  padding: 0;
  display: block;
}

.-HmIza_PostPurchaseScreen .-HmIza_modal.-HmIza_success .-HmIza_scrollContainer {
  width: 100%;
  max-height: calc(100vh - 50px);
  padding: 40px;
  position: relative;
  overflow: hidden auto;
}

.-HmIza_PostPurchaseScreen .-HmIza_modal.-HmIza_success .-HmIza_scrollContainer::-webkit-scrollbar {
  width: 15px;
}

.-HmIza_PostPurchaseScreen .-HmIza_modal.-HmIza_success .-HmIza_scrollContainer::-webkit-scrollbar-thumb {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-foreground);
  background-clip: content-box;
  border: 3px solid #0000;
  border-radius: 8px;
}

.-HmIza_PostPurchaseScreen .-HmIza_modal.-HmIza_success .-HmIza_scrollContainer::-webkit-scrollbar-track {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-background);
  background-clip: content-box;
  border-right: 1px solid #0000;
  border-radius: 10px;
}

.-HmIza_PostPurchaseScreen .-HmIza_modal.-HmIza_success h2 {
  margin: 10px 0;
}

.-HmIza_PostPurchaseScreen .-HmIza_modal.-HmIza_success p {
  margin-bottom: 15px;
  font-size: 12px;
}

.-HmIza_PostPurchaseScreen .-HmIza_modal.-HmIza_success .-HmIza_assetsContainer {
  aspect-ratio: 10 / 12;
}

.-HmIza_PostPurchaseScreen .-HmIza_modal.-HmIza_success .-HmIza_closeButton {
  z-index: 1;
  position: absolute;
  top: -15px;
  right: -15px;
}

.-HmIza_PostPurchaseScreen .-HmIza_modal.-HmIza_success .-HmIza_buttons {
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: 10px;
  width: 100%;
  display: flex;
}

.-HmIza_PostPurchaseScreen .-HmIza_modal.-HmIza_success .-HmIza_buttons .-HmIza_later {
  border: 1px solid var(--token-accent-theme-1-button-button-default);
  background-color: #0000;
}

.-HmIza_PostPurchaseScreen .-HmIza_modal.-HmIza_success .-HmIza_buttons .-HmIza_later:hover, .-HmIza_PostPurchaseScreen .-HmIza_modal.-HmIza_success .-HmIza_buttons .-HmIza_later:focus {
  background-color: var(--token-accent-theme-1-interface-mono-interface-midground);
}

.-HmIza_PostPurchaseScreen .-HmIza_modal.-HmIza_success .-HmIza_buttons > * {
  width: 50%;
}

.-HmIza_PostPurchaseScreen .-HmIza_modal h2 {
  font-family: var(--font-primary);
}

.-HmIza_PostPurchaseScreen .-HmIza_modal .-HmIza_loadingSpinner {
  height: 70%;
}

.-HmIza_PostPurchaseScreen .-HmIza_background {
  opacity: .8;
  backdrop-filter: blur(12px);
  background-color: #000;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.kdx_FG_fontSizeH1 {
  font-size: 55px;
}

.kdx_FG_fontSizeH2 {
  font-size: 44px;
}

.kdx_FG_fontSizeH3 {
  font-size: 35px;
}

.kdx_FG_fontSizeH4 {
  font-size: 28px;
}

.kdx_FG_fontSizeH5 {
  font-size: 22px;
}

.kdx_FG_fontSizeH6 {
  font-size: 18px;
}

.kdx_FG_fontSizep {
  font-size: 14px;
}

.kdx_FG_fontSizeSmall {
  font-size: 12px;
}

.kdx_FG_fontSizeTiny {
  font-size: 8px;
}

.kdx_FG_blur.kdx_FG_visible {
  -webkit-backdrop-filter: blur(10px);
}

.kdx_FG_modalDrawer {
  z-index: 5;
  pointer-events: none;
  width: 100%;
  transition: top .3s ease-out, transform .3s ease-out;
  position: absolute;
  top: calc(100% + 100px);
  left: 0;
}

.kdx_FG_modalDrawer.kdx_FG_visible {
  top: 50%;
  transform: translateY(-50%);
}

.kdx_FG_Modal {
  color: var(--token-accent-theme-1-text-text-default);
  text-align: center;
  pointer-events: all;
  background-size: 100% 100%;
  width: 565px;
  max-width: 100%;
  height: 227px;
  max-height: 100dvh;
  margin: 0 auto;
  padding: 10px 2px;
  position: relative;
}

.kdx_FG_Modal .kdx_FG_modalTitle {
  font-family: var(--font-primary);
  font-size: 22px;
}

.kdx_FG_Modal.kdx_FG_noBackgroundImage {
  background-color: var(--token-accent-theme-1-interface-mono-interface-midground);
  border-radius: 10px;
}

.kdx_FG_Modal.kdx_FG_leaveSpaceAboveAndBelow {
  max-height: calc(100dvh - 200px);
}

.kdx_FG_Modal.kdx_FG_leaveSpaceAboveAndBelow .kdx_FG_contentContainer {
  max-height: calc(100dvh - 220px);
}

.kdx_FG_Modal .kdx_FG_closeButton {
  z-index: 1;
  position: absolute;
}

.kdx_FG_Modal .kdx_FG_closeButton.kdx_FG_round {
  top: -5px;
  right: -5px;
}

.kdx_FG_Modal .kdx_FG_closeButton.kdx_FG_icon {
  top: 10px;
  right: 10px;
}

.kdx_FG_Modal .kdx_FG_contentContainer {
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  max-height: calc(100dvh - 20px);
  padding: 20px;
  display: flex;
}

.kdx_FG_Modal.kdx_FG_canScroll .kdx_FG_contentContainer {
  display: block;
  overflow: auto;
}

.kdx_FG_Modal.kdx_FG_canScroll .kdx_FG_contentContainer::-webkit-scrollbar {
  width: 15px;
}

.kdx_FG_Modal.kdx_FG_canScroll .kdx_FG_contentContainer::-webkit-scrollbar-thumb {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-foreground);
  background-clip: content-box;
  border: 3px solid #0000;
  border-radius: 8px;
}

.kdx_FG_Modal.kdx_FG_canScroll .kdx_FG_contentContainer::-webkit-scrollbar-track {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-background);
  background-clip: content-box;
  border-right: 1px solid #0000;
  border-radius: 10px;
}

.savgpW_fontSizeH1 {
  font-size: 55px;
}

.savgpW_fontSizeH2 {
  font-size: 44px;
}

.savgpW_fontSizeH3 {
  font-size: 35px;
}

.savgpW_fontSizeH4 {
  font-size: 28px;
}

.savgpW_fontSizeH5 {
  font-size: 22px;
}

.savgpW_fontSizeH6 {
  font-size: 18px;
}

.savgpW_fontSizep {
  font-size: 14px;
}

.savgpW_fontSizeSmall {
  font-size: 12px;
}

.savgpW_fontSizeTiny {
  font-size: 8px;
}

.savgpW_AuthButton {
  color: var(--token-system-text-default);
  background-color: var(--token-system-brand-mono);
  text-align: center;
  border-radius: 20px;
  width: 100%;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  display: block;
}

.savgpW_AuthButton.savgpW_isHighlighted {
  background-color: var(--token-system-brand);
}

.savgpW_AuthButton:hover {
  filter: brightness(1.5);
}

.savgpW_AuthButton:disabled {
  filter: brightness(.5);
}

.savgpW_externalLoginButtons {
  width: 100%;
}

.savgpW_externalLoginButtons > :not(:last-child) {
  margin-bottom: 16px;
}

.savgpW_gsi-material-button {
  -webkit-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  appearance: none;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  background-color: #fff;
  background-image: none;
  border: 1px solid #747775;
  border-radius: 20px;
  outline: none;
  width: 100%;
  min-width: min-content;
  max-width: 400px;
  height: 40px;
  padding: 0 12px;
  font-size: 14px;
  font-weight: bold;
  transition: background-color .218s, border-color .218s, box-shadow .218s;
  position: relative;
  overflow: hidden;
}

.savgpW_gsi-material-button .savgpW_gsi-material-button-icon {
  width: 20px;
  min-width: 20px;
  height: 20px;
  margin-left: 30px;
  margin-right: -30px;
}

.savgpW_gsi-material-button .savgpW_gsi-material-button-content-wrapper {
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.savgpW_gsi-material-button .savgpW_gsi-material-button-contents {
  text-overflow: ellipsis;
  vertical-align: top;
  flex-grow: 1;
  font-weight: bold;
  overflow: hidden;
}

.savgpW_gsi-material-button .savgpW_gsi-material-button-state {
  opacity: 0;
  transition: opacity .218s;
  position: absolute;
  inset: 0;
}

.savgpW_gsi-material-button:active .savgpW_gsi-material-button-state, .savgpW_gsi-material-button:focus .savgpW_gsi-material-button-state {
  opacity: .12;
  background-color: #303030;
}

.savgpW_gsi-material-button:hover {
  box-shadow: 0 1px 2px #3c40434d, 0 1px 3px 1px #3c404326;
}

.savgpW_gsi-material-button:hover .savgpW_gsi-material-button-state {
  opacity: .08;
  background-color: #303030;
}

.yJ5yJW_fontSizeH1 {
  font-size: 55px;
}

.yJ5yJW_fontSizeH2 {
  font-size: 44px;
}

.yJ5yJW_fontSizeH3 {
  font-size: 35px;
}

.yJ5yJW_fontSizeH4 {
  font-size: 28px;
}

.yJ5yJW_fontSizeH5 {
  font-size: 22px;
}

.yJ5yJW_fontSizeH6 {
  font-size: 18px;
}

.yJ5yJW_fontSizep {
  font-size: 14px;
}

.yJ5yJW_fontSizeSmall {
  font-size: 12px;
}

.yJ5yJW_fontSizeTiny {
  font-size: 8px;
}

.yJ5yJW_ButtonLink {
  background: none;
  margin: 0;
  padding: 0;
  display: inline;
}

.yJ5yJW_ButtonLink.yJ5yJW_highlighted {
  color: var(--token-system-brand);
}

.yJ5yJW_ButtonLink:hover, .yJ5yJW_ButtonLink:focus {
  text-decoration: underline;
}

.XwDFiW_fontSizeH1 {
  font-size: 55px;
}

.XwDFiW_fontSizeH2 {
  font-size: 44px;
}

.XwDFiW_fontSizeH3 {
  font-size: 35px;
}

.XwDFiW_fontSizeH4 {
  font-size: 28px;
}

.XwDFiW_fontSizeH5 {
  font-size: 22px;
}

.XwDFiW_fontSizeH6 {
  font-size: 18px;
}

.XwDFiW_fontSizep {
  font-size: 14px;
}

.XwDFiW_fontSizeSmall {
  font-size: 12px;
}

.XwDFiW_fontSizeTiny {
  font-size: 8px;
}

.XwDFiW_AriseLoginModal {
  color: var(--token-system-text-default);
  text-align: center;
  width: 364px;
  height: 620px;
  font-family: Inter;
  font-size: 14px;
  position: relative;
  background-color: var(--token-system-interface-background) !important;
}

.XwDFiW_AriseLoginModal .XwDFiW_backButton {
  position: absolute;
  top: 10px;
  left: 10px;
}

.XwDFiW_AriseLoginModal .XwDFiW_closeButton svg path, .XwDFiW_AriseLoginModal .XwDFiW_backButton svg path {
  fill: var(--token-system-interface-foreground);
}

.XwDFiW_AriseLoginModal .XwDFiW_closeButton:hover svg path, .XwDFiW_AriseLoginModal .XwDFiW_backButton:hover svg path {
  fill: var(--token-system-text-alt);
}

.XwDFiW_AriseLoginModal h2 {
  text-align: center;
  width: 100%;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: bold;
}

.XwDFiW_AriseLoginModal a {
  color: var(--token-system-text-alt);
}

.XwDFiW_AriseLoginModal a:hover, .XwDFiW_AriseLoginModal a:focus {
  text-decoration: underline;
}

.XwDFiW_AriseLoginModal .XwDFiW_logo {
  display: inline-block;
}

.XwDFiW_AriseLoginModal .XwDFiW_intro {
  margin-bottom: 33px;
}

.XwDFiW_AriseLoginModal .XwDFiW_smallText {
  font-size: 12px;
}

.XwDFiW_AriseLoginModal .XwDFiW_emailInfo {
  margin-top: 12px;
  margin-bottom: 28px;
}

.XwDFiW_AriseLoginModal .XwDFiW_separator {
  text-align: center;
  align-items: center;
  width: 100%;
  height: 50px;
  display: flex;
}

.XwDFiW_AriseLoginModal .XwDFiW_separator:before, .XwDFiW_AriseLoginModal .XwDFiW_separator:after {
  content: "";
  border-bottom: 1px solid var(--token-system-text-default);
  opacity: .3;
  flex: 1;
  margin: 2px;
}

.XwDFiW_AriseLoginModal .XwDFiW_separator:before {
  margin-right: 20px;
}

.XwDFiW_AriseLoginModal .XwDFiW_separator:after {
  margin-left: 20px;
}

.XwDFiW_AriseLoginModal .XwDFiW_loginLink {
  color: var(--token-system-brand);
  display: inline-block;
}

.XwDFiW_AriseLoginModal .XwDFiW_errorMessage {
  color: var(--token-system-state-error);
  text-align: center;
  margin-top: 5px;
}

.XwDFiW_AriseLoginModal .XwDFiW_errorMessage:empty:before {
  content: "";
  display: inline-block;
}

.XwDFiW_AriseLoginModal input {
  accent-color: var(--token-system-interface-midground);
  background-color: var(--token-system-interface-midground);
  color: var(--token-system-text-default);
  border-color: var(--token-system-interface-foreground-alt);
  border-radius: 4px;
}

.XwDFiW_AriseLoginModal input:focus {
  border-color: var(--token-system-interface-foreground);
}

.XwDFiW_AriseLoginModal input::placeholder {
  opacity: 0;
}

.XwDFiW_AriseLoginModal input[type="checkbox"]:checked {
  width: 24px;
  height: 24px;
}

.XwDFiW_AriseLoginModal .XwDFiW_input {
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
}

.XwDFiW_AriseLoginModal label {
  text-align: left;
  width: 100%;
  margin-bottom: 5px;
  margin-left: 2px;
  display: inline-block;
}

.XwDFiW_AriseLoginModal .XwDFiW_checkboxContainer {
  width: 100%;
  margin: 10px 0;
  display: flex;
}

.XwDFiW_AriseLoginModal .XwDFiW_checkboxContainer input {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.XwDFiW_AriseLoginModal .XwDFiW_checkboxContainer label {
  font-size: 12px;
}

.XwDFiW_AriseLoginModal .XwDFiW_forgotPassword {
  text-align: left;
  width: 100%;
  margin-bottom: 10px;
  display: inline-block;
}

.XwDFiW_AriseLoginModal .XwDFiW_turnStyle {
  margin: 10px 0;
}

.XwDFiW_bottomText {
  text-align: center;
  width: 100%;
  margin-top: 10px;
  display: inline-block;
}

._6TWM_W_fontSizeH1 {
  font-size: 55px;
}

._6TWM_W_fontSizeH2 {
  font-size: 44px;
}

._6TWM_W_fontSizeH3 {
  font-size: 35px;
}

._6TWM_W_fontSizeH4 {
  font-size: 28px;
}

._6TWM_W_fontSizeH5 {
  font-size: 22px;
}

._6TWM_W_fontSizeH6 {
  font-size: 18px;
}

._6TWM_W_fontSizep {
  font-size: 14px;
}

._6TWM_W_fontSizeSmall {
  font-size: 12px;
}

._6TWM_W_fontSizeTiny {
  font-size: 8px;
}

._6TWM_W_RedeemPrizeModal {
  text-transform: uppercase;
  font-weight: bold;
}

._6TWM_W_RedeemPrizeModal ._6TWM_W_modal {
  width: 371px;
  max-width: calc(100% - 40px);
  height: auto;
}

._6TWM_W_RedeemPrizeModal ._6TWM_W_modal ._6TWM_W_contentContainer {
  max-height: calc(100dvh - 50px);
}

._6TWM_W_RedeemPrizeModal ._6TWM_W_modal h2 {
  font-family: var(--font-quaternary);
  margin-bottom: -12px;
  font-size: 44px;
}

._6TWM_W_RedeemPrizeModal ._6TWM_W_modal h3 {
  color: var(--token-accent-theme-1-brand-palette-brand-accent-1);
  font-size: 18px;
}

._6TWM_W_RedeemPrizeModal ._6TWM_W_modal p {
  margin-bottom: 15px;
  font-size: 14px;
}

._6TWM_W_RedeemPrizeModal ._6TWM_W_modal ._6TWM_W_prizeContainer {
  margin: 23px 0;
  position: relative;
}

._6TWM_W_RedeemPrizeModal ._6TWM_W_modal ._6TWM_W_prizeContainer ._6TWM_W_prizeImage {
  background-color: var(--token-accent-theme-1-brand-palette-brand-accent-1);
  border-radius: 15px;
  min-width: 200px;
  max-width: 290px;
  min-height: 200px;
  max-height: 290px;
  display: inline-block;
}

._6TWM_W_RedeemPrizeModal ._6TWM_W_modal ._6TWM_W_prizeContainer ._6TWM_W_codeContainer {
  position: absolute;
  bottom: 10px;
  right: 0;
}

._6TWM_W_RedeemPrizeModal ._6TWM_W_modal ._6TWM_W_prizeContainer ._6TWM_W_codeContainer ._6TWM_W_codeIntro {
  text-align: left;
  text-shadow: -2px -2px #000, 2px -2px #000, -2px 2px #000, 2px 2px #000, -2px 0 #000, 2px 0 #000, 0 -2px #000, 0 2px #000;
  width: 100%;
  display: block;
}

._6TWM_W_RedeemPrizeModal ._6TWM_W_modal ._6TWM_W_prizeContainer ._6TWM_W_codeContainer ._6TWM_W_code {
  background-color: var(--token-accent-theme-1-interface-mono-interface-white);
  color: var(--token-accent-theme-1-interface-mono-interface-black);
  text-align: center;
  outline: 2px solid var(--token-accent-theme-1-interface-mono-interface-black);
  cursor: pointer;
  border-radius: 10px;
  max-width: 136px;
}

._6TWM_W_RedeemPrizeModal ._6TWM_W_modal ._6TWM_W_prizeContainer ._6TWM_W_codeContainer ._6TWM_W_code:hover {
  filter: brightness(.9);
}

._6TWM_W_RedeemPrizeModal ._6TWM_W_modal ._6TWM_W_closeButton {
  z-index: 1;
  position: absolute;
  top: -15px;
  right: -15px;
}

._6TWM_W_RedeemPrizeModal ._6TWM_W_modal ._6TWM_W_ctaButton {
  color: var(--token-accent-theme-1-interface-mono-interface-black);
  font-family: var(--font-quaternary);
  text-transform: uppercase;
  width: 264px;
  padding: 10px;
  font-size: 22px;
  display: inline-block;
}

._6TWM_W_RedeemPrizeModal ._6TWM_W_modal ._6TWM_W_ctaButton:hover {
  filter: brightness(1.5);
}

._6TWM_W_RedeemPrizeModal ._6TWM_W_modal ._6TWM_W_firstButton {
  margin-bottom: 10px;
}

._6TWM_W_RedeemPrizeModal ._6TWM_W_modal ._6TWM_W_highlight {
  background-color: var(--token-accent-theme-1-brand-palette-brand-accent-2);
}

._6TWM_W_RedeemPrizeModal ._6TWM_W_modal ._6TWM_W_lowlight {
  background-color: var(--token-accent-theme-1-button-button-secondary-inactive);
}

._6TWM_W_RedeemPrizeModal ._6TWM_W_modal ._6TWM_W_readMoreLink {
  color: var(--token-accent-theme-1-button-button-secondary-inactive);
  width: 100%;
  margin-top: 21px;
  text-decoration: underline;
  display: inline-block;
}

._6TWM_W_RedeemPrizeModal ._6TWM_W_modal ._6TWM_W_readMoreLink:hover {
  filter: brightness(1.5);
}

@media (width <= 768px) {
  ._6TWM_W_RedeemPrizeModal ._6TWM_W_modal h2 {
    font-size: 35px;
  }

  ._6TWM_W_RedeemPrizeModal ._6TWM_W_modal h3 {
    font-size: 18px;
  }

  ._6TWM_W_RedeemPrizeModal ._6TWM_W_modal p {
    font-size: 14px;
  }

  ._6TWM_W_RedeemPrizeModal ._6TWM_W_modal ._6TWM_W_prizeContainer ._6TWM_W_prizeImage {
    max-width: 230px;
    max-height: 230px;
  }

  ._6TWM_W_RedeemPrizeModal ._6TWM_W_modal ._6TWM_W_ctaButton {
    padding: 10px;
    font-size: 18px;
  }
}

._6TWM_W_RedeemPrizeModal ._6TWM_W_background {
  opacity: .8;
  backdrop-filter: blur(12px);
  background-color: #000;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.\!container {
  width: 100% !important;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .\!container {
    max-width: 640px !important;
  }

  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .\!container {
    max-width: 768px !important;
  }

  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .\!container {
    max-width: 1024px !important;
  }

  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .\!container {
    max-width: 1280px !important;
  }

  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .\!container {
    max-width: 1536px !important;
  }

  .container {
    max-width: 1536px;
  }
}

.\!visible {
  visibility: visible !important;
}

.visible {
  visibility: visible;
}

.collapse {
  visibility: collapse;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.bottom-0 {
  bottom: 0;
}

.bottom-\[60px\] {
  bottom: 60px;
}

.left-0 {
  left: 0;
}

.left-\[60px\] {
  left: 60px;
}

.right-0 {
  right: 0;
}

.top-0 {
  top: 0;
}

.top-\[50\%\] {
  top: 50%;
}

.z-10 {
  z-index: 10;
}

.z-\[-1\] {
  z-index: -1;
}

.z-\[100\] {
  z-index: 100;
}

.mb-4 {
  margin-bottom: 1rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.hidden {
  display: none;
}

.h-\[150px\] {
  height: 150px;
}

.h-full {
  height: 100%;
}

.w-\[150px\] {
  width: 150px;
}

.w-\[60\%\] {
  width: 60%;
}

.w-full {
  width: 100%;
}

.flex-1 {
  flex: 1;
}

.-translate-y-\[50\%\] {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.skew-x-\[-20deg\] {
  --tw-skew-x: -20deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-150 {
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-\[\.6\] {
  --tw-scale-x: .6;
  --tw-scale-y: .6;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.resize {
  resize: both;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.overflow-hidden {
  overflow: hidden;
}

.rounded-md {
  border-radius: .375rem;
}

.bg-\[\#413832\] {
  --tw-bg-opacity: 1;
  background-color: rgb(65 56 50 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

.p-2 {
  padding: .5rem;
}

.p-4 {
  padding: 1rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-\[50px\] {
  padding-top: 50px;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-\[12px\] {
  font-size: 12px;
}

.text-\[14px\] {
  font-size: 14px;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.italic {
  font-style: italic;
}

.text-\[\#F6CEB2\] {
  --tw-text-opacity: 1;
  color: rgb(246 206 178 / var(--tw-text-opacity));
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur-md {
  --tw-backdrop-blur: blur(12px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-filter {
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[backdrop-filter\] {
  transition-property: -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-300 {
  transition-duration: .3s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.mainLoader {
  text-align: center;
  pointer-events: none;
  touch-action: none;
  font-family: var(--font-secondary, system-ui), system-ui;
  background-color: #000;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.mainLoader__text {
  color: #fff;
  opacity: 0;
  max-width: 400px;
  padding: 20px;
  transition: opacity 5s ease-in 5s;
  animation: 5s 5s forwards fade-in;
  position: absolute;
  top: calc(50% + 70px);
}

.mainLoader__title {
  padding-bottom: 10px;
  font-weight: bold;
  display: block;
}

.mainLoader__subtitle {
  font-style: italic;
  display: block;
}

.mainLoader:before, .mainLoader:after {
  content: "";
  opacity: 0;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 5s ease-in 1s;
  display: block;
  position: absolute;
}

.mainLoader:before {
  background-image: url("/assets/global/preloader/logo.png");
  width: 90px;
  height: 90px;
  animation: 5s 1s forwards fade-in;
}

.mainLoader:after {
  background-image: url("/assets/global/preloader/spinner.svg");
  width: 150px;
  height: 150px;
  animation: 5s 1s forwards fade-in, 2.2s infinite rotate-and-back;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes rotate-and-back {
  0%, 100% {
    transform: rotate(0);
  }

  10% {
    animation-timing-function: ease-in-out;
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  60% {
    animation-timing-function: ease-in-out;
    transform: rotate(0);
  }
}

body, html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--font-secondary);
  background-color: #000;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
}

.lakUJq_fontSizeH1 {
  font-size: 55px;
}

.lakUJq_fontSizeH2 {
  font-size: 44px;
}

.lakUJq_fontSizeH3 {
  font-size: 35px;
}

.lakUJq_fontSizeH4 {
  font-size: 28px;
}

.lakUJq_fontSizeH5 {
  font-size: 22px;
}

.lakUJq_fontSizeH6 {
  font-size: 18px;
}

.lakUJq_fontSizep {
  font-size: 14px;
}

.lakUJq_fontSizeSmall {
  font-size: 12px;
}

.lakUJq_fontSizeTiny {
  font-size: 8px;
}

.lakUJq_Sidebar {
  transition: transform-origin .5s cubic-bezier(.5, .33, .33, 1);
}

.kiTIEW_fontSizeH1 {
  font-size: 55px;
}

.kiTIEW_fontSizeH2 {
  font-size: 44px;
}

.kiTIEW_fontSizeH3 {
  font-size: 35px;
}

.kiTIEW_fontSizeH4 {
  font-size: 28px;
}

.kiTIEW_fontSizeH5 {
  font-size: 22px;
}

.kiTIEW_fontSizeH6 {
  font-size: 18px;
}

.kiTIEW_fontSizep {
  font-size: 14px;
}

.kiTIEW_fontSizeSmall {
  font-size: 12px;
}

.kiTIEW_fontSizeTiny {
  font-size: 8px;
}

.kiTIEW_AuthDrawer {
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.kiTIEW_AuthDrawer__drawer {
  width: calc(100% + 989px);
  height: 100%;
  transition-property: margin-left;
  transition-timing-function: cubic-bezier(.5, .33, .33, 1);
  position: absolute;
  left: calc(-100% - 989px);
}

.kiTIEW_AuthDrawer__drawerLeft {
  width: calc(100% - 989px);
  height: 100%;
  display: inline-block;
  overflow: hidden;
}

.kiTIEW_AuthDrawer__drawerRight {
  background-position-x: right;
  background-repeat: no-repeat;
  background-size: cover;
  width: 989px;
  height: 100%;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.kiTIEW_AuthDrawer__drawer--none {
  margin-left: 0%;
}

.kiTIEW_AuthDrawer__drawer--small {
  margin-left: 594px;
}

@media (width <= 768px) {
  .kiTIEW_AuthDrawer__drawer--small {
    margin-left: calc(100% + 10px);
  }
}

.kiTIEW_AuthDrawer__drawer--mediumContent {
  margin-left: 864px;
}

@media (width <= 768px) {
  .kiTIEW_AuthDrawer__drawer--mediumContent {
    margin-left: calc(100% + 200px);
  }
}

.kiTIEW_AuthDrawer__drawer--largeContent {
  margin-left: 989px;
}

.kiTIEW_AuthDrawer__drawer--full {
  margin-left: calc(100% + 989px);
}

.kiTIEW_AuthDrawer--noImage .kiTIEW_AuthDrawer__drawer {
  -webkit-clip-path: polygon(0% 0%, calc(100% - 400px) 0%, 100% 100%, 0% 100%);
  clip-path: polygon(0% 0%, calc(100% - 400px) 0%, 100% 100%, 0% 100%);
  backdrop-filter: blur(10px);
  height: 100%;
  min-height: 1117px;
  bottom: 0;
}

.kiTIEW_AuthDrawer--noImage .kiTIEW_AuthDrawer__drawerLeft, .kiTIEW_AuthDrawer--noImage .kiTIEW_AuthDrawer__drawerRight {
  background-color: var(--token-accent-theme-1-interface-mono-interface-background) !important;
}

.KFENNG_fontSizeH1 {
  font-size: 55px;
}

.KFENNG_fontSizeH2 {
  font-size: 44px;
}

.KFENNG_fontSizeH3 {
  font-size: 35px;
}

.KFENNG_fontSizeH4 {
  font-size: 28px;
}

.KFENNG_fontSizeH5 {
  font-size: 22px;
}

.KFENNG_fontSizeH6 {
  font-size: 18px;
}

.KFENNG_fontSizep {
  font-size: 14px;
}

.KFENNG_fontSizeSmall {
  font-size: 12px;
}

.KFENNG_fontSizeTiny {
  font-size: 8px;
}

.KFENNG_Badge {
  color: var(--token-arise-theme-1-rarity-rarity-common-light);
  background-color: var(--token-arise-theme-1-rarity-rarity-common-mid);
  border-radius: 30px;
  align-items: center;
  height: 21px;
  padding: 0 8px;
  font-size: 14px;
  font-weight: 500;
  display: inline-flex;
}

.KFENNG_Badge.KFENNG_common {
  color: var(--token-arise-theme-1-rarity-rarity-common-light);
  background-color: var(--token-arise-theme-1-rarity-rarity-common-mid);
}

.KFENNG_Badge.KFENNG_common .KFENNG_icon {
  background-color: var(--token-arise-theme-1-rarity-rarity-common-light);
}

.KFENNG_Badge.KFENNG_uncommon {
  color: var(--token-arise-theme-1-rarity-rarity-uncommon-light);
  background-color: var(--token-arise-theme-1-rarity-rarity-uncommon-mid);
}

.KFENNG_Badge.KFENNG_uncommon .KFENNG_icon {
  background-color: var(--token-arise-theme-1-rarity-rarity-uncommon-light);
}

.KFENNG_Badge.KFENNG_rare {
  color: var(--token-arise-theme-1-rarity-rarity-rare-light);
  background-color: var(--token-arise-theme-1-rarity-rarity-rare-mid);
}

.KFENNG_Badge.KFENNG_rare .KFENNG_icon {
  background-color: var(--token-arise-theme-1-rarity-rarity-rare-light);
}

.KFENNG_Badge.KFENNG_epic {
  color: var(--token-arise-theme-1-rarity-rarity-epic-light);
  background-color: var(--token-arise-theme-1-rarity-rarity-epic-mid);
}

.KFENNG_Badge.KFENNG_epic .KFENNG_icon {
  background-color: var(--token-arise-theme-1-rarity-rarity-epic-light);
}

.KFENNG_Badge.KFENNG_legendary {
  color: var(--token-arise-theme-1-rarity-rarity-legendary-light);
  background-color: var(--token-arise-theme-1-rarity-rarity-legendary-mid);
}

.KFENNG_Badge.KFENNG_legendary .KFENNG_icon {
  background-color: var(--token-arise-theme-1-rarity-rarity-legendary-light);
}

.KFENNG_Badge.KFENNG_system {
  color: var(--token-LEGACY-accent-theme-1-interface-interface-white);
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-foreground);
}

.KFENNG_Badge .KFENNG_icon {
  background-color: var(--token-arise-theme-1-rarity-rarity-common-light);
  vertical-align: text-bottom;
  width: 15px;
  height: 15px;
  margin-left: 5px;
  display: inline-block;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}

.KFENNG_Badge p {
  display: inline-block;
}

.M5cyaa_fontSizeH1 {
  font-size: 55px;
}

.M5cyaa_fontSizeH2 {
  font-size: 44px;
}

.M5cyaa_fontSizeH3 {
  font-size: 35px;
}

.M5cyaa_fontSizeH4 {
  font-size: 28px;
}

.M5cyaa_fontSizeH5 {
  font-size: 22px;
}

.M5cyaa_fontSizeH6 {
  font-size: 18px;
}

.M5cyaa_fontSizep {
  font-size: 14px;
}

.M5cyaa_fontSizeSmall {
  font-size: 12px;
}

.M5cyaa_fontSizeTiny {
  font-size: 8px;
}

.M5cyaa_DisplayName {
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: bottom;
  max-width: 100%;
  font-weight: 500;
  overflow: hidden;
}

.M5cyaa_DisplayName.M5cyaa_hasHover:hover, .M5cyaa_DisplayName.M5cyaa_hasHover:focus {
  text-decoration: underline;
}

.b9eSOq_fontSizeH1 {
  font-size: 55px;
}

.b9eSOq_fontSizeH2 {
  font-size: 44px;
}

.b9eSOq_fontSizeH3 {
  font-size: 35px;
}

.b9eSOq_fontSizeH4 {
  font-size: 28px;
}

.b9eSOq_fontSizeH5 {
  font-size: 22px;
}

.b9eSOq_fontSizeH6 {
  font-size: 18px;
}

.b9eSOq_fontSizep {
  font-size: 14px;
}

.b9eSOq_fontSizeSmall {
  font-size: 12px;
}

.b9eSOq_fontSizeTiny {
  font-size: 8px;
}

.b9eSOq_DisplayNameWithBadges .b9eSOq_displayName {
  margin-right: 6px;
  display: inline-block;
}

.b9eSOq_DisplayNameWithBadges .b9eSOq_badgeContainer {
  display: inline-block;
}

.b9eSOq_DisplayNameWithBadges .b9eSOq_badgeContainer .b9eSOq_titleBadge, .b9eSOq_DisplayNameWithBadges .b9eSOq_badgeContainer .b9eSOq_systemBadge {
  margin-right: 6px;
}

.uMShka_fontSizeH1 {
  font-size: 55px;
}

.uMShka_fontSizeH2 {
  font-size: 44px;
}

.uMShka_fontSizeH3 {
  font-size: 35px;
}

.uMShka_fontSizeH4 {
  font-size: 28px;
}

.uMShka_fontSizeH5 {
  font-size: 22px;
}

.uMShka_fontSizeH6 {
  font-size: 18px;
}

.uMShka_fontSizep {
  font-size: 14px;
}

.uMShka_fontSizeSmall {
  font-size: 12px;
}

.uMShka_fontSizeTiny {
  font-size: 8px;
}

.uMShka_MarkdownRenderer.uMShka_hasDefaultStyling h1, .uMShka_MarkdownRenderer.uMShka_hasDefaultStyling h2 {
  font-family: var(--font-primary);
  font-size: 22px;
}

.uMShka_MarkdownRenderer.uMShka_hasDefaultStyling h3, .uMShka_MarkdownRenderer.uMShka_hasDefaultStyling h4, .uMShka_MarkdownRenderer.uMShka_hasDefaultStyling h5, .uMShka_MarkdownRenderer.uMShka_hasDefaultStyling h6 {
  padding-bottom: 5px;
  font-size: 18px;
}

.uMShka_MarkdownRenderer.uMShka_hasDefaultStyling p {
  padding: 5px 0;
  font-size: 14px;
}

.uMShka_MarkdownRenderer.uMShka_hasDefaultStyling ul, .uMShka_MarkdownRenderer.uMShka_hasDefaultStyling ol {
  text-align: left;
  margin-bottom: 10px;
  margin-left: 20px;
  font-size: 14px;
  display: inline-block;
}

.uMShka_MarkdownRenderer.uMShka_hasDefaultStyling ul li {
  list-style-type: disc;
}

.uMShka_MarkdownRenderer.uMShka_hasDefaultStyling ol li {
  list-style-type: uMShka_auto;
}

.uMShka_MarkdownRenderer.uMShka_hasDefaultStyling a {
  color: var(--token-accent-theme-1-text-text-link);
}

.uMShka_MarkdownRenderer.uMShka_hasDefaultStyling a:hover, .uMShka_MarkdownRenderer.uMShka_hasDefaultStyling a:focus {
  text-decoration: underline;
}

.uMShka_MarkdownRenderer.uMShka_centerAlign {
  text-align: center;
}

.uMShka_MarkdownRenderer.uMShka_leftAlign {
  text-align: left;
}

._FajrW_fontSizeH1 {
  font-size: 55px;
}

._FajrW_fontSizeH2 {
  font-size: 44px;
}

._FajrW_fontSizeH3 {
  font-size: 35px;
}

._FajrW_fontSizeH4 {
  font-size: 28px;
}

._FajrW_fontSizeH5 {
  font-size: 22px;
}

._FajrW_fontSizeH6 {
  font-size: 18px;
}

._FajrW_fontSizep {
  font-size: 14px;
}

._FajrW_fontSizeSmall {
  font-size: 12px;
}

._FajrW_fontSizeTiny {
  font-size: 8px;
}

._FajrW_Settings {
  z-index: 50;
  width: 100%;
  height: 100%;
  transition-property: transform;
  transition-duration: .4s;
  transition-timing-function: ease-in;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-100%);
}

@media screen and (width <= 576px) {
  ._FajrW_Settings {
    transform: translate(-150%);
  }
}

._FajrW_closeButton {
  margin: 17px;
  position: absolute;
  left: 0;
}

._FajrW_backdropBlur {
  z-index: 50;
  backdrop-filter: blur();
  visibility: hidden;
  background-color: #0000;
  width: 100%;
  height: 100%;
  transition-property: visibility, background-color, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: 0s, .4s, .4s, .4s;
  transition-delay: .45s, 0s, 0s, 0s;
  position: absolute;
  top: 0;
  left: 0;
}

._FajrW_header {
  flex-direction: column;
  justify-content: center;
  height: 70px;
  display: flex;
  position: absolute;
  top: 0;
  left: 70px;
}

._FajrW_content {
  color: var(--token-accent-theme-1-text-text-default);
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: 50%;
  flex-direction: column;
  justify-content: space-between;
  width: 727px;
  max-width: 100%;
  height: calc(100% - 70px);
  font-size: 18px;
  font-weight: 500;
  display: flex;
  position: absolute;
  top: 70px;
  left: 0;
}

._FajrW_content:focus {
  outline: none;
}

._FajrW_content ._FajrW_top {
  width: 400px;
  max-width: 100%;
  padding: 40px;
}

@media screen and (width <= 768px) {
  ._FajrW_content ._FajrW_top {
    width: 300px;
    padding: 20px;
    font-size: 14px;
  }
}

._FajrW_content ._FajrW_middle {
  width: 550px;
  max-width: 100%;
  padding: 0 40px;
}

._FajrW_content ._FajrW_middle p {
  font-size: 18px !important;
}

@media screen and (width <= 768px) {
  ._FajrW_content ._FajrW_middle {
    width: 400px;
    padding: 0 20px;
  }

  ._FajrW_content ._FajrW_middle p {
    font-size: 14px !important;
  }
}

._FajrW_content ._FajrW_bottom {
  padding: 40px;
  bottom: 0;
  left: 0;
}

@media screen and (width <= 768px) {
  ._FajrW_content ._FajrW_bottom {
    padding: 20px;
  }
}

._FajrW_content ._FajrW_bottom ._FajrW_logoutButton {
  width: 245px;
  margin-bottom: 20px;
}

._FajrW_content ._FajrW_bottom ._FajrW_logos {
  align-items: center;
  width: fit-content;
  max-width: 450px;
  display: flex;
}

._FajrW_content ._FajrW_bottom ._FajrW_logos ._FajrW_logoContainer {
  text-align: left;
  flex: 1;
}

._FajrW_content ._FajrW_bottom ._FajrW_logos ._FajrW_logoContainer img {
  max-height: 50px;
  display: inline-block;
}

._FajrW_content ._FajrW_bottom ._FajrW_logos a._FajrW_logoContainer:hover, ._FajrW_content ._FajrW_bottom ._FajrW_logos a._FajrW_logoContainer:focus {
  filter: brightness(1.1);
}

._FajrW_content ._FajrW_bottom ._FajrW_footerText {
  opacity: .4;
  margin-top: 20px;
  font-family: Inter;
  font-size: 12px;
}

@media screen and (width <= 768px) {
  ._FajrW_content ._FajrW_bottom ._FajrW_footerText {
    font-size: 8px;
  }
}

._FajrW_secondaryLogo {
  width: 130px;
  margin-left: 30px;
}

._FajrW_Settings._FajrW_visible {
  transition-timing-function: ease-out;
  transform: translate(0%);
}

._FajrW_backdropBlur._FajrW_visible {
  backdrop-filter: blur(10px);
  visibility: visible;
  background-color: #0000004d;
  transition-delay: 0s, 0s, 0s, 0s;
}

.tWzobG_fontSizeH1 {
  font-size: 55px;
}

.tWzobG_fontSizeH2 {
  font-size: 44px;
}

.tWzobG_fontSizeH3 {
  font-size: 35px;
}

.tWzobG_fontSizeH4 {
  font-size: 28px;
}

.tWzobG_fontSizeH5 {
  font-size: 22px;
}

.tWzobG_fontSizeH6 {
  font-size: 18px;
}

.tWzobG_fontSizep {
  font-size: 14px;
}

.tWzobG_fontSizeSmall {
  font-size: 12px;
}

.tWzobG_fontSizeTiny {
  font-size: 8px;
}

.tWzobG_GuestBanner {
  background-color: var(--token-accent-theme-1-interface-mono-interface-background);
  color: var(--token-accent-theme-1-text-text-default);
  border-top: 1px solid #ffffff1a;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 0 8px;
  font-size: 14px;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

.tWzobG_GuestBanner .tWzobG_logo {
  height: 28px;
}

.tWzobG_GuestBanner .tWzobG_buttons {
  white-space: nowrap;
  margin-left: 11px;
}

.tWzobG_GuestBanner span {
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 8px;
  margin-right: auto;
  overflow: hidden;
}

.tWzobG_GuestBanner .tWzobG_loginButton {
  background-color: var(--token-accent-theme-1-brand-palette-brand-colour);
  width: 82px;
  height: 33px;
  padding: 0;
  display: inline-block;
  position: relative;
}

.tWzobG_GuestBanner .tWzobG_loginButton:hover {
  filter: brightness(1.5);
}

.tWzobG_GuestBanner .tWzobG_loginButton:first-child {
  background-color: var(--token-accent-theme-1-interface-mono-interface-midground);
  margin-right: 8px;
}

.tWzobG_GuestBanner.tWzobG_thoseBeyondThemed {
  background-color: var(--token-system-interface-background);
  color: var(--token-system-text-default);
}

.tWzobG_GuestBanner.tWzobG_thoseBeyondThemed .tWzobG_loginButton {
  background-color: var(--token-system-brand);
}

.tWzobG_GuestBanner.tWzobG_thoseBeyondThemed .tWzobG_loginButton:first-child {
  background-color: var(--token-system-brand-mono);
}

.Lyqp4G_fontSizeH1 {
  font-size: 55px;
}

.Lyqp4G_fontSizeH2 {
  font-size: 44px;
}

.Lyqp4G_fontSizeH3 {
  font-size: 35px;
}

.Lyqp4G_fontSizeH4 {
  font-size: 28px;
}

.Lyqp4G_fontSizeH5 {
  font-size: 22px;
}

.Lyqp4G_fontSizeH6 {
  font-size: 18px;
}

.Lyqp4G_fontSizep {
  font-size: 14px;
}

.Lyqp4G_fontSizeSmall {
  font-size: 12px;
}

.Lyqp4G_fontSizeTiny {
  font-size: 8px;
}

.Lyqp4G_SuggestionsPicker {
  border: 1px solid var(--token-accent-theme-1-border-border-default);
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-midground);
  color: var(--token-accent-theme-1-text-text-default);
  border-radius: 10px;
  width: 100%;
  max-height: 105px;
  position: absolute;
  bottom: 100%;
  overflow-y: auto;
}

.Lyqp4G_SuggestionsPicker::-webkit-scrollbar {
  width: 15px;
}

.Lyqp4G_SuggestionsPicker::-webkit-scrollbar-thumb {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-foreground);
  background-clip: content-box;
  border: 3px solid #0000;
  border-radius: 8px;
}

.Lyqp4G_SuggestionsPicker::-webkit-scrollbar-track {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-background);
  background-clip: content-box;
  border-right: 1px solid #0000;
  border-radius: 10px;
}

.Lyqp4G_SuggestionsPicker.Lyqp4G_hidden {
  display: none;
}

.Lyqp4G_SuggestionsPicker__closeButton {
  z-index: 10;
  float: right;
  width: 35px;
  height: 0;
  position: sticky;
  top: 4px;
  right: 0;
}

.Lyqp4G_SuggestionsPicker__user {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-midground);
  text-align: left;
  width: 100%;
  height: 35px;
  padding: 5px 10px;
  position: relative;
}

.Lyqp4G_SuggestionsPicker__user .Lyqp4G_avatar {
  vertical-align: bottom;
  width: 25px;
  height: 25px;
  margin-right: 10px;
  display: inline-block;
}

.Lyqp4G_SuggestionsPicker__user.Lyqp4G_active {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-foreground);
}

.Lyqp4G_SuggestionsPicker__helpText {
  color: var(--token-LEGACY-accent-theme-1-text-text-secondary);
  width: 100%;
  height: 35px;
  padding: 5px 10px;
  display: inline-block;
}

.Nv5JfG_fontSizeH1 {
  font-size: 55px;
}

.Nv5JfG_fontSizeH2 {
  font-size: 44px;
}

.Nv5JfG_fontSizeH3 {
  font-size: 35px;
}

.Nv5JfG_fontSizeH4 {
  font-size: 28px;
}

.Nv5JfG_fontSizeH5 {
  font-size: 22px;
}

.Nv5JfG_fontSizeH6 {
  font-size: 18px;
}

.Nv5JfG_fontSizep {
  font-size: 14px;
}

.Nv5JfG_fontSizeSmall {
  font-size: 12px;
}

.Nv5JfG_fontSizeTiny {
  font-size: 8px;
}

.Nv5JfG_ChatBarWrapper {
  border: 1px solid var(--token-accent-theme-1-border-border-default);
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-midground);
  border-radius: 10px;
}

.Nv5JfG_ChatBarWrapper.Nv5JfG_hasToolbar {
  padding-bottom: 30px;
}

.Nv5JfG_ChatBarWrapper.Nv5JfG_hasToolbar .Nv5JfG_Chatbar {
  min-height: 40px;
}

.Nv5JfG_ChatBarWrapper.Nv5JfG_hasToolbar .Nv5JfG_Chatbar__input {
  height: 40px;
  padding-bottom: 0;
}

.Nv5JfG_ChatBarWrapper.Nv5JfG_hasToolbar .Nv5JfG_Chatbar__sendButton {
  top: calc(100% - 26px);
}

.Nv5JfG_ChatBarWrapper .Nv5JfG_helpButton {
  width: 16px;
  height: 16px;
  position: absolute;
  bottom: 10px;
  left: 18px;
}

.Nv5JfG_ChatBarWrapper .Nv5JfG_helpButton svg {
  width: 100%;
  height: 100%;
}

.Nv5JfG_Chatbar {
  font-family: var(--font-tertiary);
  cursor: text;
  width: 100%;
  min-height: 52px;
  max-height: 116px;
  font-size: 14px;
  transition: height .1s linear, border .2s linear;
  position: relative;
  overflow-y: hidden;
}

.Nv5JfG_Chatbar::-webkit-scrollbar {
  width: 15px;
}

.Nv5JfG_Chatbar::-webkit-scrollbar-thumb {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-foreground);
  background-clip: content-box;
  border: 3px solid #0000;
  border-radius: 8px;
}

.Nv5JfG_Chatbar::-webkit-scrollbar-track {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-background);
  background-clip: content-box;
  border-right: 1px solid #0000;
  border-radius: 10px;
}

.Nv5JfG_Chatbar__input {
  color: var(--token-accent-theme-1-text-text-default);
  resize: none;
  word-break: break-word;
  background-color: #0000;
  outline: none;
  width: 100%;
  height: 52px;
  padding: 15px 42px 15px 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 21.5px;
  transition: height .1s linear;
  display: inline-block;
  position: absolute;
  overflow: hidden;
}

.Nv5JfG_Chatbar__input:empty:after {
  content: attr(placeholder);
  font-family: var(--font-secondary);
  color: var(--token-LEGACY-accent-theme-1-interface-interface-foreground);
  font-weight: 400;
  transition: color .2s linear;
}

.Nv5JfG_Chatbar__input:hover:empty:after, .Nv5JfG_Chatbar__input:focus:empty:after {
  color: var(--token-accent-theme-1-text-text-default);
}

.Nv5JfG_Chatbar__input:empty + .Nv5JfG_Chatbar__sendButton {
  opacity: 0;
  visibility: hidden;
}

.Nv5JfG_Chatbar__input:empty + .Nv5JfG_Chatbar__sendButton svg {
  transform: scaleX(0)translateX(30%);
}

.Nv5JfG_Chatbar__richText {
  pointer-events: none;
  color: #0000;
  height: 100%;
  position: absolute;
}

.Nv5JfG_Chatbar__mentionInProgress {
  color: var(--token-LEGACY-accent-theme-1-text-text-secondary);
  font-weight: bold;
  display: inline-block;
}

.Nv5JfG_Chatbar__mention {
  color: var(--token-arise-theme-1-state-state-success-light);
  font-weight: bold;
  display: inline-block;
}

.Nv5JfG_Chatbar__command {
  color: var(--token-accent-theme-1-button-button-highlight);
  padding-right: 2px;
  font-weight: bold;
  display: inline-block;
}

.Nv5JfG_Chatbar__sendButton {
  float: right;
  margin-right: 8px;
  transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
  position: sticky;
  top: calc(100% - 38px);
}

.Nv5JfG_Chatbar__sendButton svg {
  transform-origin: 100%;
  transition: transform .2s ease-in-out;
}

.Nv5JfG_Chatbar__sendButton path {
  fill: var(--token-accent-theme-1-button-button-highlight) !important;
}

.Nv5JfG_Chatbar:hover, .Nv5JfG_Chatbar:focus, .Nv5JfG_Chatbar:focus-within {
  border-color: var(--token-accent-theme-1-border-border-highlight);
}

.gdN3qq_fontSizeH1 {
  font-size: 55px;
}

.gdN3qq_fontSizeH2 {
  font-size: 44px;
}

.gdN3qq_fontSizeH3 {
  font-size: 35px;
}

.gdN3qq_fontSizeH4 {
  font-size: 28px;
}

.gdN3qq_fontSizeH5 {
  font-size: 22px;
}

.gdN3qq_fontSizeH6 {
  font-size: 18px;
}

.gdN3qq_fontSizep {
  font-size: 14px;
}

.gdN3qq_fontSizeSmall {
  font-size: 12px;
}

.gdN3qq_fontSizeTiny {
  font-size: 8px;
}

.gdN3qq_FeedInputOverlay {
  border: 1px solid var(--token-arise-theme-1-state-state-error-mid);
  background-color: var(--token-arise-theme-1-state-state-error-dark);
  color: var(--token-arise-theme-1-state-state-error-light);
  border-radius: 10px;
  align-items: center;
  width: 100%;
  height: auto;
  min-height: 52px;
  padding: 10px 35px 10px 20px;
  font-size: 14px;
  display: flex;
  position: relative;
  top: 0;
  left: 0;
}

.gdN3qq_FeedInputOverlay svg {
  position: absolute;
  right: 10px;
}

.gdN3qq_FeedInputOverlay svg path {
  fill: var(--token-arise-theme-1-state-state-error-light);
}

.P1ilkG_FailedInputOverlay {
  backdrop-filter: blur(3px);
  border-radius: 10px;
  width: 100%;
  height: 100%;
  transition: height 0s .3s, transform .3s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
  transform: translateY(200%);
}

.P1ilkG_FailedInputOverlay button {
  height: 52px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.P1ilkG_visible {
  top: 50%;
  transform: translateY(-50%);
}

.mrKkkW_fontSizeH1 {
  font-size: 55px;
}

.mrKkkW_fontSizeH2 {
  font-size: 44px;
}

.mrKkkW_fontSizeH3 {
  font-size: 35px;
}

.mrKkkW_fontSizeH4 {
  font-size: 28px;
}

.mrKkkW_fontSizeH5 {
  font-size: 22px;
}

.mrKkkW_fontSizeH6 {
  font-size: 18px;
}

.mrKkkW_fontSizep {
  font-size: 14px;
}

.mrKkkW_fontSizeSmall {
  font-size: 12px;
}

.mrKkkW_fontSizeTiny {
  font-size: 8px;
}

.mrKkkW_imagePlaceholder {
  color: #0000;
  background-color: #00000080;
  width: 100%;
  height: 100%;
}

.mrKkkW_namePlaceholder, .mrKkkW_messagePlaceholder, .mrKkkW_reactionsPlaceholder {
  color: #0000;
  background-color: #00000080;
}

.mrKkkW_ModerationActions {
  text-align: center;
  opacity: 0;
  position: absolute;
  top: 0;
  right: 5px;
}

.mrKkkW_modActionsOptions {
  z-index: 30;
  background-color: #0f0f0f;
  border: 1px solid #fff;
  width: 200px;
  position: absolute;
  right: 0;
}

.mrKkkW_modActionsOptions.mrKkkW_isOffBottom {
  bottom: 10px;
}

.mrKkkW_modActionsOptions button {
  border: 1px solid #fff;
  width: 100%;
  height: 100%;
  margin-top: 5px;
  padding: 5px;
}

.mrKkkW_modActionsOptions button:hover {
  background-color: #330101;
  text-decoration: underline;
}

.mrKkkW_ChatMessage {
  word-break: break-word;
  opacity: .5;
  border-radius: 10px;
  padding: 10px 10px 10px 66px;
}

.mrKkkW_ChatMessage .mrKkkW_reactionsMenu {
  opacity: 0;
}

.mrKkkW_ChatMessage.mrKkkW_highlighted, .mrKkkW_ChatMessage.mrKkkW_highlighted:hover, .mrKkkW_ChatMessage.mrKkkW_highlighted:focus, .mrKkkW_ChatMessage.mrKkkW_highlighted:focus-within {
  background-color: var(--token-arise-theme-1-state-state-success-dark);
}

.mrKkkW_ChatMessage:hover, .mrKkkW_ChatMessage:focus, .mrKkkW_ChatMessage:focus-within {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-midground);
}

.mrKkkW_ChatMessage:hover .mrKkkW_reactionsMenu, .mrKkkW_ChatMessage:focus .mrKkkW_reactionsMenu, .mrKkkW_ChatMessage:focus-within .mrKkkW_reactionsMenu, .mrKkkW_ChatMessage:hover .mrKkkW_ModerationActions, .mrKkkW_ChatMessage:focus .mrKkkW_ModerationActions, .mrKkkW_ChatMessage:focus-within .mrKkkW_ModerationActions {
  opacity: 1;
}

.mrKkkW_ChatMessage.mrKkkW_isGlobalChatMessage {
  border: 1px solid var(--token-accent-theme-1-interface-interface-midground);
}

.mrKkkW_ChatMessage .mrKkkW_avatar {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 10px;
  left: 13px;
}

.mrKkkW_ChatMessage .mrKkkW_mention {
  color: var(--token-arise-theme-1-state-state-success-light);
  font-weight: bold;
}

.mrKkkW_ChatMessage .mrKkkW_messageText {
  margin-top: 6px;
}

.mrKkkW_ChatMessage.mrKkkW_visible {
  opacity: 1;
}

.mrKkkW_LocationLink {
  color: var(--token-accent-theme-1-interface-interface-black);
  background-color: var(--token-accent-theme-1-interface-interface-white);
  cursor: pointer;
  border-radius: 18px;
  padding: 3px 10px;
  font-size: 12px;
  font-weight: bold;
  line-height: 12px;
  transition: opacity .3s;
  display: inline-block;
}

.mrKkkW_LocationLink:hover {
  opacity: .5;
}

.FX5MUW_fontSizeH1 {
  font-size: 55px;
}

.FX5MUW_fontSizeH2 {
  font-size: 44px;
}

.FX5MUW_fontSizeH3 {
  font-size: 35px;
}

.FX5MUW_fontSizeH4 {
  font-size: 28px;
}

.FX5MUW_fontSizeH5 {
  font-size: 22px;
}

.FX5MUW_fontSizeH6 {
  font-size: 18px;
}

.FX5MUW_fontSizep {
  font-size: 14px;
}

.FX5MUW_fontSizeSmall {
  font-size: 12px;
}

.FX5MUW_fontSizeTiny {
  font-size: 8px;
}

.FX5MUW_showButton, .FX5MUW_exisitingReaction {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-background);
  -webkit-user-select: none;
  user-select: none;
  border-radius: 50px;
  height: 21px;
  margin-right: 5px;
  transition: background-color .2s ease-in-out;
  display: inline-block;
}

.FX5MUW_showButton {
  text-align: left;
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-foreground);
  color: var(--token-LEGACY-accent-theme-1-text-text-secondary);
  width: 44px;
  padding-left: 8px;
  font-size: 14px;
  font-weight: bold;
  position: absolute;
  top: -10px;
  right: 10px;
}

.FX5MUW_showButton span {
  position: relative;
  top: -1px;
}

.FX5MUW_showButton.FX5MUW_isOpen {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-foreground);
}

.FX5MUW_showButton svg {
  margin-top: -2px;
}

.FX5MUW_showButton svg path {
  fill: var(--token-LEGACY-accent-theme-1-text-text-secondary);
}

.FX5MUW_showButton:hover, .FX5MUW_showButton:focus {
  color: var(--token-LEGACY-accent-theme-1-text-text-active);
}

.FX5MUW_showButton:hover svg path, .FX5MUW_showButton:focus svg path {
  fill: var(--token-LEGACY-accent-theme-1-text-text-active);
}

.FX5MUW_reactionMenuContainer {
  height: 47px;
  padding-top: 5px;
  padding-bottom: 15px;
  display: block;
  position: absolute;
  top: -47px;
  right: 10px;
}

.FX5MUW_reactionMenuContainer .FX5MUW_reactionMenu {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-background);
  white-space: nowrap;
  opacity: 0;
  border-radius: 50px;
  height: 27px;
  padding: 0 6px;
  transition: max-width .3s cubic-bezier(.5, .33, .33, 1);
  overflow: hidden;
}

.FX5MUW_reactionMenuContainer .FX5MUW_reactionMenu img {
  max-width: 20px;
  max-height: 20px;
  margin-top: -2px;
  display: inline;
}

.FX5MUW_reactionMenuContainer .FX5MUW_reactionMenu.FX5MUW_show {
  opacity: 1;
  overflow: visible;
}

.FX5MUW_reactionMenuContainer .FX5MUW_reactionMenu.FX5MUW_show .FX5MUW_reactionMenuButton {
  opacity: 1;
}

.FX5MUW_reactionMenuContainer .FX5MUW_reactionMenuButton {
  opacity: 0;
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  border-radius: 50px;
  width: 24px;
  height: 24px;
  margin-left: 1.5px;
  transition: transform .15s cubic-bezier(.5, .33, .33, 1), background-color .15s cubic-bezier(.5, .33, .33, 1), opacity .5s cubic-bezier(.5, .33, .33, 1);
  position: relative;
  top: 1.5px;
  transform: scale(1);
}

.FX5MUW_reactionMenuContainer .FX5MUW_reactionMenuButton.FX5MUW_active {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-foreground);
}

.FX5MUW_reactionMenuContainer .FX5MUW_reactionMenuButton:hover, .FX5MUW_reactionMenuContainer .FX5MUW_reactionMenuButton:focus {
  background-color: #0000;
  transform: scale(1.25);
}

.FX5MUW_reactionMenuContainer .FX5MUW_reactionMenuButton:hover span, .FX5MUW_reactionMenuContainer .FX5MUW_reactionMenuButton:focus span {
  bottom: 10px;
}

.FX5MUW_reactionMenuContainer .FX5MUW_reactionMenuButton span {
  transition-property: bottom;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.5, .33, .33, 1);
  position: relative;
  bottom: 0;
}

@media (width <= 992px) {
  .FX5MUW_reactionMenu {
    transform-origin: 100% 100%;
    transform: scale(1.5);
  }
}

.FX5MUW_existingReactions {
  height: 21px;
  margin-top: 6px;
  display: inline-block;
}

.FX5MUW_exisitingReaction {
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  margin-bottom: 2px;
  padding: 0 5px;
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
  position: relative;
}

.FX5MUW_exisitingReaction img {
  vertical-align: text-top;
  max-width: 16px;
  max-height: 16px;
  display: inline;
}

.FX5MUW_exisitingReaction:hover {
  transform: scale(1.1);
}

.FX5MUW_exisitingReaction.FX5MUW_active {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-foreground);
}

.eV16Sq_fontSizeH1 {
  font-size: 55px;
}

.eV16Sq_fontSizeH2 {
  font-size: 44px;
}

.eV16Sq_fontSizeH3 {
  font-size: 35px;
}

.eV16Sq_fontSizeH4 {
  font-size: 28px;
}

.eV16Sq_fontSizeH5 {
  font-size: 22px;
}

.eV16Sq_fontSizeH6 {
  font-size: 18px;
}

.eV16Sq_fontSizep {
  font-size: 14px;
}

.eV16Sq_fontSizeSmall {
  font-size: 12px;
}

.eV16Sq_fontSizeTiny {
  font-size: 8px;
}

.eV16Sq_EventSummaryMessage {
  color: var(--token-accent-theme-1-text-text-default);
  border-radius: 10px;
  margin: 0 0 5px;
  font-size: 14px;
  position: relative;
}

.eV16Sq_EventSummaryMessage .eV16Sq_container {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-foreground);
  border-radius: 10px;
  width: 100%;
  height: 100%;
  padding: 20px;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}

.eV16Sq_EventSummaryMessage .eV16Sq_container.eV16Sq_failed {
  background-color: var(--token-arise-theme-1-state-state-error-dark);
}

.eV16Sq_EventSummaryMessage .eV16Sq_container.eV16Sq_failed .eV16Sq_heading {
  color: var(--token-arise-theme-1-state-state-error-light);
}

.eV16Sq_EventSummaryMessage .eV16Sq_container.eV16Sq_success {
  background-color: var(--token-arise-theme-1-state-state-success-dark);
}

.eV16Sq_EventSummaryMessage .eV16Sq_container.eV16Sq_success .eV16Sq_heading {
  color: var(--token-arise-theme-1-state-state-success-light);
}

.eV16Sq_EventSummaryMessage .eV16Sq_heading {
  font-family: var(--font-primary);
  min-width: 80%;
  max-width: calc(100% - 160px);
  font-size: 14px;
  display: inline-block;
}

.eV16Sq_EventSummaryMessage .eV16Sq_text {
  color: var(--token-accent-theme-1-text-text-default);
  min-width: 80%;
  max-width: calc(100% - 180px);
}

.eV16Sq_EventSummaryMessage .eV16Sq_text p {
  display: block;
}

.eV16Sq_EventSummaryMessage .eV16Sq_bold {
  font-weight: bold;
}

.eV16Sq_EventSummaryMessage .eV16Sq_successHighlight {
  color: var(--token-arise-theme-1-state-state-success-light);
}

.eV16Sq_EventSummaryMessage .eV16Sq_failedHighlight {
  color: var(--token-arise-theme-1-state-state-error-light);
}

.eV16Sq_EventSummaryMessage .eV16Sq_image {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-background);
  background-position: 100%;
  background-size: cover;
  border-radius: 0 10px 10px 0;
  width: 200px;
  max-width: 38%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  -webkit-mask-image: url("/assets/global/images/slanted-image-mask.webp");
  mask-image: url("/assets/global/images/slanted-image-mask.webp");
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}

.eV16Sq_EventSummaryMessage .eV16Sq_existingReactions {
  margin-top: 12px;
}

.eV16Sq_EventSummaryMessage .eV16Sq_reactionsMenu {
  opacity: 0;
}

.eV16Sq_EventSummaryMessage:hover .eV16Sq_reactionsMenu, .eV16Sq_EventSummaryMessage:focus .eV16Sq_reactionsMenu, .eV16Sq_EventSummaryMessage:focus-within .eV16Sq_reactionsMenu {
  opacity: 1;
}

.eV16Sq_EventSummaryMessage .eV16Sq_content {
  align-items: center;
  width: 90%;
  height: 80px;
  display: flex;
}

.eV16Sq_EventSummaryMessage .eV16Sq_content .eV16Sq_contentCenter {
  width: 100%;
}

.SlhrjW_fontSizeH1 {
  font-size: 55px;
}

.SlhrjW_fontSizeH2 {
  font-size: 44px;
}

.SlhrjW_fontSizeH3 {
  font-size: 35px;
}

.SlhrjW_fontSizeH4 {
  font-size: 28px;
}

.SlhrjW_fontSizeH5 {
  font-size: 22px;
}

.SlhrjW_fontSizeH6 {
  font-size: 18px;
}

.SlhrjW_fontSizep {
  font-size: 14px;
}

.SlhrjW_fontSizeSmall {
  font-size: 12px;
}

.SlhrjW_fontSizeTiny {
  font-size: 8px;
}

.SlhrjW_Update {
  background-color: var(--token-arise-theme-1-state-state-default-dark);
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  min-height: 60px;
  padding: 10px 10px 10px 66px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  position: relative;
}

.SlhrjW_Update .SlhrjW_displayName {
  color: var(--token-accent-theme-1-text-text-default);
}

.SlhrjW_Update .SlhrjW_action {
  color: var(--token-arise-theme-1-state-state-default-light);
}

.SlhrjW_Update .SlhrjW_icon {
  background-color: var(--token-arise-theme-1-state-state-default-mid);
  border-radius: 40px;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 13px;
  transform: translateY(-50%);
}

.SlhrjW_Update .SlhrjW_icon svg {
  object-fit: contain;
  width: 26px;
  height: 26px;
  position: absolute;
  top: 7px;
  left: 7px;
}

.SlhrjW_Update.SlhrjW_danger {
  background-color: var(--token-arise-theme-1-state-state-error-dark);
}

.SlhrjW_Update.SlhrjW_danger .SlhrjW_displayName {
  color: var(--token-accent-theme-1-text-text-default);
}

.SlhrjW_Update.SlhrjW_danger .SlhrjW_action {
  color: var(--token-arise-theme-1-state-state-error-light);
}

.SlhrjW_Update.SlhrjW_danger .SlhrjW_icon {
  background-color: var(--token-arise-theme-1-state-state-error-mid);
}

.SlhrjW_Update.SlhrjW_success {
  background-color: var(--token-arise-theme-1-state-state-success-dark);
}

.SlhrjW_Update.SlhrjW_success .SlhrjW_displayName {
  color: var(--token-accent-theme-1-text-text-default);
}

.SlhrjW_Update.SlhrjW_success .SlhrjW_action {
  color: var(--token-arise-theme-1-state-state-success-light);
}

.SlhrjW_Update.SlhrjW_success .SlhrjW_icon {
  background-color: var(--token-arise-theme-1-state-state-success-mid);
}

.SlhrjW_Update .SlhrjW_reactionsMenu {
  opacity: 0;
}

.SlhrjW_Update:hover .SlhrjW_reactionsMenu, .SlhrjW_Update:focus .SlhrjW_reactionsMenu, .SlhrjW_Update:focus-within .SlhrjW_reactionsMenu {
  opacity: 1;
}

.P3sPeG_fontSizeH1 {
  font-size: 55px;
}

.P3sPeG_fontSizeH2 {
  font-size: 44px;
}

.P3sPeG_fontSizeH3 {
  font-size: 35px;
}

.P3sPeG_fontSizeH4 {
  font-size: 28px;
}

.P3sPeG_fontSizeH5 {
  font-size: 22px;
}

.P3sPeG_fontSizeH6 {
  font-size: 18px;
}

.P3sPeG_fontSizep {
  font-size: 14px;
}

.P3sPeG_fontSizeSmall {
  font-size: 12px;
}

.P3sPeG_fontSizeTiny {
  font-size: 8px;
}

.P3sPeG_Feed {
  font-family: var(--font-tertiary);
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
}

.P3sPeG_messages {
  flex: 1;
  position: relative;
  overflow: hidden;
}

.P3sPeG_messages::-webkit-scrollbar {
  width: 15px;
}

.P3sPeG_messages::-webkit-scrollbar-thumb {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-foreground);
  background-clip: content-box;
  border: 3px solid #0000;
  border-radius: 8px;
}

.P3sPeG_messages::-webkit-scrollbar-track {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-background);
  background-clip: content-box;
  border-right: 1px solid #0000;
  border-radius: 10px;
}

.P3sPeG_input {
  z-index: 100;
  min-height: 52px;
  margin: 0 38px 38px;
  position: relative;
}

@media (width <= 768px) {
  .P3sPeG_input {
    margin: 0 25px 25px;
  }
}

@media (width <= 576px) {
  .P3sPeG_input {
    margin: 0 15px 15px;
  }
}

.P3sPeG_guestFade {
  pointer-events: none;
  background: linear-gradient(0deg, #000 20%, #0000 100%);
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: 0;
}

.P3sPeG_guestFade div {
  backdrop-filter: none;
  pointer-events: auto;
}

.P3sPeG_guestFade button {
  width: calc(100% - 80px);
  margin: 0 40px;
}

.P3sPeG_scrollToBottom {
  z-index: 100;
  visibility: hidden;
  transition: bottom .3s ease-in-out;
  position: absolute;
  bottom: -50px;
  right: 38px;
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-white) !important;
  width: 40px !important;
  height: 40px !important;
}

.P3sPeG_scrollToBottom svg path {
  fill: var(--token-LEGACY-accent-theme-1-interface-interface-black) !important;
}

@media (width <= 768px) {
  .P3sPeG_scrollToBottom {
    right: 25px;
  }
}

@media (width <= 576px) {
  .P3sPeG_scrollToBottom {
    right: 15px;
  }
}

.P3sPeG_scrollToBottom:hover {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-grey) !important;
}

.P3sPeG_scrollToBottom.P3sPeG_show {
  visibility: visible;
  transition-delay: .5s;
  bottom: 26px;
}

.P3sPeG_FeedContentNew {
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: hidden scroll;
}

.P3sPeG_FeedVirtual {
  width: 100%;
  height: 100%;
  overflow: hidden scroll;
}

.P3sPeG_FeedVirtual::-webkit-scrollbar {
  width: 15px;
}

.P3sPeG_FeedVirtual::-webkit-scrollbar-thumb {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-foreground);
  background-clip: content-box;
  border: 3px solid #0000;
  border-radius: 8px;
}

.P3sPeG_FeedVirtual::-webkit-scrollbar-track {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-background);
  background-clip: content-box;
  border-right: 1px solid #0000;
  border-radius: 10px;
}

.P3sPeG_slider {
  width: 100%;
  position: relative;
}

.P3sPeG_itemHolder {
  width: 100%;
  position: absolute;
}

.P3sPeG_loadingSpinner {
  color: #000;
  background-color: #fff;
  border-radius: 100%;
  padding: 10px;
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.P3sPeG_firstItem {
  width: 100%;
  position: absolute;
  top: 0;
}

.P3sPeG_loadMoreButton {
  z-index: 100;
  color: #000;
  cursor: pointer;
  background-color: #fff;
  border-radius: 16px;
  padding: 0 10px;
  font-size: 14px;
  font-weight: bold;
  line-height: 32px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.P3sPeG_vw-list::-webkit-scrollbar {
  width: 15px;
}

.P3sPeG_vw-list::-webkit-scrollbar-thumb {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-foreground);
  background-clip: content-box;
  border: 3px solid #0000;
  border-radius: 8px;
}

.P3sPeG_vw-list::-webkit-scrollbar-track {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-background);
  background-clip: content-box;
  border-right: 1px solid #0000;
  border-radius: 10px;
}

.P3sPeG_feedItem {
  padding: 0 38px;
}

@media (width <= 768px) {
  .P3sPeG_feedItem {
    padding: 0 25px;
  }
}

@media (width <= 576px) {
  .P3sPeG_feedItem {
    padding: 0 15px;
  }
}

.de9p6q_fontSizeH1 {
  font-size: 55px;
}

.de9p6q_fontSizeH2 {
  font-size: 44px;
}

.de9p6q_fontSizeH3 {
  font-size: 35px;
}

.de9p6q_fontSizeH4 {
  font-size: 28px;
}

.de9p6q_fontSizeH5 {
  font-size: 22px;
}

.de9p6q_fontSizeH6 {
  font-size: 18px;
}

.de9p6q_fontSizep {
  font-size: 14px;
}

.de9p6q_fontSizeSmall {
  font-size: 12px;
}

.de9p6q_fontSizeTiny {
  font-size: 8px;
}

.de9p6q_Countdown {
  position: relative;
}

.de9p6q_Countdown__description {
  color: var(--token-accent-theme-1-text-text-default);
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  font-size: 18px;
  display: block;
}

.de9p6q_Countdown__timer {
  display: block;
}

.de9p6q_Countdown__timer span {
  text-align: center;
  display: inline-block;
}

.-\34 UvlG_fontSizeH1 {
  font-size: 55px;
}

.-\34 UvlG_fontSizeH2 {
  font-size: 44px;
}

.-\34 UvlG_fontSizeH3 {
  font-size: 35px;
}

.-\34 UvlG_fontSizeH4 {
  font-size: 28px;
}

.-\34 UvlG_fontSizeH5 {
  font-size: 22px;
}

.-\34 UvlG_fontSizeH6 {
  font-size: 18px;
}

.-\34 UvlG_fontSizep {
  font-size: 14px;
}

.-\34 UvlG_fontSizeSmall {
  font-size: 12px;
}

.-\34 UvlG_fontSizeTiny {
  font-size: 8px;
}

.-\34 UvlG_EventTimer {
  background-color: var(--token-accent-theme-1-interface-mono-interface-white);
  font-family: var(--font-quaternary);
  overflow: hidden;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}

.-\34 UvlG_EventTimer .-\34 UvlG_progressBar {
  background-color: var(--token-accent-theme-1-brand-palette-brand-accent-2);
  width: 100%;
  height: 100%;
  transition-property: left;
  transition-timing-function: linear;
  position: absolute;
  top: 0;
}

.-\34 UvlG_EventTimer .-\34 UvlG_statusText {
  text-transform: uppercase;
}

.-\34 UvlG_EventTimer .-\34 UvlG_statusText, .-\34 UvlG_EventTimer .-\34 UvlG_countdown {
  color: var(--token-accent-theme-1-interface-mono-interface-black);
  font-size: 44px;
  font-weight: 600;
  position: absolute;
  top: 50%;
  left: 25px;
  transform: translateY(-50%);
}

@media (width <= 576px) {
  .-\34 UvlG_EventTimer .-\34 UvlG_statusText, .-\34 UvlG_EventTimer .-\34 UvlG_countdown {
    font-size: 28px;
  }
}

.-\34 UvlG_EventTimer .-\34 UvlG_graphic {
  background-position-x: calc(100% + 18px);
  background-repeat: no-repeat;
  background-size: auto 100%;
  width: 100%;
  height: 101%;
  transition-property: left;
  transition-timing-function: linear;
  animation-name: -\34 UvlG_move, -\34 UvlG_shake;
  animation-duration: 10s, .2s;
  animation-timing-function: linear;
  animation-iteration-count: 1, infinite;
  animation-delay: 2s, 2s;
  animation-fill-mode: both, none;
  position: absolute;
  top: 0;
}

@keyframes -\34 UvlG_move {
  0% {
    left: 100%;
  }

  10% {
    left: 25%;
  }

  100% {
    left: 0%;
  }
}

@keyframes -\34 UvlG_shake {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-1px);
  }

  to {
    transform: translateY(0);
  }
}

.-\34 UvlG_EventTimer.-\34 UvlG_passed {
  background-color: var(--token-arise-theme-1-state-state-success-mid);
}

.-\34 UvlG_EventTimer.-\34 UvlG_passed .-\34 UvlG_statusText {
  color: var(--token-arise-theme-1-state-state-success-light);
}

.-\34 UvlG_EventTimer.-\34 UvlG_failed {
  background-color: var(--token-arise-theme-1-state-state-error-mid);
}

.-\34 UvlG_EventTimer.-\34 UvlG_failed .-\34 UvlG_statusText {
  color: var(--token-accent-theme-1-interface-mono-interface-black);
}

.KB8y1G_fontSizeH1 {
  font-size: 55px;
}

.KB8y1G_fontSizeH2 {
  font-size: 44px;
}

.KB8y1G_fontSizeH3 {
  font-size: 35px;
}

.KB8y1G_fontSizeH4 {
  font-size: 28px;
}

.KB8y1G_fontSizeH5 {
  font-size: 22px;
}

.KB8y1G_fontSizeH6 {
  font-size: 18px;
}

.KB8y1G_fontSizep {
  font-size: 14px;
}

.KB8y1G_fontSizeSmall {
  font-size: 12px;
}

.KB8y1G_fontSizeTiny {
  font-size: 8px;
}

.KB8y1G_SidebarEventContainer {
  pointer-events: none;
  width: 100%;
  height: 290px;
  transition: transform .2s ease-in-out;
  position: absolute;
  top: 0;
  transform: translateY(0%);
}

.KB8y1G_SidebarEventContainer.KB8y1G_isHidden {
  transform: translateY(-100%);
}

.KB8y1G_SidebarEventContainer .KB8y1G_dismissButton {
  z-index: 1;
  pointer-events: auto;
  position: absolute;
  top: 4px;
  right: 4px;
}

.KB8y1G_SidebarEventContainer .KB8y1G_dismissButton svg path {
  fill: var(--token-accent-theme-1-text-text-default);
}

.KB8y1G_SidebarEventContainer .KB8y1G_SidebarEvent {
  font-family: var(--font-quaternary);
  background-image: url("/assets/global/images/events/sidebar-overlay.webp");
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  font-weight: 500;
  position: absolute;
  top: 0;
}

.KB8y1G_SidebarEventContainer .KB8y1G_SidebarEvent .KB8y1G_timer {
  width: calc(100% - 30px);
  height: 130px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.KB8y1G_SidebarEventContainer .KB8y1G_SidebarEvent .KB8y1G_incorrectShake {
  animation-name: KB8y1G_incorrect-shake;
  animation-duration: .5s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
}

@keyframes KB8y1G_incorrect-shake {
  8%, 41% {
    transform: translateX(-10px);
  }

  25%, 58% {
    transform: translateX(10px);
  }

  75% {
    transform: translateX(-5px);
  }

  92% {
    transform: translateX(5px);
  }

  0%, 100% {
    transform: translateX(0);
  }
}

.KB8y1G_SidebarEventContainer .KB8y1G_SidebarEvent .KB8y1G_text {
  color: var(--token-accent-theme-1-text-text-default);
  text-align: center;
  text-transform: uppercase;
  pointer-events: auto;
  width: calc(100% - 140px);
  margin: 33px 72px 0;
}

.KB8y1G_SidebarEventContainer .KB8y1G_SidebarEvent .KB8y1G_text .KB8y1G_winString {
  text-transform: none;
}

.KB8y1G_SidebarEventContainer .KB8y1G_SidebarEvent .KB8y1G_text .KB8y1G_intro {
  font-size: 18px;
  display: block;
}

.KB8y1G_SidebarEventContainer .KB8y1G_SidebarEvent .KB8y1G_text .KB8y1G_instruction {
  margin-top: -5px;
  margin-bottom: 5px;
  font-size: 28px;
  font-weight: 600;
  display: block;
}

.KB8y1G_SidebarEventContainer .KB8y1G_SidebarEvent .KB8y1G_text .KB8y1G_lowlight {
  opacity: .7;
}

@media (width <= 768px), (height <= 800px) {
  .KB8y1G_SidebarEventContainer .KB8y1G_SidebarEvent {
    height: 190px;
  }

  .KB8y1G_SidebarEventContainer .KB8y1G_SidebarEvent .KB8y1G_text {
    margin-top: 20px;
  }

  .KB8y1G_SidebarEventContainer .KB8y1G_SidebarEvent .KB8y1G_text .KB8y1G_intro {
    font-size: 14px;
  }

  .KB8y1G_SidebarEventContainer .KB8y1G_SidebarEvent .KB8y1G_text .KB8y1G_instruction {
    font-size: 18px;
  }

  .KB8y1G_SidebarEventContainer .KB8y1G_SidebarEvent .KB8y1G_timer {
    height: 100px;
  }
}

.PkhzSW_fontSizeH1 {
  font-size: 55px;
}

.PkhzSW_fontSizeH2 {
  font-size: 44px;
}

.PkhzSW_fontSizeH3 {
  font-size: 35px;
}

.PkhzSW_fontSizeH4 {
  font-size: 28px;
}

.PkhzSW_fontSizeH5 {
  font-size: 22px;
}

.PkhzSW_fontSizeH6 {
  font-size: 18px;
}

.PkhzSW_fontSizep {
  font-size: 14px;
}

.PkhzSW_fontSizeSmall {
  font-size: 12px;
}

.PkhzSW_fontSizeTiny {
  font-size: 8px;
}

.PkhzSW_FailedOverlay {
  z-index: 20;
  pointer-events: none;
  width: 100%;
  height: 0;
  font-size: 35px;
  transition: translate 1s ease-in-out, height 0s linear .3s;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  translate: 0 -100%;
}

.PkhzSW_FailedOverlay.PkhzSW_visible {
  height: 640px;
  transition-delay: 0s, 0s;
  translate: 0;
}

@media (height <= 800px) {
  .PkhzSW_FailedOverlay.PkhzSW_visible {
    height: 550px;
  }
}

@media screen and (width <= 390px) {
  .PkhzSW_FailedOverlay {
    font-size: 28px;
  }
}

.PkhzSW_background {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-black);
  opacity: .7;
  width: 100%;
  height: 100%;
  position: absolute;
  -webkit-mask-position: 90%;
  mask-position: 90%;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.PkhzSW_text {
  text-align: center;
  font-family: var(--font-primary);
  width: 100%;
  padding-top: 100px;
  position: absolute;
  top: 0;
  left: 0;
}

.tVg0Pq_fontSizeH1 {
  font-size: 55px;
}

.tVg0Pq_fontSizeH2 {
  font-size: 44px;
}

.tVg0Pq_fontSizeH3 {
  font-size: 35px;
}

.tVg0Pq_fontSizeH4 {
  font-size: 28px;
}

.tVg0Pq_fontSizeH5 {
  font-size: 22px;
}

.tVg0Pq_fontSizeH6 {
  font-size: 18px;
}

.tVg0Pq_fontSizep {
  font-size: 14px;
}

.tVg0Pq_fontSizeSmall {
  font-size: 12px;
}

.tVg0Pq_fontSizeTiny {
  font-size: 8px;
}

.tVg0Pq_ThemedMenuBar {
  filter: drop-shadow(0 0 40px #0009);
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  height: 50px;
  display: flex;
  position: relative;
}

.tVg0Pq_ThemedMenuBar.tVg0Pq_noBackgroundImage {
  background-color: var(--token-accent-theme-1-interface-mono-interface-background);
  border-radius: 10px;
}

.tVg0Pq_ThemedMenuBar .tVg0Pq_leftCap, .tVg0Pq_ThemedMenuBar .tVg0Pq_center, .tVg0Pq_ThemedMenuBar .tVg0Pq_rightCap {
  height: 100%;
}

.tVg0Pq_ThemedMenuBar .tVg0Pq_rightCap {
  transform: scaleX(-1);
}

.tVg0Pq_ThemedMenuBar .tVg0Pq_center {
  width: 100%;
}

.OO8D2a_fontSizeH1 {
  font-size: 55px;
}

.OO8D2a_fontSizeH2 {
  font-size: 44px;
}

.OO8D2a_fontSizeH3 {
  font-size: 35px;
}

.OO8D2a_fontSizeH4 {
  font-size: 28px;
}

.OO8D2a_fontSizeH5 {
  font-size: 22px;
}

.OO8D2a_fontSizeH6 {
  font-size: 18px;
}

.OO8D2a_fontSizep {
  font-size: 14px;
}

.OO8D2a_fontSizeSmall {
  font-size: 12px;
}

.OO8D2a_fontSizeTiny {
  font-size: 8px;
}

.OO8D2a_Rank {
  opacity: 1;
  transition: opacity .2s ease-in-out;
  position: relative;
}

.OO8D2a_Rank.OO8D2a_isLoading {
  opacity: 0;
}

.OO8D2a_Rank .OO8D2a_label {
  font-family: var(--font-primary);
  text-align: center;
  text-shadow: 2px 2px 5px #000;
  width: 100%;
  font-size: 18px;
  position: absolute;
  top: 0;
  left: 0;
}

.OO8D2a_Rank .OO8D2a_fallbackImage {
  min-width: 100px;
  max-height: 130px;
  display: block;
  position: absolute;
  top: 30px;
  left: 30px;
}

.OO8D2a_Rank .OO8D2a_RankBadge {
  aspect-ratio: 1;
  width: 100%;
  min-width: 100px;
  position: absolute;
  top: 30px;
  left: 0;
}

.OO8D2a_Rank .OO8D2a_RankBadge .OO8D2a_image, .OO8D2a_Rank .OO8D2a_RankBadge .OO8D2a_overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.OO8D2a_Rank .OO8D2a_RankBadge .OO8D2a_overlay {
  mix-blend-mode: multiply;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}

.PPJVKG_fontSizeH1 {
  font-size: 55px;
}

.PPJVKG_fontSizeH2 {
  font-size: 44px;
}

.PPJVKG_fontSizeH3 {
  font-size: 35px;
}

.PPJVKG_fontSizeH4 {
  font-size: 28px;
}

.PPJVKG_fontSizeH5 {
  font-size: 22px;
}

.PPJVKG_fontSizeH6 {
  font-size: 18px;
}

.PPJVKG_fontSizep {
  font-size: 14px;
}

.PPJVKG_fontSizeSmall {
  font-size: 12px;
}

.PPJVKG_fontSizeTiny {
  font-size: 8px;
}

.PPJVKG_CardDescription {
  box-sizing: border-box;
  text-align: left;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: var(--token-arise-theme-1-rarity-rarity-common-dark);
  flex-direction: column;
  justify-content: center;
  height: 44px;
  padding: 5px 15px;
  transition: transform .2s cubic-bezier(.5, .33, .33, 1);
  display: flex;
  position: relative;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}

.PPJVKG_CardDescription .PPJVKG_subtitle {
  color: var(--token-arise-theme-1-rarity-rarity-common-light);
}

.PPJVKG_CardDescription.PPJVKG_common {
  background-color: var(--token-arise-theme-1-rarity-rarity-common-dark);
}

.PPJVKG_CardDescription.PPJVKG_common .PPJVKG_subtitle {
  color: var(--token-arise-theme-1-rarity-rarity-common-light);
}

.PPJVKG_CardDescription.PPJVKG_uncommon {
  background-color: var(--token-arise-theme-1-rarity-rarity-uncommon-dark);
}

.PPJVKG_CardDescription.PPJVKG_uncommon .PPJVKG_subtitle {
  color: var(--token-arise-theme-1-rarity-rarity-uncommon-light);
}

.PPJVKG_CardDescription.PPJVKG_rare {
  background-color: var(--token-arise-theme-1-rarity-rarity-rare-dark);
}

.PPJVKG_CardDescription.PPJVKG_rare .PPJVKG_subtitle {
  color: var(--token-arise-theme-1-rarity-rarity-rare-light);
}

.PPJVKG_CardDescription.PPJVKG_epic {
  background-color: var(--token-arise-theme-1-rarity-rarity-epic-dark);
}

.PPJVKG_CardDescription.PPJVKG_epic .PPJVKG_subtitle {
  color: var(--token-arise-theme-1-rarity-rarity-epic-light);
}

.PPJVKG_CardDescription.PPJVKG_legendary {
  background-color: var(--token-arise-theme-1-rarity-rarity-legendary-dark);
}

.PPJVKG_CardDescription.PPJVKG_legendary .PPJVKG_subtitle {
  color: var(--token-arise-theme-1-rarity-rarity-legendary-light);
}

.PPJVKG_CardDescription.PPJVKG_isButton:hover, .PPJVKG_CardDescription.PPJVKG_isButton:focus {
  transform: scale(1.1);
}

.PPJVKG_CardDescription .PPJVKG_title {
  font-size: 14px;
  font-family: var(--font-tertiary);
  font-weight: 500;
}

.PPJVKG_CardDescription .PPJVKG_title.PPJVKG_longTitle {
  font-size: 8px;
}

@media (width <= 576px) {
  .PPJVKG_CardDescription .PPJVKG_title {
    font-size: 12px;
  }

  .PPJVKG_CardDescription .PPJVKG_title.PPJVKG_longTitle {
    font-size: 8px;
  }
}

.PPJVKG_CardDescription .PPJVKG_subtitle {
  font-size: 8px;
  font-weight: 300;
}

.PPJVKG_CardDescription .PPJVKG_icon {
  fill: var(--token-accent-theme-1-text-text-default);
  opacity: .7;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 4px;
  right: 6px;
}

.MaULVa_fontSizeH1 {
  font-size: 55px;
}

.MaULVa_fontSizeH2 {
  font-size: 44px;
}

.MaULVa_fontSizeH3 {
  font-size: 35px;
}

.MaULVa_fontSizeH4 {
  font-size: 28px;
}

.MaULVa_fontSizeH5 {
  font-size: 22px;
}

.MaULVa_fontSizeH6 {
  font-size: 18px;
}

.MaULVa_fontSizep {
  font-size: 14px;
}

.MaULVa_fontSizeSmall {
  font-size: 12px;
}

.MaULVa_fontSizeTiny {
  font-size: 8px;
}

.MaULVa_ItemContainer {
  position: relative;
}

.MaULVa_ItemContainer:last-child {
  margin-right: 0;
}

.MaULVa_ItemContainer__BackgroundContainer {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  transition: filter .4s cubic-bezier(.5, .33, .33, 1);
  position: absolute;
  inset: 0;
}

.MaULVa_ItemContainer__BackgroundContainer.MaULVa_noBackgroundImage {
  background-color: var(--token-accent-theme-1-interface-brand-interface-background);
  border-radius: 10px;
}

.MaULVa_ItemContainer__BackgroundIcon {
  fill: var(--token-accent-theme-1-interface-brand-interface-midground);
  width: 60px;
  height: 60px;
  transition: transform .4s cubic-bezier(.5, .33, .33, 1);
  position: absolute;
  top: calc(50% - 15px);
  left: 50%;
  transform: translate(-50%, -50%);
}

.MaULVa_ItemContainer__Button {
  aspect-ratio: 203 / 237;
  background-size: 100% 100%;
  width: 100%;
  position: relative;
}

.MaULVa_ItemContainer__Button--interactable:hover .MaULVa_ItemContainer__Border {
  border-color: var(--token-accent-theme-1-border-border-highlight);
}

.MaULVa_ItemContainer__Button--interactable:focus .MaULVa_ItemContainer__Image, .MaULVa_ItemContainer__Button--interactable:hover .MaULVa_ItemContainer__Image {
  transform: scale(1.2);
}

.MaULVa_ItemContainer__Button--interactable:focus .MaULVa_ItemContainer__BackgroundIcon, .MaULVa_ItemContainer__Button--interactable:hover .MaULVa_ItemContainer__BackgroundIcon {
  transform: translate(-50%, -50%)scale(1.2);
}

.MaULVa_ItemContainer__Button--interactable:focus .MaULVa_ItemContainer__BackgroundContainer, .MaULVa_ItemContainer__Button--interactable:hover .MaULVa_ItemContainer__BackgroundContainer {
  filter: brightness(1.2);
}

.MaULVa_ItemContainer__Border {
  border: 1.5px solid var(--token-accent-theme-1-border-border-default);
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  margin: 10px;
  transition: border-color .5s cubic-bezier(.5, .33, .33, 1);
  position: absolute;
  inset: 0;
}

.MaULVa_ItemContainer__Image {
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  transition: transform .4s cubic-bezier(.5, .33, .33, 1);
  position: relative;
}

.MaULVa_ItemContainer__HighlightImage {
  z-index: -1;
  opacity: 0;
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-size: 100% 0%;
  width: 224px;
  height: 491px;
  transition: none;
  position: absolute;
  top: -135px;
  left: -15px;
  rotate: 30deg;
}

.MaULVa_ItemContainer__Description {
  margin: 0 8px;
  position: absolute;
  bottom: -8px;
  left: 0;
  right: 0;
}

@keyframes MaULVa_slide-in {
  from {
    opacity: 0;
    bottom: -200px;
  }

  to {
    opacity: 1;
    bottom: 0;
  }
}

.JDZAmq_fontSizeH1 {
  font-size: 55px;
}

.JDZAmq_fontSizeH2 {
  font-size: 44px;
}

.JDZAmq_fontSizeH3 {
  font-size: 35px;
}

.JDZAmq_fontSizeH4 {
  font-size: 28px;
}

.JDZAmq_fontSizeH5 {
  font-size: 22px;
}

.JDZAmq_fontSizeH6 {
  font-size: 18px;
}

.JDZAmq_fontSizep {
  font-size: 14px;
}

.JDZAmq_fontSizeSmall {
  font-size: 12px;
}

.JDZAmq_fontSizeTiny {
  font-size: 8px;
}

.JDZAmq_MyPrizesDrawer {
  color: var(--token-accent-theme-1-text-text-default);
  -webkit-backdrop-filter: blur(10px);
  opacity: 0;
  z-index: 10;
  background-color: #000000b3;
  width: 100%;
  height: 100%;
  transition: right .5s cubic-bezier(.5, .33, .33, 1), opacity 0s linear .5s;
  position: absolute;
  top: 0;
  right: -520px;
}

@media (width <= 992px) {
  .JDZAmq_MyPrizesDrawer {
    width: 100%;
    right: -100%;
  }
}

.JDZAmq_MyPrizesDrawer.JDZAmq_isOpen {
  opacity: 1;
  transition: right .5s cubic-bezier(.5, .33, .33, 1), opacity linear;
  right: 0;
}

.JDZAmq_MyPrizesDrawer .JDZAmq_scrollContainer {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: scroll;
}

.JDZAmq_MyPrizesDrawer .JDZAmq_scrollContainer::-webkit-scrollbar {
  width: 15px;
}

.JDZAmq_MyPrizesDrawer .JDZAmq_scrollContainer::-webkit-scrollbar-thumb {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-foreground);
  background-clip: content-box;
  border: 3px solid #0000;
  border-radius: 8px;
}

.JDZAmq_MyPrizesDrawer .JDZAmq_scrollContainer::-webkit-scrollbar-track {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-background);
  background-clip: content-box;
  border-right: 1px solid #0000;
  border-radius: 10px;
}

.JDZAmq_MyPrizesDrawer h2 {
  font-family: var(--font-primary);
  text-align: center;
  font-size: 18px;
}

.JDZAmq_MyPrizesDrawer .JDZAmq_topBar {
  width: 100%;
  height: 80px;
  position: absolute;
}

.JDZAmq_MyPrizesDrawer .JDZAmq_topBar .JDZAmq_title {
  text-align: center;
  position: absolute;
  top: 25px;
  left: 50%;
  transform: translateX(-50%);
}

.JDZAmq_MyPrizesDrawer .JDZAmq_topBar .JDZAmq_closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
}

.JDZAmq_MyPrizesDrawer .JDZAmq_content {
  border-style: solid;
  background-position: center;
  background-size: 200% 200%;
  border-image-slice: 60;
  border-image-width: 30px;
  border-image-outset: 5px;
  border-image-repeat: stretch;
  width: 100%;
  max-width: 430px;
  min-height: 500px;
  padding: 20px;
  position: absolute;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
}

.JDZAmq_MyPrizesDrawer .JDZAmq_content.JDZAmq_noBackgroundImage {
  background-color: var(--token-accent-theme-1-interface-mono-interface-midground);
  border-radius: 10px;
}

@media (width <= 992px) {
  .JDZAmq_MyPrizesDrawer .JDZAmq_content {
    max-width: 500px;
  }
}

.JDZAmq_MyPrizesDrawer .JDZAmq_content .JDZAmq_title {
  margin: 10px;
}

.JDZAmq_MyPrizesDrawer .JDZAmq_content .JDZAmq_emptyText {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
}

.JDZAmq_MyPrizesDrawer .JDZAmq_content ul {
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  display: flex;
}

.JDZAmq_MyPrizesDrawer .JDZAmq_content li {
  width: calc(50% - 10px);
  height: 100%;
  display: block;
}

.JDZAmq_MyPrizesDrawer .JDZAmq_content img {
  width: 100%;
  max-width: calc(100% - 35px);
  height: auto;
  max-height: calc(100% - 35px);
}

.qJLAhW_fontSizeH1 {
  font-size: 55px;
}

.qJLAhW_fontSizeH2 {
  font-size: 44px;
}

.qJLAhW_fontSizeH3 {
  font-size: 35px;
}

.qJLAhW_fontSizeH4 {
  font-size: 28px;
}

.qJLAhW_fontSizeH5 {
  font-size: 22px;
}

.qJLAhW_fontSizeH6 {
  font-size: 18px;
}

.qJLAhW_fontSizep {
  font-size: 14px;
}

.qJLAhW_fontSizeSmall {
  font-size: 12px;
}

.qJLAhW_fontSizeTiny {
  font-size: 8px;
}

.qJLAhW_ProfileStatsSummary {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  min-height: 176px;
  padding: 20px;
  display: flex;
}

.qJLAhW_ProfileStatsSummary.qJLAhW_noBackgroundImage {
  background-color: var(--token-accent-theme-1-interface-mono-interface-background);
  border-radius: 10px;
}

@media (width <= 440px) {
  .qJLAhW_ProfileStatsSummary {
    flex-direction: column;
    padding: 10px;
  }

  .qJLAhW_ProfileStatsSummary .qJLAhW_logo {
    display: none;
  }

  .qJLAhW_ProfileStatsSummary .qJLAhW_summaryRow {
    gap: 35px;
  }
}

.qJLAhW_ProfileStatsSummary .qJLAhW_logo {
  background-color: var(--token-accent-theme-1-brand-palette-brand-accent-1);
  width: 100%;
  min-width: 60px;
  max-width: 50%;
  height: 136px;
  margin: 20px 20px 20px 0;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.qJLAhW_ProfileStatsSummary .qJLAhW_summaryItems {
  flex-flow: column wrap;
  flex: none;
  justify-content: space-evenly;
  align-items: center;
  gap: 10px;
  display: flex;
}

.qJLAhW_ProfileStatsSummary .qJLAhW_summaryItems .qJLAhW_summaryRow {
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
  display: flex;
}

.qJLAhW_ProfileStatsSummary .qJLAhW_summaryItems li {
  flex: none;
  display: inline-block;
}

.qJLAhW_ProfileStatsSummary .qJLAhW_extraStats {
  animation: .7s cubic-bezier(.5, .33, .33, 1) qJLAhW_fade-in;
}

@keyframes qJLAhW_fade-in {
  from {
    opacity: 0;
    max-height: 0;
  }

  to {
    opacity: 1;
    max-height: 200px;
  }
}

.qJLAhW_SummaryItem {
  color: var(--token-LEGACY-accent-theme-1-interface-interface-white);
  align-items: center;
  width: 100%;
  min-width: 100px;
  display: flex;
}

.qJLAhW_SummaryItem.qJLAhW_reduceFontSize .qJLAhW_value {
  font-size: 14px;
}

.qJLAhW_SummaryItem span {
  display: block;
}

.qJLAhW_SummaryItem .qJLAhW_title {
  color: var(--token-accent-theme-1-brand-palette-brand-accent-1);
  font-size: 8px;
}

.qJLAhW_SummaryItem .qJLAhW_value {
  text-align: left;
  font-size: 18px;
}

.qJLAhW_SummaryItem .qJLAhW_icon {
  fill: var(--token-LEGACY-accent-theme-1-interface-interface-white);
  width: 48px;
  height: 48px;
  margin-right: 10px;
}

.XeGVQW_fontSizeH1 {
  font-size: 55px;
}

.XeGVQW_fontSizeH2 {
  font-size: 44px;
}

.XeGVQW_fontSizeH3 {
  font-size: 35px;
}

.XeGVQW_fontSizeH4 {
  font-size: 28px;
}

.XeGVQW_fontSizeH5 {
  font-size: 22px;
}

.XeGVQW_fontSizeH6 {
  font-size: 18px;
}

.XeGVQW_fontSizep {
  font-size: 14px;
}

.XeGVQW_fontSizeSmall {
  font-size: 12px;
}

.XeGVQW_fontSizeTiny {
  font-size: 8px;
}

.XeGVQW_ProfileEditor {
  z-index: 10;
  pointer-events: none;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  top: -4px;
  left: 0;
}

.XeGVQW_ProfileEditor .XeGVQW_loadingScreen {
  height: 100%;
  top: 0;
}

.XeGVQW_ProfileEditor .XeGVQW_top {
  width: 100%;
  height: 500px;
  position: absolute;
  top: 0;
  left: 0;
}

.XeGVQW_ProfileEditor .XeGVQW_bottom {
  pointer-events: all;
  background-color: #000;
  width: 100%;
  min-height: calc(100% - 500px);
  margin: 0;
  padding: 0;
  position: absolute;
  top: 500px;
}

.XeGVQW_ProfileEditor .XeGVQW_editorContainer {
  border-style: solid;
  background-position: center;
  background-size: 100% 100%;
  border-image-slice: 60;
  border-image-width: 30px;
  border-image-outset: 5px;
  border-image-repeat: stretch;
  max-width: 430px;
  height: 100%;
  min-height: 500px;
  margin: -10px auto 0;
  padding: 24px 50px;
  transition: height .5s linear;
  position: relative;
}

.XeGVQW_ProfileEditor .XeGVQW_editorContainer.XeGVQW_noBackgroundImage {
  background-color: var(--token-accent-theme-1-interface-mono-interface-background);
  border-radius: 10px;
}

@media (width <= 992px) {
  .XeGVQW_ProfileEditor .XeGVQW_editorContainer {
    max-width: 500px;
  }
}

@media (width <= 390px) {
  .XeGVQW_ProfileEditor .XeGVQW_editorContainer {
    padding: 12px;
  }
}

.XeGVQW_ProfileEditor .XeGVQW_tabButtons {
  justify-content: space-evenly;
  width: 100%;
  display: flex;
}

.XeGVQW_ProfileEditor .XeGVQW_tabButtons li {
  width: 40px;
  height: 40px;
}

.XeGVQW_ProfileEditor .XeGVQW_tabButtons button, .XeGVQW_ProfileEditor .XeGVQW_tabButtons button svg {
  width: 100%;
  height: 100%;
}

.XeGVQW_ProfileEditor .XeGVQW_tabButtons button svg path {
  fill: var(--token-accent-theme-1-button-button-secondary-inactive);
}

.XeGVQW_ProfileEditor .XeGVQW_tabButtons button.XeGVQW_isActive svg path {
  fill: var(--token-accent-theme-1-button-button-secondary-active);
}

.XeGVQW_ProfileEditor .XeGVQW_tabButtons button:hover svg path, .XeGVQW_ProfileEditor .XeGVQW_tabButtons button:focus svg path {
  fill: var(--token-accent-theme-1-button-button-secondary-highlight);
}

.XeGVQW_ProfileEditor .XeGVQW_title {
  font-family: var(--font-primary);
  text-align: center;
  font-size: 18px;
  position: absolute;
  top: 41px;
  left: 50%;
  transform: translateX(-50%);
}

.XeGVQW_ProfileEditor .XeGVQW_tabTitle {
  text-align: center;
  font-size: 18px;
  font-family: var(--font-primary);
  margin: 20px 0;
}

.XeGVQW_ProfileEditor .XeGVQW_items {
  flex-wrap: wrap;
  gap: 10px;
  list-style: none;
  display: flex;
}

.XeGVQW_ProfileEditor .XeGVQW_items .XeGVQW_empty {
  text-align: center;
  width: 100%;
}

.XeGVQW_ProfileEditor .XeGVQW_items li {
  width: calc(50% - 5px);
}

.XeGVQW_ProfileEditor .XeGVQW_items .XeGVQW_isCurrent {
  box-shadow: 0 0 5px 1px #ffffff4d;
}

.XeGVQW_ProfileEditor .XeGVQW_items .XeGVQW_titleItem {
  width: 100%;
}

.XeGVQW_ProfileEditor .XeGVQW_items .XeGVQW_factionItem img {
  margin-bottom: 30px;
}

.XeGVQW_ProfileEditor .XeGVQW_items .XeGVQW_frameItem img {
  width: calc(100% - 40px);
  margin-bottom: 25px;
}

.XeGVQW_ProfileEditor .XeGVQW_items .XeGVQW_characterItem img {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  margin: 20px;
}

.XeGVQW_ProfileEditor .XeGVQW_items .XeGVQW_backgroundItem img {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  margin: 20px 20px 30px;
}

.GEwJ6q_fontSizeH1 {
  font-size: 55px;
}

.GEwJ6q_fontSizeH2 {
  font-size: 44px;
}

.GEwJ6q_fontSizeH3 {
  font-size: 35px;
}

.GEwJ6q_fontSizeH4 {
  font-size: 28px;
}

.GEwJ6q_fontSizeH5 {
  font-size: 22px;
}

.GEwJ6q_fontSizeH6 {
  font-size: 18px;
}

.GEwJ6q_fontSizep {
  font-size: 14px;
}

.GEwJ6q_fontSizeSmall {
  font-size: 12px;
}

.GEwJ6q_fontSizeTiny {
  font-size: 8px;
}

.GEwJ6q_ArisewareScores {
  font-family: var(--font-primary);
  border-bottom: 1px solid #fff3;
  height: 88px;
  font-size: 14px;
}

.GEwJ6q_ArisewareScores .GEwJ6q_success {
  color: var(--token-arise-theme-1-state-state-success-light);
}

.GEwJ6q_ArisewareScores .GEwJ6q_failure {
  color: var(--token-arise-theme-1-state-state-error-light);
}

.GEwJ6q_ArisewareScores ul {
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.GEwJ6q_ArisewareScores li {
  text-align: center;
  flex-direction: column;
  flex: 33.33%;
  display: flex;
}

.GEwJ6q_ArisewareScores li span {
  display: block;
}

.HEBnsa_fontSizeH1 {
  font-size: 55px;
}

.HEBnsa_fontSizeH2 {
  font-size: 44px;
}

.HEBnsa_fontSizeH3 {
  font-size: 35px;
}

.HEBnsa_fontSizeH4 {
  font-size: 28px;
}

.HEBnsa_fontSizeH5 {
  font-size: 22px;
}

.HEBnsa_fontSizeH6 {
  font-size: 18px;
}

.HEBnsa_fontSizep {
  font-size: 14px;
}

.HEBnsa_fontSizeSmall {
  font-size: 12px;
}

.HEBnsa_fontSizeTiny {
  font-size: 8px;
}

.HEBnsa_ProfileDrawer {
  z-index: 120;
  color: var(--token-accent-theme-1-text-text-default);
  backdrop-filter: blur(30px);
  opacity: 0;
  background-color: #000000e6;
  width: 100%;
  height: 100%;
  transition: right .5s cubic-bezier(.5, .33, .33, 1), opacity 0s linear .5s;
  position: absolute;
  top: 0;
  right: -520px;
  overflow: hidden;
}

@media (width <= 992px) {
  .HEBnsa_ProfileDrawer {
    width: 100%;
    right: -100%;
  }
}

.HEBnsa_ProfileDrawer.HEBnsa_isOpen {
  opacity: 1;
  transition: right .5s cubic-bezier(.5, .33, .33, 1), opacity linear;
  right: 0;
}

.HEBnsa_ProfileDrawer.HEBnsa_isOpen.HEBnsa_isLoaded .HEBnsa_factionImage {
  transform: scale(1);
}

.HEBnsa_ProfileDrawer.HEBnsa_isOpen.HEBnsa_isLoaded .HEBnsa_profileCharacter {
  transform: translateX(0%);
}

.HEBnsa_ProfileDrawer .HEBnsa_profileCharacterContainer {
  height: 500px;
  position: relative;
  overflow: hidden;
}

.HEBnsa_ProfileDrawer .HEBnsa_profileCharacterContainer:after {
  content: "";
  pointer-events: none;
  opacity: .9;
  background: linear-gradient(#000 0%, #0000 100%);
  width: 100%;
  height: 80px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.HEBnsa_ProfileDrawer .HEBnsa_profileCharacterContainer .HEBnsa_profileBackground {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
}

.HEBnsa_ProfileDrawer .HEBnsa_profileCharacterContainer .HEBnsa_profileCharacter {
  pointer-events: none;
  width: 100%;
  height: calc(100% - 70px);
  transition: transform 1.5s ease-out, left .5s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 90px;
  transform: translateX(50%);
}

@media (width <= 768px) {
  .HEBnsa_ProfileDrawer .HEBnsa_profileCharacterContainer .HEBnsa_profileCharacter {
    left: 30px;
  }
}

.HEBnsa_ProfileDrawer .HEBnsa_profileCharacterContainer .HEBnsa_factionImage {
  pointer-events: none;
  width: 140px;
  transition: transform .5s ease-out, opacity .2s ease-in-out;
  animation: none;
  position: absolute;
  bottom: 200px;
  left: 10px;
  transform: scale(.8);
}

.HEBnsa_ProfileDrawer .HEBnsa_editingAvatar {
  opacity: 0;
  width: 40px;
  height: 40px;
  transition: opacity .2s ease-in-out;
  top: 430px;
  left: 50%;
  transform: translateX(-50%);
}

.HEBnsa_ProfileDrawer .HEBnsa_isEditing .HEBnsa_profileCharacter {
  left: 0;
}

.HEBnsa_ProfileDrawer .HEBnsa_isEditing .HEBnsa_factionImage {
  opacity: 0;
}

.HEBnsa_ProfileDrawer .HEBnsa_isEditing .HEBnsa_editingAvatar {
  opacity: 1;
}

.HEBnsa_ProfileDrawer .HEBnsa_topBar {
  z-index: 10;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  padding-top: 30px;
  padding-right: 20px;
  display: flex;
  position: absolute;
}

.HEBnsa_ProfileDrawer .HEBnsa_topBar .HEBnsa_closeButton {
  position: absolute;
  top: 10px;
  right: 20px;
}

.HEBnsa_ProfileDrawer .HEBnsa_topBar .HEBnsa_closeButton svg {
  vertical-align: middle;
}

.HEBnsa_ProfileDrawer .HEBnsa_topBar .HEBnsa_shopButton {
  position: absolute;
  top: 10px;
  left: 10px;
}

.HEBnsa_ProfileDrawer .HEBnsa_editButton {
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: 30px;
  right: 40px;
}

.HEBnsa_ProfileDrawer .HEBnsa_content {
  z-index: 1;
  height: 100%;
  padding-bottom: 20px;
  position: relative;
  overflow-y: scroll;
}

.HEBnsa_ProfileDrawer .HEBnsa_content::-webkit-scrollbar {
  width: 15px;
}

.HEBnsa_ProfileDrawer .HEBnsa_content::-webkit-scrollbar-thumb {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-foreground);
  background-clip: content-box;
  border: 3px solid #0000;
  border-radius: 8px;
}

.HEBnsa_ProfileDrawer .HEBnsa_content::-webkit-scrollbar-track {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-background);
  background-clip: content-box;
  border-right: 1px solid #0000;
  border-radius: 10px;
}

.HEBnsa_ProfileDrawer .HEBnsa_bottomContent {
  z-index: 1;
  margin-top: -2px;
  padding-left: 22px;
  padding-right: 22px;
  transition: opacity .1s linear;
  position: relative;
}

.HEBnsa_ProfileDrawer .HEBnsa_bottomContent.HEBnsa_isEditing {
  opacity: 0;
  pointer-events: none;
}

.HEBnsa_ProfileDrawer .HEBnsa_themedBar {
  width: 100%;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 500;
}

.HEBnsa_ProfileDrawer .HEBnsa_themedBar .HEBnsa_avatar {
  width: 40px;
  height: 40px;
  margin-right: 17px;
}

.HEBnsa_ProfileDrawer .HEBnsa_themedBar .HEBnsa_themedBarContent {
  align-items: center;
  height: 100%;
  display: flex;
  position: absolute;
  left: 28px;
}

.HEBnsa_ProfileDrawer .HEBnsa_myPrizes {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  padding: 10px;
  display: flex;
}

.HEBnsa_ProfileDrawer .HEBnsa_myPrizes.HEBnsa_noBackgroundImage {
  background-color: var(--token-accent-theme-1-interface-mono-interface-background);
  border-radius: 10px;
}

.HEBnsa_ProfileDrawer .HEBnsa_myPrizes span {
  width: 100%;
  font-size: 14px;
}

.HEBnsa_ProfileDrawer .HEBnsa_myPrizes svg {
  flex-shrink: 0;
  height: 48px;
  margin: 0 20px 0 5px;
}

@media (width <= 768px) {
  .HEBnsa_ProfileDrawer .HEBnsa_myPrizes svg {
    margin: 0 10px 0 5px;
  }
}

.HEBnsa_ProfileDrawer .HEBnsa_myPrizes button {
  flex-shrink: 0;
  width: auto;
  padding: 9px 23px;
}

.HEBnsa_ProfileDrawer .HEBnsa_titlesUnlocked {
  padding: 15px;
}

.HEBnsa_ProfileDrawer .HEBnsa_titlesUnlocked .HEBnsa_titleStat {
  margin-bottom: 20px;
}

.HEBnsa_ProfileDrawer .HEBnsa_titlesUnlocked ul {
  flex-flow: wrap;
  justify-content: left;
  align-items: center;
  gap: 7px;
  width: 100%;
  height: 100%;
  display: flex;
}

.HEBnsa_ProfileDrawer .HEBnsa_loadingScreen {
  z-index: 10;
  background-color: #000;
  transition: none;
}

.By9l4W_fontSizeH1 {
  font-size: 55px;
}

.By9l4W_fontSizeH2 {
  font-size: 44px;
}

.By9l4W_fontSizeH3 {
  font-size: 35px;
}

.By9l4W_fontSizeH4 {
  font-size: 28px;
}

.By9l4W_fontSizeH5 {
  font-size: 22px;
}

.By9l4W_fontSizeH6 {
  font-size: 18px;
}

.By9l4W_fontSizep {
  font-size: 14px;
}

.By9l4W_fontSizeSmall {
  font-size: 12px;
}

.By9l4W_fontSizeTiny {
  font-size: 8px;
}

.By9l4W_BundleItem {
  aspect-ratio: 4 / 5;
  background-size: 100% 100%;
  width: 100%;
  padding: 30px;
  position: relative;
}

.By9l4W_BundleItem.By9l4W_noBackgroundImage {
  background-color: var(--token-accent-theme-1-interface-mono-interface-background);
  border-radius: 10px;
}

.By9l4W_BundleItem .By9l4W_label {
  z-index: 1;
  min-width: 170px;
  position: absolute;
  top: 40px;
  left: 20px;
}

.By9l4W_BundleItem .By9l4W_avatar {
  bottom: 60px !important;
}

@media (width <= 390px) {
  .By9l4W_BundleItem .By9l4W_avatar {
    bottom: 110px !important;
  }
}

.By9l4W_BundleItem .By9l4W_purchasedOverlay {
  background-color: #0009;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.By9l4W_BundleItem .By9l4W_purchasedOverlay div {
  text-align: center;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (width <= 390px) {
  .By9l4W_BundleItem .By9l4W_purchasedOverlay div {
    padding-bottom: 50px;
  }
}

.By9l4W_BundleItem .By9l4W_purchasedOverlay svg {
  width: 20%;
  margin-bottom: 10px;
  display: inline-block;
}

.By9l4W_BundleItem .By9l4W_purchasedOverlay p {
  font-family: var(--font-primary);
  text-align: center;
  font-size: 18px;
}

@media (width <= 390px) {
  .By9l4W_BundleItem .By9l4W_purchasedOverlay p {
    font-size: 12px;
  }
}

.By9l4W_BundleItem .By9l4W_interactionContainer {
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 20px;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

.By9l4W_BundleItem .By9l4W_interactionContainer > * {
  width: 50%;
}

.By9l4W_BundleItem .By9l4W_interactionContainer .By9l4W_cardDescription {
  height: auto;
  padding-right: 32px;
}

@media (width <= 390px) {
  .By9l4W_BundleItem .By9l4W_interactionContainer {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .By9l4W_BundleItem .By9l4W_interactionContainer .By9l4W_cardDescription {
    max-width: 170px;
  }

  .By9l4W_BundleItem .By9l4W_interactionContainer button {
    width: 100%;
  }
}

.By9l4W_BundleItem.By9l4W_longTitle .By9l4W_avatar {
  bottom: 90px !important;
}

@media (width <= 390px) {
  .By9l4W_BundleItem.By9l4W_longTitle .By9l4W_avatar {
    bottom: 110px !important;
  }

  .By9l4W_BundleItem.By9l4W_longTitle .By9l4W_cardDescription {
    max-width: 500px;
  }
}

.By9l4W_BundleItem .By9l4W_loadingScreen {
  z-index: 10;
  background-color: #000;
  transition: none;
}

.xggSTG_fontSizeH1 {
  font-size: 55px;
}

.xggSTG_fontSizeH2 {
  font-size: 44px;
}

.xggSTG_fontSizeH3 {
  font-size: 35px;
}

.xggSTG_fontSizeH4 {
  font-size: 28px;
}

.xggSTG_fontSizeH5 {
  font-size: 22px;
}

.xggSTG_fontSizeH6 {
  font-size: 18px;
}

.xggSTG_fontSizep {
  font-size: 14px;
}

.xggSTG_fontSizeSmall {
  font-size: 12px;
}

.xggSTG_fontSizeTiny {
  font-size: 8px;
}

.xggSTG_BundleModal {
  z-index: 20;
  background-color: #000c;
  width: 100%;
  max-width: 100%;
  height: 100vh;
  animation: .4s ease-out forwards xggSTG_fadeInBackground;
  position: absolute;
  top: 0;
  left: 0;
}

.xggSTG_BundleModal.xggSTG_closeAnimation {
  animation: .4s ease-out forwards xggSTG_fadeOutBackground;
}

.xggSTG_BundleModal.xggSTG_closeAnimation .xggSTG_modal {
  animation: .3s ease-out forwards xggSTG_bulgeEffectOut;
}

.xggSTG_BundleModal .xggSTG_modal {
  background-size: 100% 100%;
  width: 450px;
  max-width: calc(100% - 40px);
  max-height: calc(100dvh - 100px);
  margin: auto;
  animation: .3s ease-out forwards xggSTG_bulgeEffectIn;
  position: relative;
  top: 50px;
}

.xggSTG_BundleModal .xggSTG_modal.xggSTG_noBackgroundImage {
  background-color: var(--token-accent-theme-1-interface-mono-interface-midground);
  border-radius: 10px;
}

.xggSTG_BundleModal .xggSTG_scrollContainer {
  width: 100%;
  max-height: calc(100dvh - 100px);
  padding: 20px;
  position: relative;
  overflow-y: auto;
}

.xggSTG_BundleModal .xggSTG_scrollContainer::-webkit-scrollbar {
  width: 15px;
}

.xggSTG_BundleModal .xggSTG_scrollContainer::-webkit-scrollbar-thumb {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-foreground);
  background-clip: content-box;
  border: 3px solid #0000;
  border-radius: 8px;
}

.xggSTG_BundleModal .xggSTG_scrollContainer::-webkit-scrollbar-track {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-background);
  background-clip: content-box;
  border-right: 1px solid #0000;
  border-radius: 10px;
}

.xggSTG_BundleModal .xggSTG_closeButton {
  z-index: 1;
  position: absolute;
  top: -15px;
  right: -15px;
}

.xggSTG_BundleModal .xggSTG_title {
  text-align: center;
  width: 100%;
  padding: 0 20px;
}

.xggSTG_BundleModal .xggSTG_assets {
  flex-wrap: wrap;
  gap: 10px;
  padding: 20px 0;
  list-style: none;
  display: flex;
}

.xggSTG_BundleModal .xggSTG_assets li {
  width: calc(50% - 5px);
}

.xggSTG_BundleModal .xggSTG_assets .xggSTG_frameItem img {
  width: calc(100% - 40px);
  margin-bottom: 25px;
}

.xggSTG_BundleModal .xggSTG_assets .xggSTG_characterItem img {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  margin: 20px;
}

.xggSTG_BundleModal .xggSTG_assets .xggSTG_backgroundItem img {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  margin: 20px 20px 30px;
}

.xggSTG_BundleModal .xggSTG_assets .xggSTG_blankItem {
  opacity: .5;
  pointer-events: none;
}

.xggSTG_BundleModal .xggSTG_assets .xggSTG_blankItem.xggSTG_noBackgroundImage {
  background-color: var(--token-accent-theme-1-interface-mono-interface-background);
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.xggSTG_BundleModal .xggSTG_interactionContainer {
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: 10px;
  width: 100%;
  display: flex;
}

.xggSTG_BundleModal .xggSTG_interactionContainer > * {
  width: 50%;
}

@media (width <= 390px) {
  .xggSTG_BundleModal .xggSTG_interactionContainer {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .xggSTG_BundleModal .xggSTG_interactionContainer > * {
    width: 100%;
  }
}

.xggSTG_BundleModal .xggSTG_bottomText {
  color: var(--token-accent-theme-1-text-text-default);
  font-family: var(--font-tertiary);
  opacity: .5;
  padding-top: 15px;
  font-size: 14px;
  font-weight: 300;
}

.xggSTG_BundleModal .xggSTG_guestOverlay {
  text-align: center;
  pointer-events: none;
  background: linear-gradient(0deg, #000000e6 50%, #0000 100%);
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  min-height: 70%;
  padding: 20px 20px 80px;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

.xggSTG_BundleModal .xggSTG_guestOverlay > * {
  pointer-events: all;
}

.xggSTG_BundleModal .xggSTG_guestOverlay h2, .xggSTG_BundleModal .xggSTG_guestOverlay p {
  padding-bottom: 20px;
}

@keyframes xggSTG_fadeInBackground {
  0% {
    background-color: #0000;
  }

  100% {
    background-color: #000c;
  }
}

@keyframes xggSTG_fadeOutBackground {
  0% {
    background-color: #000c;
  }

  100% {
    background-color: #0000;
  }
}

@keyframes xggSTG_bulgeEffectIn {
  0% {
    opacity: 0;
    transform: scale(.8);
  }

  50% {
    opacity: 1;
    transform: scale(1.04);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes xggSTG_bulgeEffectOut {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.04);
  }

  65% {
    opacity: 1;
    transform: scale(1.04);
  }

  100% {
    opacity: 0;
    transform: scale(.8);
  }
}

._XoYAq_fontSizeH1 {
  font-size: 55px;
}

._XoYAq_fontSizeH2 {
  font-size: 44px;
}

._XoYAq_fontSizeH3 {
  font-size: 35px;
}

._XoYAq_fontSizeH4 {
  font-size: 28px;
}

._XoYAq_fontSizeH5 {
  font-size: 22px;
}

._XoYAq_fontSizeH6 {
  font-size: 18px;
}

._XoYAq_fontSizep {
  font-size: 14px;
}

._XoYAq_fontSizeSmall {
  font-size: 12px;
}

._XoYAq_fontSizeTiny {
  font-size: 8px;
}

._XoYAq_ShopExternalItem {
  background-size: 100% 100%;
  width: 100%;
  padding: 30px;
  position: relative;
}

._XoYAq_ShopExternalItem._XoYAq_noBackgroundImage {
  background-color: var(--token-accent-theme-1-interface-brand-interface-background);
  border-radius: 10px;
}

._XoYAq_ShopExternalItem ._XoYAq_label {
  width: auto;
  min-width: 170px;
  height: auto;
  margin-bottom: 10px;
  display: inline-block;
}

._XoYAq_ShopExternalItem ._XoYAq_price {
  font-family: var(--font-secondary);
  width: 100%;
  display: inline-block;
}

._XoYAq_ShopExternalItem ._XoYAq_image {
  background-color: var(--token-accent-theme-1-interface-brand-interface-midground);
  object-fit: contain;
  min-width: 200px;
  max-width: 100%;
  min-height: 200px;
  max-height: 400px;
  margin: 10px auto;
}

._XoYAq_ShopExternalItem ._XoYAq_interactionContainer {
  justify-content: flex-end;
  display: flex;
}

._XoYAq_ShopExternalItem ._XoYAq_interactionContainer ._XoYAq_button {
  font-family: var(--font-secondary);
  width: auto;
  display: inline-block;
}

@media (width <= 390px) {
  ._XoYAq_ShopExternalItem ._XoYAq_interactionContainer ._XoYAq_button {
    width: 100%;
  }
}

.QMaCRa_fontSizeH1 {
  font-size: 55px;
}

.QMaCRa_fontSizeH2 {
  font-size: 44px;
}

.QMaCRa_fontSizeH3 {
  font-size: 35px;
}

.QMaCRa_fontSizeH4 {
  font-size: 28px;
}

.QMaCRa_fontSizeH5 {
  font-size: 22px;
}

.QMaCRa_fontSizeH6 {
  font-size: 18px;
}

.QMaCRa_fontSizep {
  font-size: 14px;
}

.QMaCRa_fontSizeSmall {
  font-size: 12px;
}

.QMaCRa_fontSizeTiny {
  font-size: 8px;
}

.QMaCRa_ShopDrawer {
  z-index: 120;
  color: var(--token-accent-theme-1-text-text-default);
  -webkit-backdrop-filter: blur(10px);
  opacity: 0;
  background-color: #000000b3;
  width: 100%;
  height: 100%;
  transition: right .5s cubic-bezier(.5, .33, .33, 1), opacity 0s linear .5s;
  position: absolute;
  top: 0;
  right: -520px;
}

@media (width <= 992px) {
  .QMaCRa_ShopDrawer {
    width: 100%;
    right: -100%;
  }
}

.QMaCRa_ShopDrawer.QMaCRa_isOpen {
  opacity: 1;
  transition: right .5s cubic-bezier(.5, .33, .33, 1), opacity linear;
  right: 0;
}

.QMaCRa_ShopDrawer .QMaCRa_scrollContainer {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: scroll;
}

.QMaCRa_ShopDrawer .QMaCRa_scrollContainer::-webkit-scrollbar {
  width: 15px;
}

.QMaCRa_ShopDrawer .QMaCRa_scrollContainer::-webkit-scrollbar-thumb {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-foreground);
  background-clip: content-box;
  border: 3px solid #0000;
  border-radius: 8px;
}

.QMaCRa_ShopDrawer .QMaCRa_scrollContainer::-webkit-scrollbar-track {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-background);
  background-clip: content-box;
  border-right: 1px solid #0000;
  border-radius: 10px;
}

.QMaCRa_ShopDrawer h2 {
  font-family: var(--font-primary);
  text-align: center;
  font-size: 18px;
}

.QMaCRa_ShopDrawer .QMaCRa_content {
  border-style: solid;
  background-position: center;
  background-size: 200% 200%;
  border-image-slice: 60;
  border-image-width: 30px;
  border-image-outset: 5px;
  border-image-repeat: stretch;
  width: 100%;
  max-width: 430px;
  min-height: 500px;
  padding: 20px;
  position: absolute;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
}

.QMaCRa_ShopDrawer .QMaCRa_content.QMaCRa_noBackgroundImage {
  background-color: var(--token-accent-theme-1-interface-mono-interface-midground);
  border-radius: 10px;
}

@media (width <= 992px) {
  .QMaCRa_ShopDrawer .QMaCRa_content {
    max-width: 500px;
  }
}

.QMaCRa_ShopDrawer .QMaCRa_content .QMaCRa_title {
  margin: 10px;
}

.QMaCRa_ShopDrawer .QMaCRa_content .QMaCRa_emptyText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
}

.QMaCRa_ShopDrawer .QMaCRa_content li {
  width: 100%;
  height: 100%;
  display: block;
}

.QMaCRa_ShopDrawer .QMaCRa_items {
  width: 100%;
  min-height: 500px;
  position: relative;
}

.QMaCRa_ShopDrawer .QMaCRa_tabButtons {
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
  display: flex;
}

.QMaCRa_ShopDrawer .QMaCRa_tabButtons li {
  width: 40px;
  height: 40px;
}

.QMaCRa_ShopDrawer .QMaCRa_tabButtons button, .QMaCRa_ShopDrawer .QMaCRa_tabButtons button svg {
  width: 100%;
  height: 100%;
}

.QMaCRa_ShopDrawer .QMaCRa_tabButtons button svg path {
  fill: var(--token-accent-theme-1-button-button-secondary-inactive);
}

.QMaCRa_ShopDrawer .QMaCRa_tabButtons button.QMaCRa_isActive svg path {
  fill: var(--token-accent-theme-1-button-button-secondary-active);
}

.QMaCRa_ShopDrawer .QMaCRa_tabButtons button:hover svg path, .QMaCRa_ShopDrawer .QMaCRa_tabButtons button:focus svg path {
  fill: var(--token-accent-theme-1-button-button-secondary-highlight);
}

.QMaCRa_ShopDrawer .QMaCRa_assets {
  flex-direction: column;
  justify-content: left;
  align-items: center;
  gap: 20px;
  width: 100%;
  display: flex;
}

.QMaCRa_ShopDrawer .QMaCRa_contentTitle {
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
}

.QMaCRa_ShopDrawer .QMaCRa_topBar {
  z-index: 10;
  width: 100%;
  height: 80px;
  position: absolute;
}

.QMaCRa_ShopDrawer .QMaCRa_topBar .QMaCRa_title {
  text-align: center;
  position: absolute;
  top: 25px;
  left: 50%;
  transform: translateX(-50%);
}

.QMaCRa_ShopDrawer .QMaCRa_topBar .QMaCRa_closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
}

.TWxbda_fontSizeH1 {
  font-size: 55px;
}

.TWxbda_fontSizeH2 {
  font-size: 44px;
}

.TWxbda_fontSizeH3 {
  font-size: 35px;
}

.TWxbda_fontSizeH4 {
  font-size: 28px;
}

.TWxbda_fontSizeH5 {
  font-size: 22px;
}

.TWxbda_fontSizeH6 {
  font-size: 18px;
}

.TWxbda_fontSizep {
  font-size: 14px;
}

.TWxbda_fontSizeSmall {
  font-size: 12px;
}

.TWxbda_fontSizeTiny {
  font-size: 8px;
}

.TWxbda_PinnedMessage {
  pointer-events: none;
  background: linear-gradient(0deg, #0000 0%, #000 50%);
  width: 100%;
  padding: 17px 0 50px;
  animation: .5s ease-in-out forwards TWxbda_fadeIn;
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes TWxbda_fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.TWxbda_PinnedMessage .TWxbda_message {
  background-color: var(--token-arise-theme-1-state-state-success-dark);
  font-family: var(--font-tertiary);
  word-break: break-word;
  pointer-events: auto;
  border-radius: 10px;
  width: calc(100% - 76px);
  margin: 0 38px;
  font-size: 14px;
  display: flex;
  position: relative;
}

@media (width <= 768px) {
  .TWxbda_PinnedMessage .TWxbda_message {
    width: calc(100% - 50px);
    margin: 0 25px;
  }
}

@media (width <= 576px) {
  .TWxbda_PinnedMessage .TWxbda_message {
    width: calc(100% - 30px);
    margin: 0 15px;
  }
}

.TWxbda_PinnedMessage .TWxbda_message .TWxbda_badge {
  background-color: var(--token-arise-theme-1-state-state-success-mid);
  color: var(--token-arise-theme-1-state-state-success-light);
  border-radius: 30px;
  height: 21px;
  padding: 0 8px;
  font-size: 14px;
  font-weight: bold;
  position: absolute;
  top: -10px;
  left: 10px;
}

.TWxbda_PinnedMessage .TWxbda_message .TWxbda_badge svg {
  vertical-align: sub;
  height: 16px;
  margin-right: 2px;
  display: inline-block;
}

.TWxbda_PinnedMessage .TWxbda_message .TWxbda_badge svg path {
  fill: var(--token-arise-theme-1-state-state-success-light);
}

.TWxbda_PinnedMessage .TWxbda_message .TWxbda_text {
  width: 100%;
  height: 100%;
  padding: 20px 15px 15px;
}

.TWxbda_PinnedMessage .TWxbda_message .TWxbda_text .TWxbda_title {
  color: var(--token-arise-theme-1-state-state-success-light);
  font-weight: bold;
}

.TWxbda_PinnedMessage .TWxbda_message .TWxbda_text a {
  color: var(--token-accent-theme-1-text-text-link);
}

.TWxbda_PinnedMessage .TWxbda_message .TWxbda_text a:hover, .TWxbda_PinnedMessage .TWxbda_message .TWxbda_text a:focus {
  text-decoration: underline;
}

.TWxbda_PinnedMessage .TWxbda_message .TWxbda_image {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-background);
  background-position: center;
  background-size: cover;
  border-radius: 10px;
  width: 200px;
  margin-left: -15px;
  -webkit-mask-image: url("/assets/global/images/slanted-image-mask.webp");
  mask-image: url("/assets/global/images/slanted-image-mask.webp");
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}

.wuwwpq_fontSizeH1 {
  font-size: 55px;
}

.wuwwpq_fontSizeH2 {
  font-size: 44px;
}

.wuwwpq_fontSizeH3 {
  font-size: 35px;
}

.wuwwpq_fontSizeH4 {
  font-size: 28px;
}

.wuwwpq_fontSizeH5 {
  font-size: 22px;
}

.wuwwpq_fontSizeH6 {
  font-size: 18px;
}

.wuwwpq_fontSizep {
  font-size: 14px;
}

.wuwwpq_fontSizeSmall {
  font-size: 12px;
}

.wuwwpq_fontSizeTiny {
  font-size: 8px;
}

.wuwwpq_Sidebar {
  -webkit-backdrop-filter: blur(10px);
  background-color: #000000b3;
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
}

.wuwwpq_top {
  z-index: 50;
  background-color: #000;
  border-bottom: 1px solid #fff3;
  flex-direction: column;
  justify-content: center;
  height: 70px;
  display: flex;
}

.wuwwpq_top .wuwwpq_title {
  text-align: center;
  text-transform: uppercase;
  font-family: var(--font-secondary);
  font-size: 18px;
  line-height: normal;
}

.wuwwpq_top h1.wuwwpq_title {
  color: var(--token-LEGACY-accent-theme-1-text-text-active);
}

.wuwwpq_top h2.wuwwpq_title {
  color: var(--token-LEGACY-accent-theme-1-brand-colour);
}

.wuwwpq_top .wuwwpq_headerImage {
  object-fit: contain;
  width: auto;
  max-width: calc(100% - 120px);
  height: 100%;
  margin: auto;
  padding: 10px;
}

.wuwwpq_closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
}

.wuwwpq_shopButton {
  position: absolute;
  top: 10px;
  left: 10px;
}

.wuwwpq_bottom {
  height: calc(100% - 70px);
  position: relative;
}

.wuwwpq_infobar {
  background: linear-gradient(0deg, transparent 0%, var(--token-LEGACY-accent-theme-1-interface-interface-black) 100%);
  pointer-events: none;
  width: 100%;
  height: 400px;
  padding: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

@media (height <= 900px) {
  .wuwwpq_infobar {
    height: 200px;
  }
}

@media (height <= 700px) {
  .wuwwpq_infobar {
    height: 70px;
  }
}

.wuwwpq_feedContainer {
  width: 100%;
  height: 100%;
}

.jYBf7G_fontSizeH1 {
  font-size: 55px;
}

.jYBf7G_fontSizeH2 {
  font-size: 44px;
}

.jYBf7G_fontSizeH3 {
  font-size: 35px;
}

.jYBf7G_fontSizeH4 {
  font-size: 28px;
}

.jYBf7G_fontSizeH5 {
  font-size: 22px;
}

.jYBf7G_fontSizeH6 {
  font-size: 18px;
}

.jYBf7G_fontSizep {
  font-size: 14px;
}

.jYBf7G_fontSizeSmall {
  font-size: 12px;
}

.jYBf7G_fontSizeTiny {
  font-size: 8px;
}

.jYBf7G_InventoryDrawer {
  z-index: 5;
  transition: transform .3s ease-in-out 10ms;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, calc(100% + 30px));
}

.jYBf7G_InventoryDrawer__visible {
  transform: translate(-50%);
}

.jYBf7G_Inventory {
  border-style: solid;
  background-position: center;
  background-size: 200% 200%;
  border-image-slice: 60;
  border-image-width: 30px;
  border-image-outset: 5px;
  border-image-repeat: stretch;
  width: 500px;
  padding: 50px 50px 30px;
  position: relative;
}

.jYBf7G_Inventory.jYBf7G_noBackgroundImage {
  background-color: var(--token-accent-theme-1-interface-mono-interface-midground);
  border-radius: 10px 10px 0 0;
}

.jYBf7G_closeButton {
  position: absolute;
  top: -15px;
  right: -15px;
}

.jYBf7G_pageControls {
  text-align: center;
  width: 150px;
  position: relative;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.jYBf7G_previousButton {
  position: absolute;
  left: 0;
  transform: scaleX(-1);
}

.jYBf7G_nextButton {
  position: absolute;
  right: 0;
}

.jYBf7G_pageNumber {
  display: inline-block;
}

.jYBf7G_items {
  flex-wrap: wrap;
  place-content: start space-between;
  align-items: start;
  transition: opacity .5s ease-out;
  display: flex;
  position: relative;
}

.jYBf7G_items .jYBf7G_box {
  width: 48.5%;
  margin-bottom: 5%;
}

.jYBf7G_info {
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
  position: relative;
}

@media screen and (width <= 768px), screen and (height <= 800px) {
  .jYBf7G_Inventory {
    width: 350px;
    padding: 30px;
  }
}

._0qTcRq_FailOverlay {
  height: 100%;
  transition: translate 1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  translate: -100%;
}

._0qTcRq_FailOverlay img {
  max-width: initial;
  height: 0;
  transition: height 0s 1s;
}

._0qTcRq_FailOverlayBg {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-black);
  opacity: 0;
  width: 0%;
  height: 100%;
  transition: opacity 1s ease-in-out, width 0s ease-in-out 1s;
  position: absolute;
  top: 0;
  left: 0;
}

._0qTcRq_failed._0qTcRq_FailOverlay {
  translate: 0;
}

._0qTcRq_failed._0qTcRq_FailOverlay img {
  height: 100%;
  transition-delay: 0s;
}

._0qTcRq_failed._0qTcRq_FailOverlayBg {
  opacity: 1;
  width: 100%;
  transition-delay: 0s;
}

.Pn2q8W_fontSizeH1 {
  font-size: 55px;
}

.Pn2q8W_fontSizeH2 {
  font-size: 44px;
}

.Pn2q8W_fontSizeH3 {
  font-size: 35px;
}

.Pn2q8W_fontSizeH4 {
  font-size: 28px;
}

.Pn2q8W_fontSizeH5 {
  font-size: 22px;
}

.Pn2q8W_fontSizeH6 {
  font-size: 18px;
}

.Pn2q8W_fontSizep {
  font-size: 14px;
}

.Pn2q8W_fontSizeSmall {
  font-size: 12px;
}

.Pn2q8W_fontSizeTiny {
  font-size: 8px;
}

.Pn2q8W_AchievementFeed {
  z-index: 1001;
  width: 100%;
  height: 0;
  position: absolute;
  top: 0;
}

.Pn2q8W_AchievementFeed .Pn2q8W_achievementContainer {
  transition: transform .12s ease-in;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -100%);
}

.Pn2q8W_AchievementFeed .Pn2q8W_achievementContainer.Pn2q8W_visible {
  transition: transform .3s cubic-bezier(0, 1.67, .75, .99);
  transform: translate(-50%, 5px);
}

.MaV-MW_fontSizeH1 {
  font-size: 55px;
}

.MaV-MW_fontSizeH2 {
  font-size: 44px;
}

.MaV-MW_fontSizeH3 {
  font-size: 35px;
}

.MaV-MW_fontSizeH4 {
  font-size: 28px;
}

.MaV-MW_fontSizeH5 {
  font-size: 22px;
}

.MaV-MW_fontSizeH6 {
  font-size: 18px;
}

.MaV-MW_fontSizep {
  font-size: 14px;
}

.MaV-MW_fontSizeSmall {
  font-size: 12px;
}

.MaV-MW_fontSizeTiny {
  font-size: 8px;
}

.MaV-MW_Achievement {
  background: none;
  width: 360px;
  min-height: 60px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  position: relative;
}

.MaV-MW_Achievement .MaV-MW_background {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-midground);
  border-radius: 30px;
  width: 70%;
  height: 100%;
  transition: width .4s ease-in-out;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.MaV-MW_Achievement.MaV-MW_visible .MaV-MW_background {
  width: 100%;
  transition: width .4s cubic-bezier(0, 1.67, .75, .99);
}

.MaV-MW_Achievement .MaV-MW_label {
  color: var(--token-accent-theme-1-text-text-default);
  align-items: center;
  width: 100%;
  min-height: 42px;
  margin: 8px 40px 8px 69px;
  display: flex;
  position: relative;
}

.MaV-MW_Achievement .MaV-MW_label .MaV-MW_highlighted {
  color: var(--token-accent-theme-1-text-text-default);
}

.MaV-MW_Achievement .MaV-MW_label .MaV-MW_hasLineBreak {
  display: block;
}

.MaV-MW_Achievement .MaV-MW_icon {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-foreground);
  border-radius: 100%;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 5px;
  left: 5px;
  overflow: hidden;
}

.MaV-MW_Achievement.MaV-MW_danger .MaV-MW_background {
  background-color: var(--token-arise-theme-1-state-state-error-dark);
}

.MaV-MW_Achievement.MaV-MW_danger .MaV-MW_icon {
  background-color: var(--token-arise-theme-1-state-state-error-light);
}

.MaV-MW_Achievement.MaV-MW_danger .MaV-MW_highlighted {
  color: var(--token-arise-theme-1-state-state-error-light);
}

.MaV-MW_Achievement.MaV-MW_success .MaV-MW_background {
  background-color: var(--token-arise-theme-1-state-state-success-mid);
}

.MaV-MW_Achievement.MaV-MW_success .MaV-MW_icon {
  background-color: var(--token-arise-theme-1-state-state-success-light);
}

.MaV-MW_Achievement.MaV-MW_success .MaV-MW_highlighted {
  color: var(--token-arise-theme-1-state-state-success-light);
}

.VhgSmG_fontSizeH1 {
  font-size: 55px;
}

.VhgSmG_fontSizeH2 {
  font-size: 44px;
}

.VhgSmG_fontSizeH3 {
  font-size: 35px;
}

.VhgSmG_fontSizeH4 {
  font-size: 28px;
}

.VhgSmG_fontSizeH5 {
  font-size: 22px;
}

.VhgSmG_fontSizeH6 {
  font-size: 18px;
}

.VhgSmG_fontSizep {
  font-size: 14px;
}

.VhgSmG_fontSizeSmall {
  font-size: 12px;
}

.VhgSmG_fontSizeTiny {
  font-size: 8px;
}

.VhgSmG_locationIntroduction {
  z-index: 100;
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-black);
  color: var(--token-accent-theme-1-text-text-default);
  text-align: center;
  font-family: var(--font-primary);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: 100px;
  padding-bottom: 60px;
  animation-timing-function: ease-out;
  display: flex;
  position: absolute;
}

@media (width <= 992px) {
  .VhgSmG_locationIntroduction {
    padding-bottom: 10px;
  }
}

.VhgSmG_locationIntroduction__title {
  text-transform: uppercase;
  margin: auto;
  padding: 10px;
  font-size: 44px;
  line-height: normal;
  animation: 1s VhgSmG_moveUp, 1.5s VhgSmG_fadeIn;
  display: block;
  position: relative;
}

@media (width <= 992px) {
  .VhgSmG_locationIntroduction__title {
    font-size: 35px;
  }
}

.VhgSmG_locationIntroduction__titlePrefix {
  color: var(--token-LEGACY-accent-theme-1-brand-colour);
}

.VhgSmG_locationIntroduction__titleText {
  color: var(--token-accent-theme-1-text-text-default);
}

.VhgSmG_locationIntroduction__text {
  width: 430px;
  max-width: 100%;
  padding: 10px;
  font-size: 18px;
  animation: 1s VhgSmG_moveUp, 1.5s VhgSmG_fadeIn;
  display: block;
  position: relative;
}

.VhgSmG_locationIntroduction__image {
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: auto 100%;
  width: 100%;
  height: 50%;
  animation: 1s VhgSmG_moveUpFaster, 1.5s VhgSmG_fadeIn;
  position: absolute;
  bottom: 0;
}

.VhgSmG_locationIntroduction__soundWarning {
  color: var(--token-LEGACY-accent-theme-1-brand-colour);
  text-transform: none;
  font-size: 18px;
}

.VhgSmG_locationIntroduction__soundWarning svg {
  margin-bottom: 3px;
  margin-right: 8px;
}

.VhgSmG_locationIntroduction__soundWarning svg path {
  fill: var(--token-LEGACY-accent-theme-1-brand-colour);
}

@keyframes VhgSmG_moveUp {
  0% {
    transform: translateY(150px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes VhgSmG_moveUpFaster {
  0% {
    transform: translateY(300px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes VhgSmG_fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.X2YSWW_fontSizeH1 {
  font-size: 55px;
}

.X2YSWW_fontSizeH2 {
  font-size: 44px;
}

.X2YSWW_fontSizeH3 {
  font-size: 35px;
}

.X2YSWW_fontSizeH4 {
  font-size: 28px;
}

.X2YSWW_fontSizeH5 {
  font-size: 22px;
}

.X2YSWW_fontSizeH6 {
  font-size: 18px;
}

.X2YSWW_fontSizep {
  font-size: 14px;
}

.X2YSWW_fontSizeSmall {
  font-size: 12px;
}

.X2YSWW_fontSizeTiny {
  font-size: 8px;
}

.X2YSWW_QuickReact {
  filter: drop-shadow(0 0 40px #0009);
  background-color: var(--token-accent-theme-1-interface-brand-interface-midground);
  border-radius: 15px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  display: flex;
  position: relative;
}

.X2YSWW_QuickReact .X2YSWW_message {
  flex-direction: row;
  justify-content: left;
  align-items: center;
  width: calc(100% - 50px);
  height: 100%;
  padding-left: 15px;
  display: flex;
}

.X2YSWW_QuickReact .X2YSWW_message .X2YSWW_text {
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
}

.X2YSWW_QuickReact .X2YSWW_message .X2YSWW_avatar {
  border-radius: 100%;
  flex: none;
  width: 35px;
  height: 35px;
  margin-right: 20px;
}

.X2YSWW_QuickReact .X2YSWW_react {
  border-radius: 15px;
  flex: none;
  padding: 10px 15px 10px 10px;
  position: relative;
}

.X2YSWW_QuickReact .X2YSWW_react .X2YSWW_reactionButtonImg {
  width: 25px;
  height: 25px;
}

.X2YSWW_QuickReact .X2YSWW_react .X2YSWW_reactionButtonImg:hover {
  transform: scale(1.1);
}

.X2YSWW_QuickReact .X2YSWW_react:disabled .X2YSWW_reactionButtonImg:hover {
  transform: none;
}

.X2YSWW_QuickReact .X2YSWW_floatingReactions {
  pointer-events: none;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 10px;
  left: 10px;
}

.X2YSWW_QuickReact .X2YSWW_floatingReactions img {
  width: 100%;
  height: 100%;
  animation-name: X2YSWW_yAnimation, X2YSWW_xAnimation;
  animation-duration: 2s;
  animation-timing-function: linear, ease-in-out;
  position: absolute;
}

.X2YSWW_QuickReact .X2YSWW_floatingReactions img:nth-child(2) {
  animation-name: X2YSWW_yAnimation, X2YSWW_xAnimation-alt;
  animation-delay: 1s;
}

.X2YSWW_QuickReact .X2YSWW_floatingReactions img:nth-child(3) {
  animation-delay: 1.5s;
}

@keyframes X2YSWW_yAnimation {
  0% {
    opacity: 1;
    bottom: 0;
  }

  50% {
    opacity: .8;
  }

  100% {
    opacity: 0;
    bottom: 200px;
  }
}

@keyframes X2YSWW_xAnimation {
  0% {
    left: 0;
    transform: scale(1);
  }

  35% {
    left: -35px;
    transform: scale(1.2);
  }

  75% {
    left: 30px;
  }

  100% {
    left: 0;
    transform: scale(.8);
  }
}

@keyframes X2YSWW_xAnimation-alt {
  0% {
    left: 0;
    transform: scale(1);
  }

  35% {
    left: 35px;
    transform: scale(1.2);
  }

  75% {
    left: -30px;
  }

  100% {
    left: 0;
    transform: scale(.8);
  }
}

.OKKoGa_fontSizeH1 {
  font-size: 55px;
}

.OKKoGa_fontSizeH2 {
  font-size: 44px;
}

.OKKoGa_fontSizeH3 {
  font-size: 35px;
}

.OKKoGa_fontSizeH4 {
  font-size: 28px;
}

.OKKoGa_fontSizeH5 {
  font-size: 22px;
}

.OKKoGa_fontSizeH6 {
  font-size: 18px;
}

.OKKoGa_fontSizep {
  font-size: 14px;
}

.OKKoGa_fontSizeSmall {
  font-size: 12px;
}

.OKKoGa_fontSizeTiny {
  font-size: 8px;
}

.OKKoGa_QuickChatDrawer {
  pointer-events: none;
  width: 100%;
  max-width: 515px;
  height: 65px;
  transition: transform .7s cubic-bezier(0, 1.67, .75, .99);
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateX(100%);
}

.OKKoGa_QuickChatDrawer.OKKoGa_chatWidgetState {
  transform: translateX(calc(100% - 80px));
}

.OKKoGa_QuickChatDrawer.OKKoGa_chatWidgetAndQuickReactState {
  transform: translateX(0);
}

.OKKoGa_QuickChatDrawer.OKKoGa_hiddenState {
  transition: transform .2s linear;
  transform: translateX(100%);
}

.OKKoGa_QuickChatDrawer .OKKoGa_chatWidget {
  pointer-events: all;
  position: absolute;
  top: 0;
  left: 15px;
}

.OKKoGa_QuickChatDrawer .OKKoGa_quickReact {
  pointer-events: all;
  width: calc(100% - 95px);
  max-width: 450px;
  position: absolute;
  top: 3px;
  left: 80px;
}

.UAFalW_fontSizeH1 {
  font-size: 55px;
}

.UAFalW_fontSizeH2 {
  font-size: 44px;
}

.UAFalW_fontSizeH3 {
  font-size: 35px;
}

.UAFalW_fontSizeH4 {
  font-size: 28px;
}

.UAFalW_fontSizeH5 {
  font-size: 22px;
}

.UAFalW_fontSizeH6 {
  font-size: 18px;
}

.UAFalW_fontSizep {
  font-size: 14px;
}

.UAFalW_fontSizeSmall {
  font-size: 12px;
}

.UAFalW_fontSizeTiny {
  font-size: 8px;
}

.UAFalW_FloatingHeaderBar {
  position: absolute;
  top: 15px;
  left: 15px;
}

.UAFalW_FloatingHeaderBar.UAFalW_isExpanded .UAFalW_button {
  transition: top .4s cubic-bezier(0, 1.67, .75, .99);
}

.UAFalW_FloatingHeaderBar.UAFalW_isExpanded .UAFalW_button:first-child {
  top: 70px;
}

.UAFalW_FloatingHeaderBar.UAFalW_isExpanded .UAFalW_button:nth-child(2) {
  top: 130px;
}

.UAFalW_FloatingHeaderBar.UAFalW_isExpanded .UAFalW_button:nth-child(3) {
  top: 190px;
}

.UAFalW_FloatingHeaderBar.UAFalW_isExpanded .UAFalW_button:nth-child(4) {
  top: 250px;
}

.UAFalW_FloatingHeaderBar.UAFalW_isExpanded .UAFalW_button:nth-child(5) {
  top: 310px;
}

.UAFalW_FloatingHeaderBar.UAFalW_isExpanded .UAFalW_button:nth-child(6) {
  top: 370px;
}

.UAFalW_FloatingHeaderBar.UAFalW_isExpanded .UAFalW_button:nth-child(7) {
  top: 430px;
}

.UAFalW_FloatingHeaderBar.UAFalW_isExpanded .UAFalW_button:nth-child(8) {
  top: 490px;
}

.UAFalW_FloatingHeaderBar.UAFalW_isExpanded .UAFalW_button:nth-child(9) {
  top: 550px;
}

.UAFalW_FloatingHeaderBar.UAFalW_isExpanded .UAFalW_button:nth-child(10) {
  top: 610px;
}

.UAFalW_FloatingHeaderBar .UAFalW_button {
  transition: top .2s cubic-bezier(.5, .33, .33, 1);
  position: absolute;
  top: 0;
}

.UAFalW_FloatingHeaderBar .UAFalW_buttonsContainer {
  position: absolute;
  top: 5px;
  left: 5px;
}

.UAFalW_FloatingHeaderBar .UAFalW_expandButton {
  z-index: 1;
  background-color: var(--token-accent-theme-1-brand-palette-brand-colour);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  display: flex;
  position: relative;
}

.UAFalW_FloatingHeaderBar .UAFalW_expandButton:hover .UAFalW_logoIcon {
  transform: scale(1.1);
}

.UAFalW_FloatingHeaderBar .UAFalW_expandButton:hover .UAFalW_buttonStatus {
  filter: brightness(1.4);
}

.UAFalW_FloatingHeaderBar .UAFalW_expandButton .UAFalW_buttonStatus {
  background-color: var(--token-accent-theme-1-interface-brand-interface-midground);
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 13px;
  transition: filter .2s cubic-bezier(.5, .33, .33, 1);
  display: flex;
  position: absolute;
  bottom: -4px;
  left: 16px;
}

.UAFalW_FloatingHeaderBar .UAFalW_expandButton .UAFalW_logoIcon {
  width: 42px;
  height: 42px;
  transition: transform .2s cubic-bezier(.5, .33, .33, 1);
}

._5b6GcW_fontSizeH1 {
  font-size: 55px;
}

._5b6GcW_fontSizeH2 {
  font-size: 44px;
}

._5b6GcW_fontSizeH3 {
  font-size: 35px;
}

._5b6GcW_fontSizeH4 {
  font-size: 28px;
}

._5b6GcW_fontSizeH5 {
  font-size: 22px;
}

._5b6GcW_fontSizeH6 {
  font-size: 18px;
}

._5b6GcW_fontSizep {
  font-size: 14px;
}

._5b6GcW_fontSizeSmall {
  font-size: 12px;
}

._5b6GcW_fontSizeTiny {
  font-size: 8px;
}

._5b6GcW_ThemedIconButton {
  background-color: var(--token-accent-theme-1-button-button-default);
  color: var(--token-accent-theme-1-text-text-default);
  font-weight: 500;
  font-family: var(--font-tertiary);
  width: 90px;
  height: 90px;
  padding: 13px 10px;
  display: inline-block;
  position: relative;
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
}

._5b6GcW_ThemedIconButton img {
  object-fit: contain;
  object-position: center center;
  width: 25px;
  height: 25px;
  margin: 5px auto 10px;
  position: relative;
}

._5b6GcW_ThemedIconButton span {
  font-size: 14px;
  position: relative;
}

._5b6GcW_ThemedIconButton:hover:before, ._5b6GcW_ThemedIconButton:focus:before {
  left: 0;
}

._5b6GcW_ThemedIconButton:before {
  content: "";
  background-color: var(--token-accent-theme-1-button-button-highlight);
  width: 100%;
  height: 100%;
  transition: left .3s ease-out;
  display: block;
  position: absolute;
  top: 0;
  left: -100%;
}

._84wVWW_buttonContainer {
  flex-direction: row;
  gap: 15px;
  margin: 5px;
  display: flex;
  transform: scale(1);
}

@media (width <= 500px) {
  ._84wVWW_buttonContainer {
    transform: scale(.8);
  }
}

.WYBeTW_fontSizeH1 {
  font-size: 55px;
}

.WYBeTW_fontSizeH2 {
  font-size: 44px;
}

.WYBeTW_fontSizeH3 {
  font-size: 35px;
}

.WYBeTW_fontSizeH4 {
  font-size: 28px;
}

.WYBeTW_fontSizeH5 {
  font-size: 22px;
}

.WYBeTW_fontSizeH6 {
  font-size: 18px;
}

.WYBeTW_fontSizep {
  font-size: 14px;
}

.WYBeTW_fontSizeSmall {
  font-size: 12px;
}

.WYBeTW_fontSizeTiny {
  font-size: 8px;
}

.WYBeTW_ThemedButton {
  background-color: var(--token-accent-theme-1-button-button-default);
  color: var(--token-accent-theme-1-text-text-default);
  font-weight: 500;
  font-family: var(--font-tertiary);
  width: 240px;
  height: 50px;
  padding: 13px 19px 19px;
  position: relative;
  overflow: hidden;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}

.WYBeTW_ThemedButton span {
  position: relative;
}

.WYBeTW_ThemedButton:hover:before, .WYBeTW_ThemedButton:focus:before {
  left: 0;
}

.WYBeTW_ThemedButton:before {
  content: "";
  background-color: var(--token-accent-theme-1-button-button-highlight);
  width: 100%;
  height: 100%;
  transition: left .3s ease-out;
  display: block;
  position: absolute;
  top: 0;
  left: -100%;
}

.ax7aha_fontSizeH1 {
  font-size: 55px;
}

.ax7aha_fontSizeH2 {
  font-size: 44px;
}

.ax7aha_fontSizeH3 {
  font-size: 35px;
}

.ax7aha_fontSizeH4 {
  font-size: 28px;
}

.ax7aha_fontSizeH5 {
  font-size: 22px;
}

.ax7aha_fontSizeH6 {
  font-size: 18px;
}

.ax7aha_fontSizep {
  font-size: 14px;
}

.ax7aha_fontSizeSmall {
  font-size: 12px;
}

.ax7aha_fontSizeTiny {
  font-size: 8px;
}

.ax7aha_Modal {
  width: 700px;
  max-width: calc(100% - 40px);
  height: 100vh;
}

.ax7aha_SupplyRunLeaderboardTable {
  font-size: 14px;
  position: relative;
}

@media (width <= 390px) {
  .ax7aha_SupplyRunLeaderboardTable {
    font-size: 12px;
  }

  .ax7aha_SupplyRunLeaderboardTable .ax7aha_isLong {
    font-size: 8px;
  }
}

.ax7aha_SupplyRunLeaderboardTable .ax7aha_empty {
  vertical-align: middle;
  width: 100%;
  margin: 20px 0;
  display: inline-block;
}

.ax7aha_SupplyRunLeaderboardTable .ax7aha_empty svg {
  opacity: .3;
  height: 80px;
  display: inline;
}

.ax7aha_SupplyRunLeaderboardTable.ax7aha_Today tr.ax7aha_first {
  background-color: var(--token-arise-theme-1-rarity-rarity-epic-dark);
}

.ax7aha_SupplyRunLeaderboardTable.ax7aha_AllTime tr.ax7aha_first {
  background-color: var(--token-arise-theme-1-rarity-rarity-uncommon-dark);
}

.ax7aha_SupplyRunLeaderboardTable .ax7aha_userStats {
  background-color: var(--token-accent-theme-1-interface-brand-interface-midground);
  border-radius: 10px;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  min-height: 132px;
  margin-bottom: 22px;
  padding: 5px;
  display: flex;
}

.ax7aha_SupplyRunLeaderboardTable .ax7aha_userStats .ax7aha_profile {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.ax7aha_SupplyRunLeaderboardTable .ax7aha_userStats .ax7aha_profile .ax7aha_avatar {
  width: 70px;
  height: 70px;
  margin: 10px;
  position: relative;
}

@media (width <= 390px) {
  .ax7aha_SupplyRunLeaderboardTable .ax7aha_userStats .ax7aha_profile .ax7aha_avatar {
    width: 60px;
    height: 60px;
  }
}

.ax7aha_SupplyRunLeaderboardTable .ax7aha_userStats .ax7aha_stats {
  flex-direction: row;
  align-items: center;
  gap: 20px;
  display: flex;
}

@media (width <= 390px) {
  .ax7aha_SupplyRunLeaderboardTable .ax7aha_userStats .ax7aha_stats svg {
    width: 30px;
  }
}

@media (width <= 576px) {
  .ax7aha_SupplyRunLeaderboardTable .ax7aha_userStats .ax7aha_stats {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
}

.ax7aha_SupplyRunLeaderboardTable .ax7aha_tabButtons {
  margin-top: 12px;
  margin-bottom: 22px;
}

.ax7aha_SupplyRunLeaderboardTable .ax7aha_tabButtons button {
  background-color: var(--token-accent-theme-1-button-button-secondary-inactive);
  color: var(--token-accent-theme-1-text-text-default);
  text-align: center;
  border-radius: 20px;
  width: 78px;
  padding: 2px 0;
  display: inline-block;
}

.ax7aha_SupplyRunLeaderboardTable .ax7aha_tabButtons button:first-child {
  margin-right: 10px;
}

.ax7aha_SupplyRunLeaderboardTable .ax7aha_tabButtons button:focus, .ax7aha_SupplyRunLeaderboardTable .ax7aha_tabButtons button:hover {
  filter: brightness(1.2);
}

.ax7aha_SupplyRunLeaderboardTable .ax7aha_tabButtons button.ax7aha_isCurrent {
  background-color: var(--token-accent-theme-1-button-button-secondary-active);
  color: var(--token-accent-theme-1-text-text-contrast-default);
}

.ax7aha_SupplyRunLeaderboardTable .ax7aha_tabButtons button.ax7aha_isCurrent:focus, .ax7aha_SupplyRunLeaderboardTable .ax7aha_tabButtons button.ax7aha_isCurrent:hover {
  filter: none;
}

.ax7aha_SupplyRunLeaderboardTable .ax7aha_user {
  gap: 20px;
  display: flex;
}

.ax7aha_SupplyRunLeaderboardTable .ax7aha_user .ax7aha_avatar {
  width: 40px;
  height: 40px;
  display: inline-block;
}

@media (width <= 576px) {
  .ax7aha_SupplyRunLeaderboardTable .ax7aha_user .ax7aha_avatar {
    width: 30px;
    height: 30px;
  }
}

.ax7aha_SupplyRunLeaderboardTable .ax7aha_positionTitle {
  padding-left: 8px;
}

.ax7aha_SupplyRunLeaderboardTable .ax7aha_position {
  padding-left: 10px;
  padding-right: 5px;
}

.ax7aha_SupplyRunLeaderboardTable .ax7aha_userTitle {
  padding-left: 5px;
}

@media (width <= 390px) {
  .ax7aha_SupplyRunLeaderboardTable .ax7aha_positionTitle {
    padding-left: 3px;
  }

  .ax7aha_SupplyRunLeaderboardTable .ax7aha_position {
    padding-left: 5px;
  }
}

.ax7aha_SupplyRunLeaderboardTable table {
  border-collapse: separate;
  border-spacing: 0 10px;
  width: 100%;
  margin-bottom: 20px;
}

.ax7aha_SupplyRunLeaderboardTable h3 {
  font-family: var(--font-primary);
  color: var(--token-accent-theme-1-text-text-default);
  font-size: 18px;
}

.ax7aha_SupplyRunLeaderboardTable td {
  padding: 2px;
}

.ax7aha_SupplyRunLeaderboardTable tr {
  background-color: var(--token-accent-theme-1-interface-brand-interface-midground);
}

.ax7aha_SupplyRunLeaderboardTable thead tr {
  background: none;
}

.ax7aha_SupplyRunLeaderboardTable th {
  opacity: .3;
  font-size: 12px;
  font-weight: 300;
}

.ax7aha_SupplyRunLeaderboardTable td, .ax7aha_SupplyRunLeaderboardTable th {
  text-align: left;
}

._6Iveqa_fontSizeH1 {
  font-size: 55px;
}

._6Iveqa_fontSizeH2 {
  font-size: 44px;
}

._6Iveqa_fontSizeH3 {
  font-size: 35px;
}

._6Iveqa_fontSizeH4 {
  font-size: 28px;
}

._6Iveqa_fontSizeH5 {
  font-size: 22px;
}

._6Iveqa_fontSizeH6 {
  font-size: 18px;
}

._6Iveqa_fontSizep {
  font-size: 14px;
}

._6Iveqa_fontSizeSmall {
  font-size: 12px;
}

._6Iveqa_fontSizeTiny {
  font-size: 8px;
}

._6Iveqa_NewsModal {
  height: auto;
}

._6Iveqa_NewsModal ._6Iveqa_content {
  padding: 40px;
}

@media (width <= 576px) {
  ._6Iveqa_NewsModal ._6Iveqa_content {
    padding: 20px;
  }
}

._6Iveqa_NewsModal img {
  width: 100%;
}

._2TGWPa_fontSizeH1 {
  font-size: 55px;
}

._2TGWPa_fontSizeH2 {
  font-size: 44px;
}

._2TGWPa_fontSizeH3 {
  font-size: 35px;
}

._2TGWPa_fontSizeH4 {
  font-size: 28px;
}

._2TGWPa_fontSizeH5 {
  font-size: 22px;
}

._2TGWPa_fontSizeH6 {
  font-size: 18px;
}

._2TGWPa_fontSizep {
  font-size: 14px;
}

._2TGWPa_fontSizeSmall {
  font-size: 12px;
}

._2TGWPa_fontSizeTiny {
  font-size: 8px;
}

._2TGWPa_GuestSignUpModal {
  height: auto;
  font-weight: 300;
}

._2TGWPa_GuestSignUpModal ._2TGWPa_joinButton {
  width: fit-content;
  min-width: 200px;
  margin-top: 20px;
  display: inline-block;
}

._2TGWPa_GuestSignUpModal img {
  min-width: 80px;
  max-width: 100%;
  padding: 10px 0;
  display: inline-block;
}

.xM81vW_fontSizeH1 {
  font-size: 55px;
}

.xM81vW_fontSizeH2 {
  font-size: 44px;
}

.xM81vW_fontSizeH3 {
  font-size: 35px;
}

.xM81vW_fontSizeH4 {
  font-size: 28px;
}

.xM81vW_fontSizeH5 {
  font-size: 22px;
}

.xM81vW_fontSizeH6 {
  font-size: 18px;
}

.xM81vW_fontSizep {
  font-size: 14px;
}

.xM81vW_fontSizeSmall {
  font-size: 12px;
}

.xM81vW_fontSizeTiny {
  font-size: 8px;
}

.xM81vW_MediaViewer {
  z-index: 1000;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.xM81vW_MediaViewer .xM81vW_overlay {
  background-color: #000c;
  position: fixed;
  inset: 0;
}

.xM81vW_MediaViewer .xM81vW_modalContent {
  background-color: #0000;
  background-color: var(--token-accent-theme-1-interface-brand-interface-background);
  border-radius: 10px;
  width: 100%;
  max-width: 90%;
  height: 100%;
  max-height: 90%;
  animation: .2s ease-out xM81vW_scale-up;
  position: relative;
  overflow: hidden;
}

@keyframes xM81vW_scale-up {
  0% {
    transform: scale(.5);
  }

  100% {
    transform: scale(1);
  }
}

@media (width <= 768px) {
  .xM81vW_MediaViewer .xM81vW_modalContent {
    max-width: 100%;
    max-height: 100%;
  }
}

.xM81vW_MediaViewer .xM81vW_closeButton {
  z-index: 1;
  position: absolute;
  top: 10px;
  right: 10px;
}

.xM81vW_MediaViewer .xM81vW_mediaContainer {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
}

.xM81vW_MediaViewer .xM81vW_mediaContainer .xM81vW_video {
  aspect-ratio: 16 / 9;
  width: auto;
  height: 100%;
}

@media (aspect-ratio <= 16 / 9) {
  .xM81vW_MediaViewer .xM81vW_mediaContainer .xM81vW_video {
    width: 100%;
    height: auto;
  }
}

.xM81vW_MediaViewer .xM81vW_mediaContainer .xM81vW_imageViewerImage {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.xM81vW_MediaViewer .xM81vW_mediaContainer .xM81vW_externalLinkImage {
  object-fit: contain;
  width: 100%;
  height: 100%;
  position: absolute;
}

.xM81vW_MediaViewer .xM81vW_mediaContainer .xM81vW_externalLinkButton {
  width: fit-content;
  margin: 10px;
  position: absolute;
  bottom: 0;
}

.xM81vW_transformWrapper, .xM81vW_transformContent {
  width: 100% !important;
  height: 100% !important;
}

.iF1WSW_fontSizeH1 {
  font-size: 55px;
}

.iF1WSW_fontSizeH2 {
  font-size: 44px;
}

.iF1WSW_fontSizeH3 {
  font-size: 35px;
}

.iF1WSW_fontSizeH4 {
  font-size: 28px;
}

.iF1WSW_fontSizeH5 {
  font-size: 22px;
}

.iF1WSW_fontSizeH6 {
  font-size: 18px;
}

.iF1WSW_fontSizep {
  font-size: 14px;
}

.iF1WSW_fontSizeSmall {
  font-size: 12px;
}

.iF1WSW_fontSizeTiny {
  font-size: 8px;
}

.iF1WSW_contentWrapper {
  transition: right .5s cubic-bezier(.5, .33, .33, 1);
  position: absolute;
  inset: 0;
}

.iF1WSW_contentWrapper--sidebarOpen {
  right: 520px;
}

@media (width <= 992px) {
  .iF1WSW_contentWrapper {
    right: 0;
  }
}

.iF1WSW_sidebarWrapper {
  z-index: 30;
  width: 520px;
  height: 100%;
  transition: right .5s cubic-bezier(.5, .33, .33, 1);
  position: absolute;
  top: 0;
  right: -520px;
}

@media (width <= 992px) {
  .iF1WSW_sidebarWrapper {
    width: 100%;
    right: -100%;
  }
}

.iF1WSW_sidebarWrapper--sidebarOpen {
  right: 0;
}

.TglI0W_fontSizeH1 {
  font-size: 55px;
}

.TglI0W_fontSizeH2 {
  font-size: 44px;
}

.TglI0W_fontSizeH3 {
  font-size: 35px;
}

.TglI0W_fontSizeH4 {
  font-size: 28px;
}

.TglI0W_fontSizeH5 {
  font-size: 22px;
}

.TglI0W_fontSizeH6 {
  font-size: 18px;
}

.TglI0W_fontSizep {
  font-size: 14px;
}

.TglI0W_fontSizeSmall {
  font-size: 12px;
}

.TglI0W_fontSizeTiny {
  font-size: 8px;
}

.TglI0W_AnimatedFullscreenText {
  z-index: 300;
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-black);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition-property: opacity;
  transition-duration: 1s;
  transition-timing-function: ease-in-out;
  display: flex;
  position: absolute;
}

.TglI0W_AnimatedFullscreenText.TglI0W_page-transition-preVisible {
  pointer-events: none;
  opacity: 0;
}

.TglI0W_AnimatedFullscreenText.TglI0W_page-transition-visible {
  opacity: 1;
}

.TglI0W_AnimatedFullscreenText.TglI0W_page-transition-postVisible {
  pointer-events: none;
  opacity: 0;
}

.TglI0W_AnimatedFullscreenText .TglI0W_title {
  color: var(--token-LEGACY-accent-theme-1-text-text-active);
  font-family: var(--font-primary);
  white-space: nowrap;
  font-size: 44px;
  transition-property: opacity, transform;
  transition-duration: .3s;
  position: absolute;
}

@media (width <= 992px) {
  .TglI0W_AnimatedFullscreenText .TglI0W_title {
    font-size: 35px;
  }
}

@media (width <= 768px) {
  .TglI0W_AnimatedFullscreenText .TglI0W_title.TglI0W_longTitle {
    font-size: 22px;
  }
}

.TglI0W_AnimatedFullscreenText .TglI0W_title.TglI0W_page-transition-preVisible {
  pointer-events: none;
  opacity: 0;
  transform: translateY(100px);
}

.TglI0W_AnimatedFullscreenText .TglI0W_title.TglI0W_page-transition-visible {
  opacity: 1;
  transform: translateY(0);
}

.TglI0W_AnimatedFullscreenText .TglI0W_title.TglI0W_page-transition-postVisible {
  pointer-events: none;
  opacity: 0;
  transform: translateY(-100px);
}

.TglI0W_AnimatedFullscreenText .TglI0W_title:first-child.TglI0W_page-transition-preVisible {
  opacity: 0;
  transition: opacity 2s linear;
}

.TglI0W_AnimatedFullscreenText .TglI0W_title:first-child.TglI0W_page-transition-visible {
  opacity: 1;
  transition: opacity 2s linear;
}

.TglI0W_AnimatedFullscreenText .TglI0W_title:first-child.TglI0W_page-transition-postVisible {
  transition-duration: .3s;
}

.TglI0W_AnimatedFullscreenText .TglI0W_title:first-child span {
  white-space: pre;
  animation: 1.5s forwards TglI0W_moveUp;
  display: inline-block;
}

.TglI0W_AnimatedFullscreenText .TglI0W_highlighted {
  color: var(--token-LEGACY-accent-theme-1-brand-colour);
}

@keyframes TglI0W_moveUp {
  0% {
    transform: translateY(150px);
  }

  100% {
    transform: translateY(0);
  }
}

.zCW8cW_fontSizeH1 {
  font-size: 55px;
}

.zCW8cW_fontSizeH2 {
  font-size: 44px;
}

.zCW8cW_fontSizeH3 {
  font-size: 35px;
}

.zCW8cW_fontSizeH4 {
  font-size: 28px;
}

.zCW8cW_fontSizeH5 {
  font-size: 22px;
}

.zCW8cW_fontSizeH6 {
  font-size: 18px;
}

.zCW8cW_fontSizep {
  font-size: 14px;
}

.zCW8cW_fontSizeSmall {
  font-size: 12px;
}

.zCW8cW_fontSizeTiny {
  font-size: 8px;
}

.zCW8cW_SceneSelectorTile {
  width: 100%;
  position: relative;
}

.zCW8cW_SceneSelectorTile__anchor {
  cursor: pointer;
  width: 50%;
  height: 230px;
  margin-bottom: 45px;
  margin-left: auto;
  margin-right: auto;
  transition: width 2.5s cubic-bezier(.36, 0, 0, 1);
  display: block;
  position: relative;
}

.zCW8cW_SceneSelectorTile__anchor:hover .zCW8cW_SceneSelectorTile__image {
  transform: scale(1.2);
}

.zCW8cW_SceneSelectorTile__anchor:hover .zCW8cW_SceneSelectorTile__customBorder {
  stroke: var(--token-LEGACY-accent-theme-1-interface-interface-white);
}

.zCW8cW_SceneSelectorTile__anchor:hover .zCW8cW_SceneSelectorTile__playButton {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-active);
}

.zCW8cW_SceneSelectorTile__anchor:hover .zCW8cW_SceneSelectorTile__playButton svg path {
  fill: var(--token-accent-theme-1-button-button-highlight);
}

.zCW8cW_SceneSelectorTile--locked .zCW8cW_SceneSelectorTile__customBorder {
  stroke: var(--token-accent-theme-1-border-border-default);
}

.zCW8cW_SceneSelectorTile--locked .zCW8cW_SceneSelectorTile__anchor:hover .zCW8cW_SceneSelectorTile__customBorder {
  stroke: var(--token-accent-theme-1-border-border-highlight);
}

.zCW8cW_SceneSelectorTile--inCenter.zCW8cW_SceneSelectorTile--locked .zCW8cW_SceneSelectorTile__anchor {
  cursor: auto;
}

.zCW8cW_SceneSelectorTile--inCenter .zCW8cW_SceneSelectorTile__anchor {
  width: 100%;
}

.zCW8cW_SceneSelectorTile--inCenter .zCW8cW_SceneSelectorTile__textContainer {
  opacity: 1;
}

.zCW8cW_SceneSelectorTile--inCenter .zCW8cW_SceneSelectorTile__imageContainer {
  filter: brightness();
}

.zCW8cW_SceneSelectorTile__customBorder {
  stroke: var(--token-LEGACY-accent-theme-1-interface-interface-grey);
  width: calc(100% + 3px);
  height: calc(100% + 3px);
  transition: stroke .5s cubic-bezier(.5, .33, .33, 1);
  display: block;
  position: absolute;
  top: -1px;
}

.zCW8cW_SceneSelectorTile__imageContainer {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-black);
  filter: brightness(.5);
  width: 100%;
  height: 100%;
  transition: filter 2.5s cubic-bezier(.36, 0, 0, 1);
  overflow: hidden;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}

.zCW8cW_SceneSelectorTile__image {
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  transition: transform .5s cubic-bezier(.5, .33, .33, 1);
}

.zCW8cW_SceneSelectorTile__textContainer {
  text-align: center;
  font-family: var(--font-primary);
  color: var(--token-accent-theme-1-text-text-default);
  opacity: 0;
  margin-top: 20px;
  font-size: 18px;
  transition: opacity 2.5s cubic-bezier(.36, 0, 0, 1);
}

.zCW8cW_SceneSelectorTile__lockedIcon {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.zCW8cW_SceneSelectorTile__lockedIcon path {
  fill: var(--token-LEGACY-accent-theme-1-brand-colour);
}

.zCW8cW_SceneSelectorTile__lockedSubtext {
  color: var(--token-LEGACY-accent-theme-1-brand-colour);
}

.zCW8cW_SceneSelectorTile__playButton {
  margin-top: -37px;
  transition: background-color .5s cubic-bezier(.5, .33, .33, 1);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.zCW8cW_SceneSelectorTile__playButton svg path {
  transition: fill .5s cubic-bezier(.5, .33, .33, 1);
}

@media (width <= 992px) {
  .zCW8cW_SceneSelectorTile__anchor {
    height: 214px;
  }

  .zCW8cW_SceneSelectorTile__textContainer {
    font-size: 14px;
  }
}

@media (width <= 390px) {
  .zCW8cW_SceneSelectorTile__anchor {
    height: 190px;
  }
}

.kAzX_W_fontSizeH1 {
  font-size: 55px;
}

.kAzX_W_fontSizeH2 {
  font-size: 44px;
}

.kAzX_W_fontSizeH3 {
  font-size: 35px;
}

.kAzX_W_fontSizeH4 {
  font-size: 28px;
}

.kAzX_W_fontSizeH5 {
  font-size: 22px;
}

.kAzX_W_fontSizeH6 {
  font-size: 18px;
}

.kAzX_W_fontSizep {
  font-size: 14px;
}

.kAzX_W_fontSizeSmall {
  font-size: 12px;
}

.kAzX_W_fontSizeTiny {
  font-size: 8px;
}

.kAzX_W_SceneSelector {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-black);
  width: 100%;
  min-width: 320px;
  height: 100%;
  position: absolute;
}

.kAzX_W_SceneSelector__parallaxContainer {
  -webkit-user-select: none;
  user-select: none;
  user-drag: none;
  pointer-events: none;
  margin-bottom: 50%;
  position: relative;
  overflow: hidden;
}

@media (aspect-ratio <= 25 / 14) {
  .kAzX_W_SceneSelector__parallaxContainer {
    height: 50%;
  }
}

.kAzX_W_SceneSelector__parallax {
  aspect-ratio: 25 / 7;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
}

.kAzX_W_SceneSelector .kAzX_W_StoryText {
  text-transform: uppercase;
  text-align-last: right;
  height: 100%;
  font-style: italic;
  transition: left 2.5s cubic-bezier(.36, 0, 0, 1), top 1s cubic-bezier(.36, 0, 0, 1), opacity 1s cubic-bezier(.36, 0, 0, 1);
  position: absolute;
  top: 0;
  left: 0;
}

.kAzX_W_SceneSelector .kAzX_W_StoryText__Item {
  justify-content: center;
  height: 100%;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.kAzX_W_SceneSelector .kAzX_W_StoryText__Item p {
  background-color: var(--token-LEGACY-accent-theme-1-text-text-active);
  color: var(--token-LEGACY-accent-theme-1-interface-interface-black);
  font-family: var(--font-primary);
  border: 6px solid #0000;
  border-width: 6px 10px;
  border-image-slice: 15;
  border-image-width: repeat;
  border-image-outset: repeat;
  background-position: center;
  background-size: 200% 200%;
  background-clip: padding-box;
  max-width: min(100% - 40px, 500px);
  margin: 0 20px;
  padding: 5px 20px;
  font-size: 18px;
  line-height: 23px;
  display: block;
  position: absolute;
  top: 15px;
}

@media (width <= 576px) {
  .kAzX_W_SceneSelector .kAzX_W_StoryText__Item p {
    padding: 7px 12px;
    font-size: 14px;
    line-height: 16px;
  }
}

@media (width <= 768px) {
  .kAzX_W_SceneSelector .kAzX_W_StoryText__Item p {
    margin-left: 75px;
  }
}

.kAzX_W_SceneSelector .kAzX_W_parallaxLayer {
  max-width: initial;
  height: 100%;
  transition: left 2.5s cubic-bezier(.36, 0, 0, 1), top 1s cubic-bezier(.36, 0, 0, 1);
  position: absolute;
  left: 0;
  transform: scaleX(1.01);
}

.kAzX_W_SceneSelector .kAzX_W_parallaxLayerFrontCover {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-black);
  width: 100%;
  height: 100%;
  transition: top 1s cubic-bezier(.36, 0, 0, 1);
  position: absolute;
  left: 0;
}

.kAzX_W_SceneSelector .kAzX_W_parallaxLayer-bg {
  animation: 4s ease-in-out infinite alternate kAzX_W_sway-animation-bg;
}

@keyframes kAzX_W_sway-animation-bg {
  0% {
    transform: translateX(0)scaleX(1.01);
  }

  100% {
    transform: translateX(.3%)scaleX(1.01);
  }
}

.kAzX_W_SceneSelector .kAzX_W_parallaxLayer-mg {
  animation: 4s ease-in-out infinite alternate kAzX_W_sway-animation-mg;
}

@keyframes kAzX_W_sway-animation-mg {
  0% {
    transform: translateX(0)scaleX(1.01);
  }

  100% {
    transform: translateX(.5%)scaleX(1.01);
  }
}

.kAzX_W_SceneSelector .kAzX_W_parallaxLayer-fg {
  animation: 4s ease-in-out infinite alternate kAzX_W_sway-animation-fg;
}

@keyframes kAzX_W_sway-animation-fg {
  0% {
    transform: translateX(0)scaleX(1.01);
  }

  100% {
    transform: translateX(1%)scaleX(1.01);
  }
}

.kAzX_W_SceneSelector.kAzX_W_scene-0 ul {
  left: 0;
}

@media (width <= 992px) {
  .kAzX_W_SceneSelector.kAzX_W_scene-0 ul {
    left: 0;
  }
}

@media (width <= 390px) {
  .kAzX_W_SceneSelector.kAzX_W_scene-0 ul {
    left: 0;
  }
}

.kAzX_W_SceneSelector.kAzX_W_scene-1 ul {
  left: calc(-50% - 35px);
}

@media (width <= 992px) {
  .kAzX_W_SceneSelector.kAzX_W_scene-1 ul {
    left: calc(-50% - 33px);
  }
}

@media (width <= 390px) {
  .kAzX_W_SceneSelector.kAzX_W_scene-1 ul {
    left: calc(-50% - 20px);
  }
}

.kAzX_W_SceneSelector.kAzX_W_scene-2 ul {
  left: calc(-100% - 70px);
}

@media (width <= 992px) {
  .kAzX_W_SceneSelector.kAzX_W_scene-2 ul {
    left: calc(-100% - 66px);
  }
}

@media (width <= 390px) {
  .kAzX_W_SceneSelector.kAzX_W_scene-2 ul {
    left: calc(-100% - 40px);
  }
}

.kAzX_W_SceneSelector.kAzX_W_scene-3 ul {
  left: calc(-150% - 105px);
}

@media (width <= 992px) {
  .kAzX_W_SceneSelector.kAzX_W_scene-3 ul {
    left: calc(-150% - 99px);
  }
}

@media (width <= 390px) {
  .kAzX_W_SceneSelector.kAzX_W_scene-3 ul {
    left: calc(-150% - 60px);
  }
}

.kAzX_W_SceneSelector.kAzX_W_scene-4 ul {
  left: calc(-200% - 140px);
}

@media (width <= 992px) {
  .kAzX_W_SceneSelector.kAzX_W_scene-4 ul {
    left: calc(-200% - 132px);
  }
}

@media (width <= 390px) {
  .kAzX_W_SceneSelector.kAzX_W_scene-4 ul {
    left: calc(-200% - 80px);
  }
}

.kAzX_W_SceneSelector.kAzX_W_scene-5 ul {
  left: calc(-250% - 175px);
}

@media (width <= 992px) {
  .kAzX_W_SceneSelector.kAzX_W_scene-5 ul {
    left: calc(-250% - 165px);
  }
}

@media (width <= 390px) {
  .kAzX_W_SceneSelector.kAzX_W_scene-5 ul {
    left: calc(-250% - 100px);
  }
}

.kAzX_W_SceneSelector.kAzX_W_scene-6 ul {
  left: calc(-300% - 210px);
}

@media (width <= 992px) {
  .kAzX_W_SceneSelector.kAzX_W_scene-6 ul {
    left: calc(-300% - 198px);
  }
}

@media (width <= 390px) {
  .kAzX_W_SceneSelector.kAzX_W_scene-6 ul {
    left: calc(-300% - 120px);
  }
}

.kAzX_W_SceneSelector.kAzX_W_scene-7 ul {
  left: calc(-350% - 245px);
}

@media (width <= 992px) {
  .kAzX_W_SceneSelector.kAzX_W_scene-7 ul {
    left: calc(-350% - 231px);
  }
}

@media (width <= 390px) {
  .kAzX_W_SceneSelector.kAzX_W_scene-7 ul {
    left: calc(-350% - 140px);
  }
}

.kAzX_W_SceneSelector.kAzX_W_scene-8 ul {
  left: calc(-400% - 280px);
}

@media (width <= 992px) {
  .kAzX_W_SceneSelector.kAzX_W_scene-8 ul {
    left: calc(-400% - 264px);
  }
}

@media (width <= 390px) {
  .kAzX_W_SceneSelector.kAzX_W_scene-8 ul {
    left: calc(-400% - 160px);
  }
}

.kAzX_W_SceneSelector.kAzX_W_scene-9 ul {
  left: calc(-450% - 315px);
}

@media (width <= 992px) {
  .kAzX_W_SceneSelector.kAzX_W_scene-9 ul {
    left: calc(-450% - 297px);
  }
}

@media (width <= 390px) {
  .kAzX_W_SceneSelector.kAzX_W_scene-9 ul {
    left: calc(-450% - 180px);
  }
}

.kAzX_W_SceneSelector.kAzX_W_scene-10 ul {
  left: calc(-500% - 350px);
}

@media (width <= 992px) {
  .kAzX_W_SceneSelector.kAzX_W_scene-10 ul {
    left: calc(-500% - 330px);
  }
}

@media (width <= 390px) {
  .kAzX_W_SceneSelector.kAzX_W_scene-10 ul {
    left: calc(-500% - 200px);
  }
}

.kAzX_W_SceneSelector__scenes {
  background: linear-gradient(#0000 0%, #000 50% 100%);
  align-items: center;
  width: 100%;
  height: 450px;
  transition: opacity 1s linear;
  display: flex;
  position: absolute;
  bottom: 0;
}

@media (width <= 576px) {
  .kAzX_W_SceneSelector__scenes {
    height: auto;
    margin-bottom: 10px;
  }
}

.kAzX_W_SceneSelector ul {
  white-space: nowrap;
  width: 100%;
  transition: left 1s ease-in-out;
  position: relative;
}

.kAzX_W_SceneSelector__sceneContainer {
  white-space: normal;
  vertical-align: top;
  width: 470px;
  margin-left: calc(50% - 435px);
  display: inline-block;
  position: relative;
}

.kAzX_W_SceneSelector__sceneContainer:first-child {
  margin-left: calc(50% - 235px);
}

.kAzX_W_SceneSelector__sceneContainer:last-child {
  margin-right: calc(50% - 235px);
}

@media (width <= 992px) {
  .kAzX_W_SceneSelector__sceneContainer {
    width: 286px;
    margin-left: calc(50% - 253px);
  }

  .kAzX_W_SceneSelector__sceneContainer:first-child {
    margin-left: calc(50% - 143px);
  }

  .kAzX_W_SceneSelector__sceneContainer:last-child {
    margin-right: calc(50% - 143px);
  }
}

@media (width <= 390px) {
  .kAzX_W_SceneSelector__sceneContainer {
    width: 200px;
    margin-left: calc(50% - 180px);
  }

  .kAzX_W_SceneSelector__sceneContainer:first-child {
    margin-left: calc(50% - 100px);
  }

  .kAzX_W_SceneSelector__sceneContainer:last-child {
    margin-right: calc(50% - 100px);
  }
}

.kAzX_W_SceneSelector.kAzX_W_page-transition-preVisible .kAzX_W_parallaxLayer {
  opacity: 0;
}

.kAzX_W_SceneSelector.kAzX_W_page-transition-preVisible .kAzX_W_StoryText {
  opacity: 0;
  top: -200px;
}

.kAzX_W_SceneSelector.kAzX_W_page-transition-preVisible .kAzX_W_parallaxLayerFrontCover, .kAzX_W_SceneSelector.kAzX_W_page-transition-postVisible .kAzX_W_parallaxLayerFrontCover {
  top: 0%;
}

.kAzX_W_SceneSelector.kAzX_W_page-transition-preVisible .kAzX_W_parallaxLayer-bg, .kAzX_W_SceneSelector.kAzX_W_page-transition-postVisible .kAzX_W_parallaxLayer-bg {
  top: -10%;
}

.kAzX_W_SceneSelector.kAzX_W_page-transition-preVisible .kAzX_W_parallaxLayer-mg, .kAzX_W_SceneSelector.kAzX_W_page-transition-postVisible .kAzX_W_parallaxLayer-mg {
  top: -20%;
}

.kAzX_W_SceneSelector.kAzX_W_page-transition-preVisible .kAzX_W_parallaxLayer-fg, .kAzX_W_SceneSelector.kAzX_W_page-transition-postVisible .kAzX_W_parallaxLayer-fg {
  top: -50%;
}

.kAzX_W_SceneSelector.kAzX_W_page-transition-visible .kAzX_W_parallaxLayerFrontCover {
  top: 100%;
}

.kAzX_W_SceneSelector.kAzX_W_page-transition-visible .kAzX_W_parallaxLayer {
  opacity: 1;
  top: 0%;
}

.kAzX_W_SceneSelector.kAzX_W_page-transition-postVisible .kAzX_W_SceneSelector__scenes {
  opacity: 0;
}

.kAzX_W_SceneSelector.kAzX_W_page-transition-postVisible .kAzX_W_StoryText {
  opacity: 0;
  top: -200px;
}

.EgaQbW_fontSizeH1 {
  font-size: 55px;
}

.EgaQbW_fontSizeH2 {
  font-size: 44px;
}

.EgaQbW_fontSizeH3 {
  font-size: 35px;
}

.EgaQbW_fontSizeH4 {
  font-size: 28px;
}

.EgaQbW_fontSizeH5 {
  font-size: 22px;
}

.EgaQbW_fontSizeH6 {
  font-size: 18px;
}

.EgaQbW_fontSizep {
  font-size: 14px;
}

.EgaQbW_fontSizeSmall {
  font-size: 12px;
}

.EgaQbW_fontSizeTiny {
  font-size: 8px;
}

.EgaQbW_LandingPage {
  color: var(--token-system-text-default);
  background-color: #000;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  font-family: Inter;
  font-size: 14px;
  position: absolute;
  inset: 0;
  overflow-y: auto;
}

.EgaQbW_LandingPage::-webkit-scrollbar {
  width: 15px;
}

.EgaQbW_LandingPage::-webkit-scrollbar-thumb {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-foreground);
  background-clip: content-box;
  border: 3px solid #0000;
  border-radius: 8px;
}

.EgaQbW_LandingPage::-webkit-scrollbar-track {
  background-color: var(--token-LEGACY-accent-theme-1-interface-interface-background);
  background-clip: content-box;
  border-right: 1px solid #0000;
  border-radius: 10px;
}

.EgaQbW_LandingPage::-webkit-scrollbar {
  background-color: #000;
}

.EgaQbW_LandingPage .EgaQbW_container {
  background: linear-gradient(0deg, #000 10%, #0000 100%);
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  min-height: 100%;
  display: flex;
}

.EgaQbW_LandingPage .EgaQbW_content {
  text-align: center;
  width: 364px;
  max-width: 100%;
  margin: 0 auto;
  padding: 20px 20px 5vh;
}

.EgaQbW_LandingPage .EgaQbW_landingText {
  margin-bottom: 20px;
}

.EgaQbW_LandingPage .EgaQbW_landingText h2 {
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: bold;
}

.EgaQbW_LandingPage .EgaQbW_logo {
  width: calc(100% - 40px);
  max-width: 700px;
  padding: 10px;
}

.EgaQbW_LandingPage .EgaQbW_formContainer {
  margin: 2px;
  padding-top: 10px;
}

.EgaQbW_LandingPage .EgaQbW_dividerLine {
  border-top: 1px solid #ffffff4d;
}
/*# sourceMappingURL=index.06ad216e.css.map */
