@import '../../style/globals.scss';

.MyPrizesDrawer {
    position: absolute;
    top: 0;
    right: -$sidebar-width;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    color: var(--token-accent-theme-1-text-text-default);
    // Needed for safari
    -webkit-backdrop-filter: blur(10px);
    transition: $sidebar-transition, opacity 0s linear 0.5s;
    opacity: 0;
    z-index: 10;

    @media (max-width: $bp-mobile-chat) {
        right: -100%;
        width: 100%;
    }

    &.isOpen {
        right: 0;
        transition: $sidebar-transition, opacity 0s linear 0s;
        opacity: 1;
    }

    .scrollContainer {
        @include scrollbar-styles;
        position: relative;
        overflow-y: scroll;
        width: 100%;
        height: 100%;
    }

    h2 {
        font-family: var(--font-primary);
        font-size: $font-size-h6;
        text-align: center;
    }

    .topBar {
        position: absolute;
        width: 100%;
        height: 80px;

        .title {
            position: absolute;
            top: 25px;
            left: 50%;
            text-align: center;
            transform: translateX(-50%);
        }

        .closeButton {
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }

    .content {
        @include border-image();
        position: absolute;
        top: 70px;
        left: 50%;
        width: 100%;
        max-width: 430px;
        min-height: 500px;
        padding: 20px;
        transform: translateX(-50%);

        &.noBackgroundImage {
            background-color: var(--token-accent-theme-1-interface-mono-interface-midground);
            border-radius: 10px;
        }

        @media (max-width: $bp-mobile-chat) {
            max-width: 500px;
        }

        .title {
            margin: 10px;
        }

        .emptyText {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%);
            text-align: center;
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            width: 100%;
        }

        li {
            display: block;
            width: calc(50% - 10px);
            height: 100%;
        }

        img {
            width: 100%;
            height: auto;
            max-width: calc(100% - 35px);
            max-height: calc(100% - 35px);
        }
    }
}
