@import '../../../style/globals.scss';

.LandingPage {
    position: absolute;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    background-color: black;
    background-position: center;
    background-size: cover;
    color: var(--token-system-text-default);
    font-family: Inter;
    font-size: $font-size-p;
    inset: 0;

    @include scrollbar-styles;
    &::-webkit-scrollbar {
        background-color: black;
    }

    .container {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        min-height: 100%;
        background: linear-gradient(0deg, black 10%, transparent 100%);
    }

    .content {
        width: 364px;
        max-width: 100%;
        padding: 20px;
        padding-bottom: 5vh;
        margin: 0 auto;
        text-align: center;
    }

    .landingText {
        margin-bottom: 20px;

        h2 {
            margin-bottom: 20px;
            font-size: $font-size-h5;
            font-weight: bold;
        }
    }

    .logo {
        width: calc(100% - 40px);
        max-width: 700px;
        padding: 10px;
    }

    .formContainer {
        margin: 2px;
        padding-top: 10px;
    }

    .dividerLine {
        border-top: 1px solid rgba(255, 255, 255, 0.3);
    }
}
