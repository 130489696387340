@import '../../style/globals.scss';

.MediaViewer {
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.8);
    }

    .modalContent {
        position: relative;
        overflow: hidden;
        width: 100%;
        max-width: 90%;
        height: 100%;
        max-height: 90%;
        background-color: transparent;
        border-radius: 10px;
        background-color: var(--token-accent-theme-1-interface-brand-interface-background);
        animation: scale-up 200ms ease-out;

        @keyframes scale-up {
            0% {
                transform: scale(0.5);
            }
            100% {
                transform: scale(1);
            }
        }

        @media (max-width: $bp-s) {
            max-width: 100%;
            max-height: 100%;
        }
    }

    .closeButton {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 1;
    }

    .mediaContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        width: 100%;
        height: 100%;

        .video {
            height: 100%;
            width: auto;
            aspect-ratio: 16 / 9;

            @media (max-aspect-ratio: 16 / 9) {
                width: 100%;
                height: auto;
            }
        }

        .imageViewerImage {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }

        .externalLinkImage {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        .externalLinkButton {
            position: absolute;
            bottom: 0;
            width: fit-content;
            margin: 10px;
        }
    }
}

.transformWrapper,
.transformContent {
    height: 100% !important;
    width: 100% !important;
}
