@tailwind base;
@tailwind components;
@tailwind utilities;

@import '../style/typography.scss';
@import '../style/preloading.scss';

body,
html {
    background-color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: var(--font-secondary);

    position: relative;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

}
