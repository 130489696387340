$rarities: 'common', 'uncommon', 'rare', 'epic', 'legendary';

$padding-left: 50px;
$header-height: 70px;
$line-style: 1px solid rgba(255, 255, 255, 0.2);

$sidebar-width: 520px;

$transition-duration: 600ms;

$font_arise_primary: Inter, sans-serif;

$animation-bounce: cubic-bezier(0.5, 0.33, 0.33, 1);
$animation-parallax: cubic-bezier(0.36, 0, 0, 1);
$animation-overshoot: cubic-bezier(0, 1.67, 0.75, 0.99);

$sidebar-transition: right 0.5s $animation-bounce;
$sidebar-border-radius-mobile: 30px 30px 0px 0px;

/* Breakpoint Sizes */
$bp-xxs: 390px;
$bp-xs: 576px;
$bp-s: 768px;
$bp-m: 992px;
$bp-l: 1200px;
$bp-xl: 1400px;

$bp-mobile-chat: $bp-m; // Also referenced in globals.ts

$chat-padding-xs: 15px;
$chat-padding-s: 25px;
$chat-padding-m: 38px;

/* Transition time */
// Also referenced in pageTransition.ts
$page-transition-time: 1000ms;

@mixin scrollbar-styles {
    &::-webkit-scrollbar {
        width: 15px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--token-LEGACY-accent-theme-1-interface-interface-foreground);
        border-radius: 8px;
        background-clip: content-box;
        border: 3px solid transparent;
    }

    &::-webkit-scrollbar-track {
        background-color: var(--token-LEGACY-accent-theme-1-interface-interface-background);
        border-radius: 10px;
        background-clip: content-box;
        border-right: 1px solid transparent;
    }
}

// Font sizes
$font-size-h1: 55px;
$font-size-h2: 44px;
$font-size-h3: 35px;
$font-size-h4: 28px;
$font-size-h5: 22px;
$font-size-h6: 18px;
$font-size-p: 14px;
$font-size-small: 12px;
$font-size-tiny: 8px;

.fontSizeH1 {
    font-size: $font-size-h1;
}
.fontSizeH2 {
    font-size: $font-size-h2;
}
.fontSizeH3 {
    font-size: $font-size-h3;
}
.fontSizeH4 {
    font-size: $font-size-h4;
}
.fontSizeH5 {
    font-size: $font-size-h5;
}
.fontSizeH6 {
    font-size: $font-size-h6;
}
.fontSizep {
    font-size: $font-size-p;
}
.fontSizeSmall {
    font-size: $font-size-small;
}
.fontSizeTiny {
    font-size: $font-size-tiny;
}

@mixin border-image {
    // Allows background image to be used without stretching out edges
    // Must have borderImageSource and backgroundImage also set
    background-position: center;
    background-size: 200% 200%;
    border-image-slice: 60;
    border-image-width: 30px;
    border-image-outset: 5px;
    border-image-repeat: stretch;
    border-style: solid;
}
