@import '../../../style/globals.scss';

.ShopExternalItem {
    position: relative;
    width: 100%;
    padding: 30px;
    background-size: 100% 100%;

    &.noBackgroundImage {
        background-color: var(--token-accent-theme-1-interface-brand-interface-background);
        border-radius: 10px;
    }

    .label {
        display: inline-block;
        width: auto;
        min-width: 170px;
        height: auto;
        margin-bottom: 10px;
    }

    .price {
        display: inline-block;
        width: 100%;
        font-family: var(--font-secondary);
    }

    .image {
        min-width: 200px;
        max-width: 100%;
        min-height: 200px;
        max-height: 400px;
        margin: 10px auto;
        background-color: var(--token-accent-theme-1-interface-brand-interface-midground);
        object-fit: contain;
    }

    .interactionContainer {
        display: flex;
        justify-content: flex-end;

        .button {
            display: inline-block;
            width: auto;
            font-family: var(--font-secondary);

            @media (max-width: $bp-xxs) {
                width: 100%;
            }
        }
    }
}
