@import '../../style/globals.scss';

.GuestBanner {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    padding: 0 8px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    background-color: var(--token-accent-theme-1-interface-mono-interface-background);
    color: var(--token-accent-theme-1-text-text-default);
    font-size: $font-size-p;

    .logo {
        height: 28px;
    }

    .buttons {
        margin-left: 11px;
        white-space: nowrap;
    }

    span {
        overflow: hidden;
        margin-right: auto;
        margin-left: 8px;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .loginButton {
        position: relative;
        display: inline-block;
        width: 82px;
        height: 33px;
        padding: 0;
        background-color: var(--token-accent-theme-1-brand-palette-brand-colour);

        &:hover {
            filter: brightness(1.5);
        }

        &:first-child {
            margin-right: 8px;
            background-color: var(--token-accent-theme-1-interface-mono-interface-midground);
        }
    }

    &.thoseBeyondThemed {
        background-color: var(--token-system-interface-background);
        color: var(--token-system-text-default);

        .loginButton {
            background-color: var(--token-system-brand);

            &:first-child {
                background-color: var(--token-system-brand-mono);
            }
        }
    }
}
