@import '../../style/globals.scss';

.Badge {
    display: inline-flex;
    align-items: center;
    height: 21px;
    padding: 0px 8px;
    font-size: $font-size-p;
    font-weight: 500;
    border-radius: 30px;

    // Default is common
    color: var(--token-arise-theme-1-rarity-rarity-common-light);
    background-color: var(--token-arise-theme-1-rarity-rarity-common-mid);

    @each $rarity in $rarities {
        &.#{$rarity} {
            color: var(--token-arise-theme-1-rarity-rarity-#{$rarity}-light);
            background-color: var(--token-arise-theme-1-rarity-rarity-#{$rarity}-mid);

            .icon {
                background-color: var(--token-arise-theme-1-rarity-rarity-#{$rarity}-light);
            }
        }
    }

    &.system {
        color: var(--token-LEGACY-accent-theme-1-interface-interface-white);
        background-color: var(--token-LEGACY-accent-theme-1-interface-interface-foreground);
    }

    .icon {
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-left: 5px;
        background-color: var(--token-arise-theme-1-rarity-rarity-common-light);
        mask-size: 100%;
        vertical-align: text-bottom;
    }

    p {
        display: inline-block;
    }
}
