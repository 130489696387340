@import '../../style/globals.scss';

.Settings {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 50;
    transition-property: transform;
    transition-duration: 0.4s;
    transition-timing-function: ease-in;
    transform: translate(-100%, 0);
    @media screen and (max-width: $bp-xs) {
        transform: translate(-150%, 0);
    }
}

.closeButton {
    position: absolute;
    left: 0;
    margin: 17px;
}

.backdropBlur {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 50;
    backdrop-filter: blur(0px);
    background-color: rgba(0, 0, 0, 0);
    visibility: hidden;
    // Parcel (or a downstream tool) will automatically convert backdrop-filter to -webkit-backdrop-filter, backdrop-filter (even if it's already mentioned), hence we have four duration,delay values and only three properties.
    transition-property: visibility, background-color, backdrop-filter;
    transition-duration: 0ms, 400ms, 400ms, 400ms;
    transition-delay: 450ms, 0ms, 0ms, 0ms;
}

.header {
    position: absolute;
    top: 0;
    left: $header-height;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: $header-height;
}

.content {
    position: absolute;
    top: $header-height;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 727px;
    max-width: 100%;
    height: calc(100% - 70px);
    color: var(--token-accent-theme-1-text-text-default);
    font-size: $font-size-h6;
    font-weight: 500;
    background-position: bottom left;
    background-size: 50%;
    background-repeat: no-repeat;

    &:focus {
        outline: none;
    }

    .top {
        width: 400px;
        max-width: 100%;
        padding: 40px;

        @media screen and (max-width: $bp-s) {
            width: 300px;
            padding: 20px;
            font-size: $font-size-p;
        }
    }

    .middle {
        width: 550px;
        max-width: 100%;
        padding: 0px 40px;

        p {
            font-size: $font-size-h6 !important;
        }

        @media screen and (max-width: $bp-s) {
            width: 400px;
            padding: 0px 20px;

            p {
                font-size: $font-size-p !important;
            }
        }
    }

    .bottom {
        bottom: 0;
        left: 0;
        padding: 40px;

        @media screen and (max-width: $bp-s) {
            padding: 20px;
        }

        .logoutButton {
            width: 245px;
            margin-bottom: 20px;
        }

        .logos {
            align-items: center;
            display: flex;
            width: fit-content;
            max-width: 450px;

            .logoContainer {
                flex: 1;
                text-align: left;

                img {
                    max-height: 50px;
                    display: inline-block;
                }
            }

            a.logoContainer {
                &:hover,
                &:focus {
                    filter: brightness(1.1);
                }
            }
        }

        .footerText {
            margin-top: 20px;
            font-size: $font-size-small;
            opacity: 0.4;
            font-family: Inter;

            @media screen and (max-width: $bp-s) {
                font-size: $font-size-tiny;
            }
        }
    }
}

.secondaryLogo {
    width: 130px;
    margin-left: 30px;
}

.Settings.visible {
    transform: translate(0%, 0);
    transition-timing-function: ease-out;
}

.backdropBlur.visible {
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.3);
    visibility: visible;
    transition-delay: 0ms, 0ms, 0ms, 0ms;
}
