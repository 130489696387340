@import '../../../style/globals.scss';

.ProfileStatsSummary {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    min-height: 176px;
    padding: 20px;
    background-size: 100% 100%;
    background-repeat: no-repeat;

    &.noBackgroundImage {
        background-color: var(--token-accent-theme-1-interface-mono-interface-background);
        border-radius: 10px;
    }

    @media (max-width: 440px) {
        flex-direction: column;
        padding: 10px;

        .logo {
            display: none;
        }

        .summaryRow {
            gap: 35px;
        }
    }

    .logo {
        width: 100%;
        min-width: 60px;
        max-width: 50%;
        height: 136px;
        margin: 20px 20px 20px 0;
        background-color: var(--token-accent-theme-1-brand-palette-brand-accent-1);
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
    }

    .summaryItems {
        display: flex;
        flex: none;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        gap: 10px;

        .summaryRow {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            gap: 10px;
        }

        li {
            display: inline-block;
            flex: none;
        }
    }

    .extraStats {
        animation: fade-in 700ms $animation-bounce;
        @keyframes fade-in {
            from {
                max-height: 0;
                opacity: 0;
            }
            to {
                max-height: 200px;
                opacity: 1;
            }
        }
    }
}

.SummaryItem {
    display: flex;
    align-items: center;
    width: 100%;
    min-width: 100px;
    color: var(--token-LEGACY-accent-theme-1-interface-interface-white);

    &.reduceFontSize {
        .value {
            font-size: $font-size-p;
        }
    }

    span {
        display: block;
    }

    .title {
        color: var(--token-accent-theme-1-brand-palette-brand-accent-1);
        font-size: $font-size-tiny;
    }

    .value {
        font-size: $font-size-h6;
        text-align: left;
    }

    .icon {
        width: 48px;
        height: 48px;
        margin-right: 10px;
        fill: var(--token-LEGACY-accent-theme-1-interface-interface-white);
    }
}
