@import '../../style/globals.scss';

$transition-in-duration: 300ms; // Duplicated in tsx
$transition-out-duration: 120ms; // Duplicated in tsx

.AchievementFeed {
    position: absolute;
    z-index: 1001;
    top: 0;
    width: 100%;
    height: 0px;

    .achievementContainer {
        position: absolute;
        transition: transform $transition-out-duration ease-in;
        left: 50%;
        transform: translate(-50%, -100%);

        &.visible {
            transform: translate(-50%, 5px);
            transition: transform $transition-in-duration $animation-overshoot;
        }
    }
}
