@import '../../style/globals.scss';

.RedeemPrizeModal {
    font-weight: bold;
    text-transform: uppercase;

    .modal {
        width: 371px;
        max-width: calc(100% - 40px);
        height: auto;

        .contentContainer {
            max-height: calc(100vh - 50px);
            max-height: calc(100dvh - 50px); // Fix for mobile safari
        }

        h2 {
            font-family: var(--font-quaternary);
            font-size: $font-size-h2;
            margin-bottom: -12px;
        }

        h3 {
            color: var(--token-accent-theme-1-brand-palette-brand-accent-1);
            font-size: $font-size-h6;
        }

        p {
            margin-bottom: 15px;
            font-size: $font-size-p;
        }

        .prizeContainer {
            position: relative;
            margin: 23px 0;

            .prizeImage {
                display: inline-block;
                background-color: var(--token-accent-theme-1-brand-palette-brand-accent-1);
                border-radius: 15px;
                max-width: 290px;
                max-height: 290px;
                min-width: 200px;
                min-height: 200px;
            }

            .codeContainer {
                position: absolute;
                right: 0px;
                bottom: 10px;

                .codeIntro {
                    display: block;
                    width: 100%;
                    text-align: left;
                    text-shadow: -2px -2px 0 black, 2px -2px 0 black, -2px 2px 0 black, 2px 2px 0 black,
                        -2px 0px 0 black, 2px 0px 0 black, 0px -2px 0 black, 0px 2px 0 black;
                }
                .code {
                    max-width: 136px;
                    background-color: var(--token-accent-theme-1-interface-mono-interface-white);
                    color: var(--token-accent-theme-1-interface-mono-interface-black);
                    text-align: center;
                    outline: 2px solid var(--token-accent-theme-1-interface-mono-interface-black);
                    border-radius: 10px;
                    cursor: pointer;

                    &:hover {
                        filter: brightness(0.9);
                    }
                }
            }
        }

        .closeButton {
            position: absolute;
            z-index: 1;
            top: -15px;
            right: -15px;
        }

        .ctaButton {
            display: inline-block;
            width: 264px;
            padding: 10px;
            color: var(--token-accent-theme-1-interface-mono-interface-black);
            font-family: var(--font-quaternary);
            font-size: $font-size-h5;
            text-transform: uppercase;

            &:hover {
                filter: brightness(1.5);
            }
        }

        .firstButton {
            margin-bottom: 10px;
        }

        .highlight {
            background-color: var(--token-accent-theme-1-brand-palette-brand-accent-2);
        }

        .lowlight {
            background-color: var(--token-accent-theme-1-button-button-secondary-inactive);
        }

        .readMoreLink {
            display: inline-block;
            width: 100%;
            color: var(--token-accent-theme-1-button-button-secondary-inactive);
            text-decoration: underline;
            margin-top: 21px;

            &:hover {
                filter: brightness(1.5);
            }
        }

        @media (max-width: $bp-s) {
            h2 {
                font-size: $font-size-h3;
            }

            h3 {
                font-size: $font-size-h6;
            }

            p {
                font-size: $font-size-p;
            }

            .prizeContainer {
                .prizeImage {
                    max-width: 230px;
                    max-height: 230px;
                }
            }

            .ctaButton {
                font-size: $font-size-h6;
                padding: 10px;
            }
        }
    }

    .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: 80%;
        backdrop-filter: blur(12px);
    }
}
